import React from 'react'
import cn from 'classnames'

import s from './DefaultContentContainer.module.scss'

const DefaultContentContainer = ({ dark, page_scroll, children, className }) => {
  return (
    <div className={cn(s.content_container, {[s.scroll_bar_space]: page_scroll}, className)}>
      <div className={cn('w-100 h-100', {[s.include_scrollbar__light]: page_scroll && dark}, {[s.include_scrollbar__default]: page_scroll && !dark}, )}>
        {children}
      </div>
    </div>
  )
}

export default DefaultContentContainer