import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ACTIONS_FIELD_ID, ID_TO_COLUMN, INPUT_STATUS_FIELD_ID } from './utils/families_table.js'
import {
  CIPHER_FAMILY_ID_FIELD_ID,
  CPC_CODES_FIELD_ID,
  EXPIRED_TERRITORIES_FIELD_ID,
  GRANTED_TERRITORIES_FIELD_ID,
  PENDING_TERRITORIES_FIELD_ID,
  PRIMARY_CPC_CODE_FIELD_ID,
  SIMILARITY_SCORE_ID,
  STATUS_FIELD_ID,
  TITLE_FIELD_ID
} from '../../model/patent_family_fields.js'
import CipherFamilyLink from '../widgets/CipherFamilyLink.js'
import { format_score } from '../classifiers_editor/utils/training_set_utils.js'
import {
  GRANTED_DATE_FIELD_ID,
  OWNERS_FIELDS_ID,
  PRIORITY_DATE_FIELD_ID,
  PUBLICATION_DATE_FIELD_ID
} from '../classifiers_editor/model/patent_family_underscore_fields.js'
import FamilyActions from './FamilyActions.js'
import InputStatusMarker from './InputStatusMarker.js'
import { ASCENDING, DESCENDING } from '../../model/sort_directions.js'
import CpcWithHover, { CpcCodesWithHover } from '../family_view/CpcWithHover.js'
import { withUser } from '../UserContext.js'
import { get_field_ids_without_restricted_fields } from '../../utils/patent_family_list_utils.js'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import {
  is_element_vertically_onscreen,
  SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT,
  scroll_to_centre
} from '../../utils/scroll_utils.js'

import {
  EXTRA_LARGE,
  EXTRA_SMALL,
  FOUR_EXTRA_LARGE,
  LARGE,
  MEDIUM,
  SMALL,
  THREE_EXTRA_LARGE,
  TWO_EXTRA_LARGE,
  TWO_EXTRA_SMALL
} from '../../constants/screen_breakpoints.js'

import cs from '../cipher_styles.module.scss'
import s from './FamiliesTable.module.scss'

const TableRow = ({is_selected, page_top, children}) => {
  const row_ref = useRef(null)

  useEffect(() => {
    if (is_selected && !is_element_vertically_onscreen(row_ref.current, page_top)) {
      scroll_to_centre(row_ref, SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT)
    }
  }, [is_selected, page_top])

  return (
    <tr className={cn({ [s.__selected]: is_selected })} ref={row_ref}>
      {children}
    </tr>
  )

}

const FamiliesTable = (
  {
    user,
    column_ids,
    subidx,

    families,
    family_ids,
    blocklist,
    bookmarked,

    selected_sort_field_id,
    selected_sort_direction_id,

    on_add_to_family_ids,
    on_add_to_blocklist,
    on_add_to_bookmarked,
    on_change_sort_field_id_and_sort_direction_id,
    on_show_details,
    className,
    headerClassName,
  }) => {

  const theme = useTheme()
  const is_2xs = useMediaQuery(theme.breakpoints.up(TWO_EXTRA_SMALL))
  const is_xs = useMediaQuery(theme.breakpoints.up(EXTRA_SMALL))
  const is_sm = useMediaQuery(theme.breakpoints.up(SMALL))
  const is_md = useMediaQuery(theme.breakpoints.up(MEDIUM))
  const is_lg = useMediaQuery(theme.breakpoints.up(LARGE))
  const is_xl = useMediaQuery(theme.breakpoints.up(EXTRA_LARGE))
  const is_2xl = useMediaQuery(theme.breakpoints.up(TWO_EXTRA_LARGE))
  const is_3xl = useMediaQuery(theme.breakpoints.up(THREE_EXTRA_LARGE))
  const is_4xl = useMediaQuery(theme.breakpoints.up(FOUR_EXTRA_LARGE))

  const table_ref = useRef(null)

  const [screens, set_screens] = useState(null)

  useEffect(() => {
    const screens = {
      is_2xs, is_xs, is_sm, is_md, is_lg, is_xl, is_2xl, is_3xl, is_4xl
    }

    set_screens(screens)
  }, [is_2xs, is_xs, is_sm, is_md, is_lg, is_xl, is_2xl, is_3xl, is_4xl])

  function get_screens_field_name(min_screen_breakpoint) {
    switch (min_screen_breakpoint) {
      case TWO_EXTRA_SMALL: return 'is_2xs';
      case EXTRA_SMALL: return 'is_xs';
      case SMALL: return 'is_sm';
      case MEDIUM: return 'is_md';
      case LARGE: return 'is_lg';
      case EXTRA_LARGE: return 'is_xl';
      case TWO_EXTRA_LARGE: return 'is_2xl';
      case THREE_EXTRA_LARGE: return 'is_3xl';
      case FOUR_EXTRA_LARGE: return 'is_4xl';
      default: return null
    }
  }

  function is_column_on(min_screen_breakpoint) {
    const screen_field_name = get_screens_field_name(min_screen_breakpoint)

    return screen_field_name != null ? screens[screen_field_name] : false
  }

  function on_column_header_click(column) {
    const {sortable, id} = column || {}

    if (sortable !== true) return

    if (selected_sort_field_id !== id) return on_change_sort_field_id_and_sort_direction_id(id, ASCENDING)

    return on_change_sort_field_id_and_sort_direction_id(selected_sort_field_id, selected_sort_direction_id === ASCENDING ? DESCENDING : ASCENDING)
  }

  const cipher_family_ids = (families || []).map(item => item[CIPHER_FAMILY_ID_FIELD_ID])

  const columns = get_field_ids_without_restricted_fields(column_ids, user)
    .map(id => {
      return ID_TO_COLUMN[id] || null
    })
    .filter(column => {
      if ((!column) || (screens == null)) return false
      const {min_screen_breakpoint} = column || {}
      return !min_screen_breakpoint || is_column_on(min_screen_breakpoint)
    })
    .map(column => {
    const {id, field, label} = column || {}

    if (CIPHER_FAMILY_ID_FIELD_ID === id) {
      return {
        ...column,
        render_cell: ({row}) => {
          const {cipherFamilyId, primaryPublication} = row || {}
          return (
            <div className={s.cell}>
              <CipherFamilyLink
                family_id={cipherFamilyId}
                display_text_as_link={true}
                on_family_id_click={() => on_show_details(cipher_family_ids.indexOf(cipherFamilyId), cipherFamilyId)}

                display_link_icon={true}
                show_similar_families_search={true}
              />
              <div>{primaryPublication}</div>
            </div>
          )
        }
      }
    }

    if (SIMILARITY_SCORE_ID === id) return {
      ...column,
      render_cell: ({row}) => {
        const {similarity_score=''} = row || {}
        return (<div className={cn('text-end pe-3', s.cell)}>{format_score(similarity_score)}</div>)
      }
    }

    if (OWNERS_FIELDS_ID === id) return {
      ...column,
      render_cell: ({row}) => {
        const owners_list = (row[field] || []).join(' | ')
        const { width = 100} = ID_TO_COLUMN[id]
        return (<div className={cn(s.cell, s.cell__text)} style={{width}} title={owners_list}>{owners_list}</div>)
      }
    }

    if (STATUS_FIELD_ID === id) return {
      ...column,
      render_cell: ({row}) => {
        const status = row[field]
        const { width = 100} = ID_TO_COLUMN[id]
        return (<div className={s.cell} style={{width}} title={status}>{status}</div>)
      }
    }

    if ([PRIORITY_DATE_FIELD_ID, GRANTED_DATE_FIELD_ID, PUBLICATION_DATE_FIELD_ID].indexOf(id) > -1) return {
      ...column,
      render_cell: ({row}) => {
        const date = row[field] || ''

        return (<div className={s.cell} title={date}>{date}</div>)
      }
    }

    if (TITLE_FIELD_ID === id) return {
      ...column,
      render_cell: ({row}) => {
        const title = row[field] || ''

        return (<div className={cn(s.cell, s.cell__text)} title={title}>{title}</div>)
      }
    }

    if ([PRIMARY_CPC_CODE_FIELD_ID].indexOf(id) > -1) return {
      ...column,
      render_header: () => {
        return (<span className={cs.white_space_nowrap}>{label}</span>)
      },
      render_cell: ({row}) => {
        const value = row[field]
        if (!value) return ''

        const { width = 100} = ID_TO_COLUMN[id]
        return (
          <div className={cn(s.cell, s.cell__text)} style={{width}}>
            <CpcWithHover
              cpc_codes={[value]}
              className='me-1'
            />
          </div>
        )
      }
    }

    if ([CPC_CODES_FIELD_ID].indexOf(id) > -1) return {
      ...column,
      render_header: () => {
        return (<span className={cs.white_space_nowrap}>{label}</span>)
      },
      render_cell: ({row}) => {
        const value = row[field]
        if (!value) return ''
        const { width = 100} = ID_TO_COLUMN[id]
        return (
          <div className={cn(s.cell, s.cell__text)} style={{width}}>
            <CpcCodesWithHover
              cpc_codes={value}
            />
          </div>
        )
      }
    }

    if ([GRANTED_TERRITORIES_FIELD_ID, PENDING_TERRITORIES_FIELD_ID, EXPIRED_TERRITORIES_FIELD_ID].indexOf(id) > -1) return {
      ...column,
      header_label_className: s.column_header_auto_height,
      render_cell: ({row}) => {
        const countries_list = (row[field] || []).join(', ')
        return (<div className={cn(s.cell, s.cell__text)} title={countries_list}>{countries_list}</div>)
      }
    }

    if (ACTIONS_FIELD_ID === id) return {
      ...column,
      render_cell: ({row}) => {
        const {patFamId} = row || {}

        return (
          <FamilyActions
            family_id={patFamId}
            family_ids={family_ids}
            blocklist={blocklist}
            bookmarked={bookmarked}
            on_add_to_family_ids={on_add_to_family_ids}
            on_add_to_blocklist={on_add_to_blocklist}
            on_add_to_bookmarked={on_add_to_bookmarked}

            className='justify-content-center'
          />
        )
      }
    }

    if (INPUT_STATUS_FIELD_ID === id) return {
      ...column,

      header_className: s.input_status_column_header,
      render_cell: ({row}) => {
        const { patFamId } = row || {}

        return (
          <div className='h-100'>
            <InputStatusMarker family_id={patFamId} positives={family_ids} negatives={blocklist} bookmarked={bookmarked} display='table' />
          </div>
        )}
    }

    return column
  })

  const row_height = 58

  return (
    <div className={cn('w-100', className)}>
      <table width='100%' cellPadding={0} cellSpacing={0} className={s.families_table} ref={table_ref}>
        <thead>
          <tr className={cn(s.table_header, headerClassName)}>
            {columns.map(column => {
              const {render_header, label, id, width, sortable, header_className, header_label_className} = column || {}

              return (
                <th key={id} style={{width: width + 'px'}} className={cn('fs-unmask', cs.cursor_pointer, s.column_header, header_className)} onClick={() => on_column_header_click(column)}>
                  <SortingColumnHeaderCell
                    field={{id, sortable, name: label}}
                    column_name_to_display={render_header ? render_header({column}) : label}
                    selected_sort_field_id={selected_sort_field_id}
                    selected_sort_direction_id={selected_sort_direction_id}
                    on_change_sort_field_id_and_sort_direction_id={() => {}}

                    className={header_label_className}
                  />
                </th>
              )
            })}
          </tr>
        </thead>

        <tbody>
          {families.map((item, i) => {

            return (
              <TableRow key={i} is_selected={subidx === i} page_top={((table_ref || {}).current || {}).offsetTop}>
                {columns.map(column => {
                  const {render_cell, field, id} = column

                  return (
                    <td key={`${i}_${id}`} style={{height: `${row_height}px`}} >
                      {render_cell ? render_cell({row: item, field}) : <span className={s.cell}>{item[field] || ''}</span>}
                    </td>
                  )
                })}
              </TableRow>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default withUser(FamiliesTable)