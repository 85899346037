import ReactPiwik from 'react-piwik'
import _ from 'underscore'

import { DEBOUNCE_PERIOD, IS_PROD } from '../constants/constants.js'
import { is_dev_environment, is_tech_discovery } from './utils.js'
import { CONTEXT, mask_knn_id } from './knn_search.js'

const EVENT_CATEGORY_BUILD_REPORT   = { id: 'build_report',   name: 'Report builder' }
const EVENT_CATEGORY_VIEW_REPORT    = { id: 'view_report',    name: 'Report viewer' }
const EVENT_CATEGORY_VIEW_DECK      = { id: 'view_deck',      name: 'Deck viewer' }
const EVENT_CATEGORY_VISIT          = { id: 'visit',          name: 'Visit' }
const EVENT_CATEGORY_SUPPORT        = { id: 'support',        name: 'Support' }
const EVENT_CATEGORY_USER           = { id: 'user',           name: 'User' }
const EVENT_CATEGORY_DOWNLOAD       = { id: 'download',       name: 'Download' }
const EVENT_CATEGORY_LIST           = { id: 'list',           name: 'Families list' }
const EVENT_CATEGORY_MANAGE_REPORTS = { id: 'manage_reports', name: 'Report management' }
const EVENT_CATEGORY_SUBSCRIPTIONS  = { id: 'subscriptions',  name: 'Subscriptions' }
const EVENT_CLASSIFIERS             = { id: 'classifiers',    name: 'Classifiers' }
const EVENT_CATEGORY_VIEW_PROJECT   = { id: 'view_project',   name: 'Project viewer'}
const EVENT_CATEGORY_KNN            = { id: 'knn',            name: 'TechDiscovery'}

const NO_ANALYTICS = process.env.REACT_APP_NO_ANALYTICS === 'true'

const CUSTOM_DIMENSION_ORGANISATION_ID = 1
const CIPHER_SIDE_ID = 1
const TECHDISCOVERY_SITE_ID = 2

function block_tracking() {
  return NO_ANALYTICS || !IS_PROD
}

export function tracking_init() {
  if (block_tracking()) {
    return null
  }

  const url = is_dev_environment() ? 'ma-dev.aws.cipher.ai' : 'ma.cipher.ai'
  const siteId = is_tech_discovery() ? TECHDISCOVERY_SITE_ID : CIPHER_SIDE_ID

  const tracking = new ReactPiwik({
    url,
    siteId,
    jsFilename: 'ma.js',
    phpFilename: 'ma.php',
    trackErrors: true,
    trackDocumentTitle: false  //do not record page names, in particular titles containing report names
  })

  return tracking
}

export function set_user(user_id, user_group) {
  update_stats(['setUserId', user_id])
  update_stats(['setCustomVariable', 1, 'Group', user_group, 'visit'])
  update_stats(['setCustomDimension', CUSTOM_DIMENSION_ORGANISATION_ID, user_group])  //custom dimension 'Organisation'
}

export function reset_user() {
  update_stats(['resetUserId'])
}

export function set_custom_url(custom_url) {
  update_stats(['setCustomUrl', custom_url])
}
export function set_referrer_url(custom_url) {
  update_stats(['setReferrerUrl', custom_url])
}
export function set_document_title(custom_url) {
  update_stats(['setDocumentTitle', custom_url])
}

export function track_event(category, action, debounce) {
  update_stats(['trackEvent', category, action], debounce)
}

export function track_user_event(action, debounce) {
  return track_event(EVENT_CATEGORY_USER.name, action, debounce)
}

export function track_support_event(action, debounce) {
  return track_event(EVENT_CATEGORY_SUPPORT.name, action, debounce)
}

export function track_report_viewer_event(action, debounce) {
  return track_event(EVENT_CATEGORY_VIEW_REPORT.name, action, debounce)
}

export function track_project_viewer_event(action, debounce) {
  return track_event(EVENT_CATEGORY_VIEW_PROJECT.name, action, debounce)
}

export function track_deck_viewer_event(action, debounce) {
  return track_event(EVENT_CATEGORY_VIEW_DECK.name, action, debounce)
}

export function track_download_event(action, debounce) {
  return track_event(EVENT_CATEGORY_DOWNLOAD.name, action, debounce)
}

export function track_report_builder_event(action, debounce) {
  return track_event(EVENT_CATEGORY_BUILD_REPORT.name, action, debounce)
}

export function track_list_event(action, debounce) {
  return track_event(EVENT_CATEGORY_LIST.name, action, debounce)
}

export function track_report_management_event(action, debounce) {
  return track_event(EVENT_CATEGORY_MANAGE_REPORTS.name, action, debounce)
}

export function track_visit_event(action, debounce) {
  return track_event(EVENT_CATEGORY_VISIT.name, action, debounce)
}

export function track_subscriptions_event(action, debounce) {
  return track_event(EVENT_CATEGORY_SUBSCRIPTIONS.name, action, debounce)
}

export function track_classifiers_event(action, debounce) {
  return track_event(EVENT_CLASSIFIERS.name, action, debounce)
}

export function track_knn_events(action) {
  return track_event(EVENT_CATEGORY_KNN.name, action)
}


export function track_page_visit(url, page_title) {
  if (block_tracking()) {
    return
  }

  set_custom_url(url)
  set_document_title(page_title)
  set_referrer_url('')
  push(['trackPageView'])
}

function update_stats(args, debounce) {
  if (block_tracking()) {
    return
  }

  if (debounce) {
    push_debounced(args)
  } else {
    push(args)
  }
}

export function track_knn_search_input(query, id) {
  if (!query) return

  const {pat_fam_ids, text} = query || {}
  if ((pat_fam_ids || []).length > 0) {
    track_knn_events(`context="${CONTEXT}" action="add_to_search" obj="families" id="${mask_knn_id(id)}"`)
  }

  if ((text || []).length > 0) {
    text.forEach(item => {
      const {type} = item || {}
      track_knn_events(`context="${CONTEXT}" action="add_to_search" obj="${type}" id="${mask_knn_id(id)}"`)
    })
  }
  set_referrer_url('')
}

function push(args) {
  const {_paq} = window || {}

  if (_paq) {
    _paq.push(args)
  }
}

export function get_static_classname(text) {
  return `anchor_${text}`
}

const push_debounced = _.debounce(push, DEBOUNCE_PERIOD)
