import React, { useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import { HeaderAction, HeaderDropdownToggle } from './HeaderAction.js'
import {
  BellIcon,
  HistoryIcon,
  NewReportFromParamsIcon,
  RetryIcon,
  SaveIcon,
  ShareIcon,
  TagIcon,
  MoreVertIcon
} from '../widgets/IconSet.js'
import { TagSelectorDropdown } from '../report_management/components/TagSelectorDropdown.js'
import {
  can_tag_families,
  has_report_series_sort,
  is_creator,
  is_poweruser
} from '../../utils/user_permissions.js'
import { get_new_report_from_existing_url, report_can_be_built_from_params } from '../../utils/report_builder_utils.js'
import { FTAGS_UI, HISTORY, REPORT } from '../../constants/paths.js'
import { EXPORT_SUBPATH } from '../../constants/viewer_paths.js'
import { ALERT_SETUP_ID } from '../../model/hyperscripts.js'
import { REPORT_SERIES_SORT_OPTIONS } from '../../utils/report_utils.js'
import { RadiobuttonWithLabel } from '../widgets/RadiobuttonWithLabel.js'
import { is_IE11 } from '../../utils/browser_utils.js'
import { useToggle } from '../../hooks/general_hooks.js'
import { withUser } from '../UserContext.js'
import { can_use_report_for_alerting } from '../../utils/alert_report_utils.js'
import TextLink from '../widgets/TextLink.js'
import { track_support_event } from '../../utils/tracking_utils.js'
import FeedbackModal from '../FeedbackModal.js'
import HelpLink from '../widgets/HelpLink.js'
import { is_tech_discovery } from '../../utils/utils.js'

import cs from '../cipher_styles.module.scss'
import s from './HeaderControls.module.scss'

const BROWSER_IS_IE_11 = is_IE11()

const HeaderMenuItem = ({children}) => {
  return (
    <div className={cn('px-2 mt-1', cs.font_size_smaller)}>{children}</div>
  )
}

const HeaderControls = (
  {
    user,
    include_report_controls,
    is_admin,

    is_fetch_initial,
    should_disable,
    has_report_failed_to_build,
    external_report_id,
    report_type,
    is_saved,
    created_by_user,
    report_input,
    data_creation_date,
    is_incomplete,
    report_series_sort,
    show_charts_in_thumbnails,
    chart_in_thumbnails_allowed,
    available_tags,
    tag_ids,

    is_eval_report,
    can_edit_classifier,
    rerun_eval_report,

    is_editing_report_tags,
    is_newly_saved_report_tooltip_open,
    edit_tag_selections_handler,
    create_new_tag_handler,
    hide_newly_saved_report_tooltip_handler,

    save_report_handler,
    change_report_sort_handler,
    show_user_settings_handler,
    change_report_thumbnails_handler,
    tag_report_handler,
    share_report_handler,
    rename_report_handler,
    reset_report_handler,
    no_data
  }) => {

  const is_tech_discovery_context = is_tech_discovery()

  const [is_showing_selected_tags, toggle_is_showing_selected_tags] = useToggle(false)
  const [tag_search_input, set_tag_search_input] = useState('')
  const [report_options_open, toggle_report_options] = useToggle(false)
  const [feedback_modal_open, set_feedback_modal_open] = useState(false)

  const can_alert_on_report = can_use_report_for_alerting({report_input, report_type, created_by_user, data_creation_date, is_incomplete})

  const no_selections = no_data
  const { name, email, logout_handler } = user || {}
  const username = name || email || ''

  function set_show_feedback_modal(show_feedback_modal) {
    if (show_feedback_modal) {
      track_support_event('action="show"')
    }
    set_feedback_modal_open(show_feedback_modal)
  }

  function on_click_feedback() {
    set_show_feedback_modal(true)
  }

  return (
    <div className={cn('d-flex h-100')}>

      {include_report_controls && is_eval_report && can_edit_classifier &&
        <HeaderAction
          title='Rebuild classifier and re-run report (using default build parameters)'
          on_click={rerun_eval_report}
          className='my-auto px-3'
        >
          <RetryIcon/>
        </HeaderAction>
      }

      {include_report_controls && !should_disable && !is_eval_report && !is_saved &&
        <HeaderAction className='mx-3 my-auto' on_click={save_report_handler} title='Save'><SaveIcon/></HeaderAction>
      }

      {include_report_controls && !is_fetch_initial && !has_report_failed_to_build && !is_eval_report && is_saved &&
        <TagSelectorDropdown
          toggle_button={(
            <DropdownToggle tag='div' className='d-flex'>
              <HeaderAction
                className={cn('mx-3 my-auto', {[s.action__highlighted]: is_newly_saved_report_tooltip_open})}
                title='Tag saved report'
              >
                <TagIcon/>
              </HeaderAction>
            </DropdownToggle>
          )}
          handle_edit_tag_selections={edit_tag_selections_handler}
          is_open={is_editing_report_tags}
          tags={available_tags}
          selected_tag_ids={tag_ids}
          is_showing_selected={is_showing_selected_tags}
          toggle_is_showing_selected={toggle_is_showing_selected_tags}
          search_string={tag_search_input}
          handle_update_search_string={set_tag_search_input}
          handle_create_new_tag={create_new_tag_handler}
          handle_select_tag={tag_report_handler}
          is_newly_saved_report_tooltip_open={is_newly_saved_report_tooltip_open}
          on_tooltip_hide={hide_newly_saved_report_tooltip_handler}

          className='my-auto'
        />
      }

      {include_report_controls && !should_disable && is_creator(user) && created_by_user && report_can_be_built_from_params(report_type) &&
        <HeaderAction title='Edit' className='my-auto me-3' element='a' url={get_new_report_from_existing_url(external_report_id, report_type)}>
          <NewReportFromParamsIcon/>
        </HeaderAction>
      }

      {include_report_controls && can_alert_on_report &&
        <HeaderAction className='me-3 my-auto' to={`${REPORT}/${external_report_id}/${EXPORT_SUBPATH}/${ALERT_SETUP_ID}`} element={Link} title='Create alert'>
          <BellIcon/>
        </HeaderAction>
      }

      {include_report_controls && !should_disable && is_creator(user) &&
        <HeaderAction title='Share' className='my-auto me-3' on_click={share_report_handler}>
          <ShareIcon/>
        </HeaderAction>
      }

      {!is_admin && !is_tech_discovery_context &&
        <HeaderAction className='my-auto' to={HISTORY} element={Link} title='My history'>
          <HistoryIcon />
        </HeaderAction>
      }

      <Dropdown
        isOpen={report_options_open}
        toggle={toggle_report_options}
        className='d-flex'
      >
        <HeaderDropdownToggle
          className='ms-2 px-1 h-100'
          is_open={report_options_open}
          title={`User ${username}`}
        >
          <TextLink className={cn('d-flex h-100', s.report_options_toggle, {[s.report_options_toggle__active]: report_options_open})} no_decoration >
            <span className='my-auto'><MoreVertIcon /></span>
          </TextLink>
        </HeaderDropdownToggle>

        <DropdownMenu
          end
          className={cn('fs-mask', s.options_menu)}
        >
          <DropdownItem header className={s.menu_item__header}>Welcome, {username}</DropdownItem>
          <DropdownItem className={s.menu_item} onClick={() => show_user_settings_handler()}>Settings</DropdownItem>
          {can_tag_families(user) && !is_tech_discovery_context &&
            <DropdownItem className={s.menu_item} tag={Link} to={FTAGS_UI}>Manage family tags</DropdownItem>
          }

          {include_report_controls &&
            <div className='pb-2'>
              <DropdownItem divider className={s.menu_item__divider}/>
              <DropdownItem header className={s.menu_item__header}>Settings for this report</DropdownItem>
              {(is_poweruser(user) || has_report_series_sort(user)) &&
                <>
                  <div className={cn('px-2', s.report_option_header)}>Sort series:</div>
                  {REPORT_SERIES_SORT_OPTIONS.map((item, i) => (
                    <HeaderMenuItem key={i}>
                      <RadiobuttonWithLabel
                        is_checked={report_series_sort === item.id}
                        on_click={() => change_report_sort_handler(item.id)}
                        label={item.label}
                        is_disabled={no_selections || should_disable}
                        labelClassName={cs.white_text}
                        is_dark_bg={true}
                      />
                    </HeaderMenuItem>
                  ))}
                </>
              }

              {!BROWSER_IS_IE_11 &&
                <div className='d-none'>
                  <div className={cn('px-2 mt-1', s.report_option_header)}>Thumbnail charts:</div>
                  {!chart_in_thumbnails_allowed &&
                    <div className={cn('px-2', cs.font_size_small)}>(only icons available in large reports)</div>}
                  {chart_in_thumbnails_allowed &&
                    <HeaderMenuItem>
                      <RadiobuttonWithLabel
                        is_checked={show_charts_in_thumbnails === true}
                        on_click={() => change_report_thumbnails_handler(true)}
                        label='Show report data'
                        is_disabled={should_disable}
                        labelClassName={cs.white_text}
                        is_dark_bg={true}
                      />
                    </HeaderMenuItem>
                  }

                  {chart_in_thumbnails_allowed &&
                    <HeaderMenuItem>
                      <RadiobuttonWithLabel
                        is_checked={show_charts_in_thumbnails === false}
                        on_click={() => change_report_thumbnails_handler(false)}
                        label='Icons only (faster loading)'
                        is_disabled={should_disable}
                        labelClassName={cs.white_text}
                        is_dark_bg={true}
                      />
                    </HeaderMenuItem>
                  }
                </div>
              }

              {created_by_user &&
                <DropdownItem className={s.menu_item} title='Rename' onClick={rename_report_handler}>Rename report</DropdownItem>
              }

              <DropdownItem className={s.menu_item} onClick={reset_report_handler}>Reset report to default</DropdownItem>
            </div>
          }

          <DropdownItem divider className={s.menu_item__divider}/>
          <DropdownItem className={s.menu_item} onClick={on_click_feedback} title='Contact us'>Send feedback</DropdownItem>
          <HelpLink className={cn('dropdown-item', s.menu_item)}/>
          <DropdownItem divider className={s.menu_item__divider}/>
          <DropdownItem className={s.menu_item} tag='a' href={'https://www.lexisnexis.com/global/privacy/privacy-policy.page'} target='_blank'>Privacy Policy</DropdownItem>
          <DropdownItem divider className={s.menu_item__divider}/>
          <DropdownItem className={s.menu_item} onClick={() => logout_handler()}>Logout</DropdownItem>
        </DropdownMenu>
      </Dropdown>

      {feedback_modal_open &&
        <FeedbackModal
          is_open={true}
          on_hide={() => set_show_feedback_modal(false)}
        />
      }

    </div>
  )
}

export default withUser(HeaderControls)