import React from 'react'
import cn from 'classnames'

import cs from '../cipher_styles.module.scss'

const DATASET_ICON_STYLES = {'paddingTop': 0, width: '1.5rem', height: '1.5rem'}

export const CheckIcon = ({className}) => {
  return (<span className={cn('icon icon-Element-Icon---Correct', className)} />)
}

export const RemoveIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Dismiss fs-unmask', className)} />)
}

export const TriangleRightIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Page-Right', className)} />)
}

export const TriangleLeftIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Page-Left', className)} />)
}

export const TriangleDownIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Expand', className)} />)
}

export const FastForwardIcon = () => {
  return (<span><i className='icon icon-Interactive-Icon---Expand-Section'></i></span>)
}

export const FastBackwardIcon = () => {
  return (<span><i className='icon icon-Interactive-Icon---Collapse-Section'></i></span>)
}

export const ChevronUpIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Triangle-Up', className)} />)
}

export const ChevronDownIcon = ({className}) => {
  return (<span id='chevron-down' className={cn('icon icon-Interactive-Icon---Triangle-Down', className)} />)
}

export const ChevronRightIcon = ({className}) => {
  return (<span id='chevron-up' className={cn('icon icon-Interactive-Icon---Next-Step fs-unmask', className)} />)
}

export const ChevronLeftIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Previous-Step' , className)} />)
}

export const ArrowLeftIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Arrow-Left', className)}/>)
}

export const MailIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Mark-As-Unread' />)
}

export const PortfolioIcon = () => {
  return (<span><i className='icon-briefcase'></i></span>)
}

export const AddressIcon = () => {
  return (<span className='icon icon-Element-Icon---Geography' />)
}

export const LitigationIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Legal-Event' />)
}

export const AddToProjectIcon = () => {
  return (<span className='icon icon-project-add'/>)
}

export const ProjectIcon = () => {
  return (<span className='icon icon-project'/>)
}

export const ProjectUnlinkIcon = () => {
  return (<span className='icon icon-project-unlink'/>)
}

export const InfoIcon = ({className}) => {
  return (<span className={cn('icon icon-Info-Icon---More-Info', className)} />)
}

export const QuestionMarkIcon = () => {
  return (<span><i className='icon icon-Info-Icon---Help'></i></span>)
}

export const ShowSimilarIcon = ({className}) => {
  return (<span className={cn('icon icon-ShowSimilarIcon fs-unmask', className)} />)
}

export const NewReportFromParamsIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Copy-to-Clipboard' />)
}

export const ShareIcon = ({className}) => {
  return (<span className={cn('icon icon-share', className)} />)
}

export const HistoryIcon = ({className}) => {
  return (<span className={cn('icon icon-time fs-unmask', className)} />)
}

export const ChangesIcon = () => {
  return (<span><i className='icon-hour-glass'></i></span>)
}

export const FeedbackIcon = () => {
  return (<span className='icon icon-feedback' />)
}

export const UserIcon = () => {
  return (<span><i className='icon icon-user'></i></span>)
}

export const GroupIcon = () => {
  return (<span className='icon icon-group' />)
}

export const EditIcon = ({className, onClick, title}) => {
  return (<span className={cn('icon icon-Element-Icon---Custom-Fields', className)} onClick={onClick} title={title} />)
}

export const TrashIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Delete', className)} />)
}

export const UndoIcon = () => {
  return (<span><i className='icon-undo2'></i></span>)
}

export const SaveIcon = ({className}) => {
  return (<span className={cn('icon icon-diskette', className)} />)
}

export const UnsaveIcon = ({className}) => {
  return (<span className={cn('icon icon-diskette-minus', className)} />)
}

export const TagIcon = () => {
  return (<span className='icon icon-tag-outline' />)
}

export const TagsIconSolid = () => {
  return (<span className='icon icon-tag-multi-solid' />)
}

export const TagsIcon = () => {
  return (<span className='icon icon-tag-multi-outline'/>)
}

export const RetryIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Redo' />)
}

export const AddNewUserIcon = () => {
  return (<span className='icon icon-AddNewUser' />)
}

export const PlusIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Plus' />)
}

export const MinusIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Minus' />)
}

export const ExternalLinkIcon = () => {
  return (<span className='icon icon-Interactive-Icon---External-Link fs-unmask' />)
}

export const ZoomInIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Zoom-In' />)
}

export const ZoomOutIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Zoom-Out' />)
}

export const RotateRightIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Rotate-Right' />)
}

export const RotateLeftIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Rotate-Left' />)
}

export const TransformResetIcon = () => {
  return (<span className='icon icon-transformreset' />)
}

export const ColourPaletteIcon = () => {
  return (<span className='icon icon-colourpalette' />)
}

export const BlockedIcon = ({ className }) => {
  return (<span className={cn('icon icon-Interactive-Icon---Abstain', className)} />)
}

export const CompareIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Ascending-Sort', className)} />)
}

export const ExclamationMarkIcon = ({className}) => {
  return (<span className={cn('icon icon-System-Messages---Error-Icon-Neutral', className)} />)
}

export const SimpleErrorIcon = ({className}) => {
  return (<span className={cn('icon icon-System-Messages---Caution-Icon-Neutral', className)} />)
}

export const SimpleInfoIcon = ({className}) => {
  return (<span className={cn('icon icon-System-Message---Info-Icon-Neutral', className)} />)
}

export const KeyboardIcon = () => {
  return (<span className='icon icon-keyboard' />)
}

export const KeyboardOutlineIcon = () => {
  return (<span className='icon icon-keyboard-outline' />)
}

export const StarActiveIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Favorite-Active'/>)
}

export const StarInactiveIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Favorite-Inactive' />)
}

export const MoveAsGroupOutIcon = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" className={className}>
      <g id="Canvas_1" stroke="none">
        <title>Canvas 1</title>
        <g id="Canvas_1: Layer 1">
          <title>Layer 1</title>
          <g id="Graphic_6">
            <rect x="75" y="0" width="50" height="725"/>
          </g>
          <g id="Graphic_4">
            <circle cx="100" cy="100" r="100.00015979019"/>
            <circle cx="100" cy="100" r="100.00015979019" stroke="white" />
          </g>
          <g id="Graphic_9">
            <rect x="100" y="675" width="200" height="50"/>
          </g>
          <g id="Graphic_10">
            <rect x="125" y="375" width="200" height="50"/>
          </g>
          <g id="Graphic_8">
            <circle cx="300" cy="700" r="100.00015979019"/>
            <circle cx="300" cy="700" r="100.00015979019" stroke="white" />
          </g>
          <g id="Graphic_7">
            <circle cx="300" cy="400" r="100.00015979019"/>
            <circle cx="300" cy="400" r="100.00015979019" stroke="white"/>
          </g>
          <g id="Graphic_18">
            <path d="M 450 250 L 625 550 L 800 250 Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export const MakeGroupIcon = () => {
  return (<span><i className='icon-make-group' /></span>)
}

export const GoogleSearchIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Google' />)
}

export const WikipediaIcon = ({className}) => {
  return (<span><i className={cn('icon-wikipedia1', className)} ></i></span>)
}

export const LanguageIcon = () => {
  return (<span><i className='icon-translate'/></span>)
}

export const ExtractIcon = () => {
  return (<span><i className='icon-share'/></span>)
}

export const BellIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Add-Alert', className)} />)
}

export const RepeatInLeftIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Collapse-Section', className)} />)
}

export const RepeatInRightIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Expand-Section', className)} />)
}

export const CardsViewIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Grid-View', className)} />)
}

export const ColumnsStackViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M3 21 h5v-8.75h-5v8.75z M10 21 h5v-9.75h-5v9.75z M17 21 h5v-5.75h-5v5.75z"/>
      <path d="M3 11.75 h5v-8.75h-5v8.75z M10 10.75 h5v-4.75h-5v4.75h-5z M17 14.75 h5v-2.75h-5v2.75z" opacity="0.4"/>
    </svg>
  )
}

export const ColumnsClusterViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M3 21 h2.25v-17h-2.25v17z M10 21 h2.25v-19h-2.25v19z M17 21 h2.25v-12h-2.25v12z"/>
      <path d="M5.75 21 h2.25v-16h-2.25v16z M12.75 21 h2.25v-10h-2.25v-10z M19.75 21 h2.25v-6h-2.25v6z" opacity="0.4"/>
    </svg>
  )
}

export const BarsStackViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M3 3 v5h8.75v-5h-8.75z M3 10 v5h9.75v-5h-9.75z M3 17 v5h5.75v-5h-5.75z"/>
      <path d="M12.25 3 v5h8.75v-5h-8.75z M13.25 10 v5h4.75v-5h-4.75z M9.25 17 v5h2.75v-5h-2.75z" opacity="0.4"/>
    </svg>
  )
}

export const BarsClusterViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M3 3 v2.25h17v-2.25h-17z M3 10 v2.25h19v-2.25h-19z M3 17 v2.25h12v-2.25h-12z"/>
      <path d="M3 5.75 v2.25h16v-2.25h-16z M3 12.75 v2.25h10v-2.25h-10z M3 19.75 v2.25h6v-2.2h-6z" opacity="0.4"/>
    </svg>
  )
}

export const PieViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M12.25 2 C15 2 22 4 22 12 S15 22 12.25 22 V16 C16 15.5 16 12 16 12 S16 8.5 12.25 8 V2 z" opacity="0.6"/>
      <path d="M11.75 2.1 C 12 1.7 2.2 2.7 2 11.75 H 8 C 8.5 8.2 11.3 8.2 11.75 8 V2 z" opacity="0.3" />
      <path d="M2 12.25 H 8 C8.5 16 12 16 11.75 16 V22 C 7 21.8 2 18 2 12.25 Z"/>
    </svg>
  )
}

export const HeatmapViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path d="M0 0h25v24H0z" fill="none"/>
      <path d="M1 4 h4v1.5h-4v-1.5z M5.5 4 h4v1.5h-4v-1.5z M10 4 h4v1.5h-4v-1.5z M1 6 h4v1.5h-4v-1.5z M19 4 h4v1.5h-4v-1.5z M14.5 6 h4v1.5h-4v-1.5z M5.5 8 h4v1.5h-4v-1.5z M1 10 h4v1.5h-4v-1.5z M5.5 10 h4v1.5h-4v-1.5z M10 10 h4v1.5h-4v-1.5z M19 10 h4v1.5h-4v-1.5z M19 12 h4v1.5h-4v-1.5z M5.5 18 h4v1.5h-4v-1.5z M10 18 h4v1.5h-4v-1.5z" />
      <path d="M14.5 4 h4v1.5h-4v-1.5z M5.5 6 h4v1.5h-4v-1.5z M10 6 h4v1.5h-4v-1.5z M1 8 h4v1.5h-4v-1.5z M19 8 h4v1.5h-4v-1.5z M1 12 h4v1.5h-4v-1.5z M5.5 12 h4v1.5h-4v-1.5z M10 12 h4v1.5h-4v-1.5z M14.5 12 h4v1.5h-4v-1.5z M10 14 h4v1.5h-4v-1.5z M14.5 14 h4v1.5h-4v-1.5z M19 14 h4v1.5h-4v-1.5z M14.5 18 h4v1.5h-4v-1.5z M19 18 h4v1.5h-4v-1.5z" opacity="0.8" />
      <path d="M19 6 h4v1.5h-4v-1.5z M10 8 h4v1.5h-4v-1.5z M14.5 8 h4v1.5h-4v-1.5z M1 14 h4v1.5h-4v-1.5z M5.5 14 h4v1.5h-4v-1.5z M14.5 16 h4v1.5h-4v-1.5z M19 16 h4v1.5h-4v-1.5z M1 18 h4v1.5h-4v-1.5z" opacity="0.5" />
      <path d="M14.5 10 h4v1.5h-4v-1.5z M1 16 h4v1.5h-4v-1.5z M5.5 16 h4v1.5h-4v-1.5z M10 16 h4v1.5h-4v-1.5z" opacity="0.3" />
    </svg>
  )
}

export const LineViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z" style={{stroke: "none"}}/>
      <circle cx="3" cy="18" r="1"/>
      <circle cx="9" cy="15" r="1"/>
      <circle cx="15" cy="9" r="1"/>
      <circle cx="21" cy="11" r="1"/>
      <circle cx="3" cy="10" r="1" opacity="0.3"/>
      <circle cx="9" cy="12" r="1" opacity="0.3"/>
      <circle cx="15" cy="5" r="1" opacity="0.3"/>
      <circle cx="21" cy="16" r="1" opacity="0.3"/>
      <line x1="3" y1="18" x2="9" y2="15" style={{"strokeWidth":1}}/>
      <line x1="9" y1="15" x2="15" y2="9" style={{"strokeWidth":1}}/>
      <line x1="15" y1="9" x2="21" y2="11" style={{"strokeWidth":1}}/>
      <line x1="3" y1="10" x2="9" y2="12" style={{"strokeWidth":1, "opacity": 0.3}}/>
      <line x1="9" y1="12" x2="15" y2="5" style={{"strokeWidth":1, "opacity": 0.3}}/>
      <line x1="15" y1="5" x2="21" y2="16" style={{"strokeWidth":1, "opacity": 0.3}}/>
    </svg>
  )
}

export const TabularViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25" style={style}>
      <path d="M0 0h24v25H0z" fill="none"/>
      <path d="M1 4 h7v2.5h-7v-2.5z M9 4 h4v2.5h-4v-2.5z M14 4 h4v2.5h-4v-2.5z M19 4 h4v2.5h-4v-2.5z" opacity="0.8"/>
      <path d="M1 7.5 h7v2.5h-7v-2.5z M9 7.5 h4v2.5h-4v-2.5z M14 7.5 h4v2.5h-4v-2.5z M19 7.5 h4v2.5h-4v-2.5z" opacity="0.1"/>
      <path d="M1 11 h7v2.5h-7v-2.5z M9 11 h4v2.5h-4v-2.5z M14 11 h4v2.5h-4v-2.5z M19 11 h4v2.5h-4v-2.5z" opacity="0.2"/>
      <path d="M1 14.5 h7v2.5h-7v-2.5z M9 14.5 h4v2.5h-4v-2.5z M14 14.5 h4v2.5h-4v-2.5z M19 14.5 h4v2.5h-4v-2.5z" opacity="0.1"/>
      <path d="M1 18 h7v2.5h-7v-2.5z M9 18 h4v2.5h-4v-2.5z M14 18 h4v2.5h-4v-2.5z M19 18 h4v2.5h-4v-2.5z" opacity="0.2"/>
    </svg>
  )
}

export const TreemapViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M2 4 h10.75v16h-10.75v-16z" opacity="0.6"/>
      <path d="M13.25 13.25 h8.75v6.75h-8.75v-6.75z" />
      <path d="M13.25 4 h5.5v8.75h-5.5v-8.75z" opacity="0.2"/>
      <path d="M19.25 4 h2.75v4.25h-2.75v-4.25" opacity="0.5"/>
      <path d="M19.25 8.75 h2.75v4h-2.75v-4" opacity="0.3"/>
    </svg>
  )
}

export const MapViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 43" style={style}>
      <path d="M0 0h45v43H0z" fill="none"/>
      <path d="M3 7 H4 V8 H3 V7z M1 8 H2 V9 H1 V8z M4 10 H5 V11 H4 V10z M6 9 H7 V10 H6 V9z M9 7 H11 V8 H10 V9 H9 V7z M10 11 H11 V12 H12 V14 H11 V13 H10 V11z M12 9 H15 V8 H19 V9 H18 V11 H17 V12 H16 V13 H15 V14 H14 V12 H13 V10 H12 V9z M17 14 H18 V15 H17 V14z" opacity="0.4"/>
      <path d="M0 12 H1 V13 H2 V12 H4 V13 H7 V12 H8 V13 H9 V14 H8 V15 H7 V16 H8 V17 H9 V18 H10 V15 H11 V16 H12 V17 H13 V18 H12 V19 H11 V20 H10 V23 H9 V22 H6 V23 H5 V22 H4 V21 H3 V18 H2 V17 H1 V16 H0 V12z" />
      <path d="M6 23 H7 V24 H6 V23z
      M7 24 H12 V25 H14 V26 H15 V27 H16 V29 H15 V30 H14 V31 H13 V34 H14 V35 H13 V34 H11 V29 H9 V27 H8 V25 H7 V24z" opacity="0.6" />
      <path d="M18 22 H19 V21 H21 V20 H22 V21 H25 V22 H26 V23 H27 V25 H28 V26 H27 V29 H26 V31 H25 V32 H23 V29 H22 V26 H19 V25 H18 V22z M27 29 H28 V30 H27 V29z M28 28 H29 V29 H28 V28 " opacity="0.4"/>
      <path d="M38 29 H39 V28 H40 V27 H41 V28 H42 V27 H43 V29 H44 V31 H43 V32 H41 V31 H38 V29z M42 33 H43 V34 H42 V33z" opacity="0.5"/>
      <path d="M23 9 H24 V10 H23 V9z
      M20 20 V18 H21 V17 H24 V16 H25 V15 H24 V14 H23 V16 V22 V14 H22 V13 H23 V12 H27 V13 H25 V14 H28 V13 H30 V12 H31 V11 H33 V10 H39 V11 H40 V12 H41 V11 H44 V16 H41 V17 H42 V19 H41 V20 H39 V22 H38 V23 H37 V25 H36 V24 H35 V23 H34 V24 H33 V25 H32 V23 H31 V22 H29 V24 H27 V22 H26 V21 H25 V20 H27 V19 H25 V20 H23 V19 H21 V20 H20z
      M41 9 H42 V10 H41 V9z" opacity="0.8"/>
      <path d="M37 26 H38 V25 H39 V27 H37 V26z M41 25 H43 V26 H41 V25z M40 21 H41 V22 H40 V21z M41 20 H42 V21 H41 V20z" opacity="0.8" />
    </svg>
  )
}

export const BubbleViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path d="M0 0h24v24H0z" fill="none"/>
      <circle cx="3" cy="18" r="0.8"/>
      <circle cx="9" cy="18" r="0.5"/>
      <circle cx="15" cy="18" r="1.2"/>
      <circle cx="21" cy="18" r="1.5"/>
      <circle cx="3" cy="12" r="1.2" opacity="0.6"/>
      <circle cx="9" cy="12" r="1.5" opacity="0.6"/>
      <circle cx="15" cy="12" r="2" opacity="0.6"/>
      <circle cx="21" cy="12" r="2.3" opacity="0.6"/>
      <circle cx="3" cy="6" r="2.2" opacity="0.3"/>
      <circle cx="9" cy="6" r="1.2" opacity="0.3"/>
      <circle cx="15" cy="6" r="1.6" opacity="0.3"/>
      <circle cx="21" cy="6" r="1.2" opacity="0.3"/>
    </svg>
  )
}

export const AreaViewIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23.5" style={style}>
      <path d="M0 0h23v24H0z" fill="none"/>
      <path d="M1 4 h21 v0 l-3 1.4 l-3 1.4 l-3 1 l-3 -1 l-3 -0.2 l-3 0.1 l-3 -0.2Z" opacity="0.5"/>
      <path d="M22 4.5 l-3 2 l-3 2 l-3 1 l-3 -1 l-3 1 l-3 0.6 l-3 1 V7 l3 0.2 l3 -0.1 l3 0.2 l3 1 l3 -1 l3 -1.4 l3 -1.4Z" opacity="0.1" />
      <path d="M1 13.5 l3 -0.5 l3 -0.2 l3 -1.3 l3 -0.2 l3 -0.5 l3 -2 l3 -3 V5 l-3 2 l-3 2 l-3 1 l-3 -1 l-3 1 l-3 0.6 l-3 1Z" opacity="0.3"/>
      <path d="M1 14 l3 -0.5 l3 -0.2 l3 -1.3 l3 -0.2 l3 -0.5 l3 -2 l3 -3 V17 l-3 -2 l-6 -1 l-3 0 l-3 0.5 l-3 0.3 l-3 0.2Z" opacity="0.6"/>
      <path d="M1 19.5 h21v-2 l-3 -2 l-6 -1 l-3 0 l-3 0.5 l-3 0.3 l-3 0.2Z" opacity="0.9"/>
    </svg>
  )
}

export const ScatterViewDotsIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z" style={{stroke: "none"}}/>
      <circle cx="3" cy="19" r="1.4"/>
      <circle cx="7" cy="11" r="1.4"/>
      <circle cx="10" cy="16" r="1.4"/>
      <circle cx="17" cy="10" r="1.4"/>
      <circle cx="17" cy="17" r="1.4"/>
      <circle cx="22" cy="12" r="1.4"/>
      <circle cx="10" cy="13" r="1.4" opacity="0.3"/>
      <circle cx="14" cy="11" r="1.4" opacity="0.3"/>
      <circle cx="16" cy="6" r="1.4" opacity="0.3"/>
      <circle cx="6" cy="18" r="1.4" opacity="0.3"/>
    </svg>
  )
}

// added for benchmarking report deck
export const DeckColumnsStackIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 21 h3.25v-8.75h-3.25v8.75z M7 21 h3.25v-9.75h-3.25v9.75z M11 21 h3.25v-5.75h-3.25v5.75z M15 21 h3.25v-3.75h-3.25v3.75z M19 21 h3.25v-1.75h-3.25v1.75z"/>
      <path d="M3 11.75 h3.25v-8.75h-3.25v8.75z M7 10.75 h3.25v-4.75h-3.25v4.75 M11 14.75 h3.25v-5.75h-3.25v5.75z M15 16.75 h3.25v-2.75h-3.25v2.75 M19 18.75 h3.25v-1.75h-3.25v1.75" opacity="0.4"/>
    </svg>
  )
}

export const DeckColumnsBoldIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 21 h3.25v-18h-3.25v18z M7 21 h3.25v-15h-3.25v15z M11 21 h3.25v-10h-3.25v10z M15 21 h3.25v-6h-3.25v6z M19 21 h3.25v-4h-3.25v4z"/>
    </svg>
  )
}

export const DeckColumnsLightIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 21 h3.25v-18h-3.25v18z M7 21 h3.25v-15h-3.25v15z M11 21 h3.25v-10h-3.25v10z M15 21 h3.25v-6h-3.25v6z M19 21 h3.25v-4h-3.25v4z" opacity="0.4"/>
    </svg>
  )
}

export const DeckColsPosNegSpotlightIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 17 h3.25v-13h-3.25v13z M7 17 h3.25v-11h-3.25v11z M15 17 h3.25v2h-3.25v-2z M19 17 h3.25v4h-3.25v-4z" opacity="0.4"/>
      <path d="M11 17 h3.25v-4h-3.25v4z"/>
    </svg>
  )
}

export const DeckBarsSpotlightIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" style={style}>
      <path fill="none" d="M0 0h25v25H0V0z"/>
      <path d="M3 3 v3.25h18v-3.25h-18z M3 11 v3.25h10v-3.25h-10z M3 15 v3.25h6v-3.25h-6z M3 19 v3.25h4v-3.25h-4z" opacity="0.4"/>
      <path d="M3 7 v3.25h15v-3.25h-15z"/>
    </svg>
  )
}

export const DeckBarsPosNegSpotlightIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" style={style}>
      <path fill="none" d="M0 0h25v25H0V0z"/>
      <path d="M7 3 v3.25h13v-3.25h-13z M7 7 v3.25h11v-3.25h-11z M7 15 v3.25h-2v-3.25h2z M7 19 v3.25h-4v-3.25h4z" opacity="0.4"/>
      <path d="M7 11 v3.25h4v-3.25h-4z"/>
    </svg>
  )
}

export const DeckLandscapeBarsIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z"/>
      <path d="M3 4.5 v1.5h15v-1.5h-15z M3 9 v1.5h9v-1.5h-9z M3 12 v1.5h6.5v-1.5h-6.5z M3 13.5 v1.5h6v-1.5h-6z M3 16.5 v1.5h3.5v-1.5h-3.5z M3 18 v1.5h3v-1.5h-3z v1.5h2v-1.5h-2z" opacity="0.2"/>
      <path d="M3 3 v1.5h18v-1.5h-18z M3 7.5 v1.5h10v-1.5h-10z M3 10.5 v1.5h8v-1.5h-8z M3 15 v1.5h5v-1.5h-5z M3 19.5" opacity="0.6"/>
      <path d="M3 6 v1.5h12v-1.5h-12z"/>
    </svg>
  )
}

export const DeckLineIcon = ({custom_style={}}) => {
  const style = {...DATASET_ICON_STYLES, ...custom_style}
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" style={style}>
      <path fill="none" d="M0 0h25v24H0V0z" style={{stroke: "none"}}/>
      <circle cx="3" cy="14" r="0.75"/>
      <circle cx="9" cy="12" r="0.75"/>
      <circle cx="15" cy="7" r="0.75"/>
      <circle cx="21" cy="9" r="0.75"/>
      <line x1="3" y1="14" x2="9" y2="12" style={{"strokeWidth":0.75}}/>
      <line x1="9" y1="12" x2="15" y2="7" style={{"strokeWidth":0.75}}/>
      <line x1="15" y1="7" x2="21" y2="9" style={{"strokeWidth":0.75}}/>

      <circle cx="3" cy="8" r="0.75" opacity="0.4"/>
      <circle cx="9" cy="10" r="0.75" opacity="0.4"/>
      <circle cx="15" cy="3" r="0.75" opacity="0.4"/>
      <circle cx="21" cy="11" r="0.75" opacity="0.4"/>
      <line x1="3" y1="8" x2="9" y2="10" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="9" y1="10" x2="15" y2="3" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="15" y1="3" x2="21" y2="11" style={{"strokeWidth":0.75, "opacity": 0.4}}/>

      <circle cx="3" cy="12" r="0.75" opacity="0.4"/>
      <circle cx="9" cy="15" r="0.75" opacity="0.4"/>
      <circle cx="15" cy="10" r="0.75" opacity="0.4"/>
      <circle cx="21" cy="8" r="0.75" opacity="0.4"/>
      <line x1="3" y1="12" x2="9" y2="15" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="9" y1="15" x2="15" y2="10" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="15" y1="10" x2="21" y2="8" style={{"strokeWidth":0.75, "opacity": 0.4}}/>

      <circle cx="3" cy="19" r="0.75" opacity="0.4"/>
      <circle cx="9" cy="19" r="0.75" opacity="0.4"/>
      <circle cx="15" cy="16" r="0.75" opacity="0.4"/>
      <circle cx="21" cy="18" r="0.75" opacity="0.4"/>
      <line x1="3" y1="19" x2="9" y2="19" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="9" y1="19" x2="15" y2="16" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="15" y1="16" x2="21" y2="18" style={{"strokeWidth":0.75, "opacity": 0.4}}/>

      <circle cx="3" cy="18" r="0.75" opacity="0.4"/>
      <circle cx="9" cy="20" r="0.75" opacity="0.4"/>
      <circle cx="15" cy="19" r="0.75" opacity="0.4"/>
      <circle cx="21" cy="15" r="0.75" opacity="0.4"/>
      <line x1="3" y1="18" x2="9" y2="20" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="9" y1="20" x2="15" y2="19" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
      <line x1="15" y1="19" x2="21" y2="15" style={{"strokeWidth":0.75, "opacity": 0.4}}/>
    </svg>
  )
}

export const EyeIcon = () => {
  return (<span><i className='icon icon-eye-open' /></span>)
}

export const EyeBlockedIcon = () => {
  return (<span><i className='icon icon-eye-closed'/></span>)
}

export const ConfigureIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Indicator-Filter', className)} />)
}

export const InvestigateIcon = () => {
  return (<span className='icon icon-investigate' />)
}

export const QueryReportDataIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Folder'/>)
}

export const FilterIcon = () => {
  return (<span className='icon icon-Element-Icon---Filter' />)
}

export const GridIcon = () => {
  return  (<span className='icon icon-Interactive-Icon---Gallary-View' />)
}

export const ColumnChartIcon = () => {
  return  (<span className='icon icon-Element-Icon---Column-Chart' />)
}

export const SelectedNavIcon = () => {
  return (<span className='icon icon-checkbox-selected'/>)
}

export const ListViewNavIcon = () => {
  return (<span className='icon icon-table-view-list'/>)
}

export const NotesIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Full-Text-Filter' />)
}

export const MoveAssigneeUpIcon = () => {
  return (<span className='icon icon-moveassigneeup' />)
}

export const PropagateUpIcon = () => {
  return (<span className='icon icon-propagateup' />)
}

export const ListIcon = ({ className }) => {
  return (<span className={cn('icon icon-Interactive-Icon---List-View', className)}/>)
}

export const ExportIcon = () => {
  return (<span className='icon icon-download' />)
}

export const UploadIcon = () => {
  return (<span className='icon icon-Interactive-Icon---Upload' />)
}

export const LoopIcon = () => {
  return (<span><i className='icon-loop1' /></span>)
}

export const CogIcon = ({className}) => {
  return (<span className={cn('fs-unmask icon icon-Interactive-Icon---Options', className)} />)
}

export const MenuIcon = () => {
  return (<span><i className='icon-menu' /></span>)
}

export const AddToClipboardIcon = () => {
  return (<span><i className='icon icon-clipboard' /></span>)
}

export const LightbulbCrossedIcon = () => {
  return (<span className='icon icon-lightbulbcrossed' />)
}

export const LightbulbOnIcon = () => {
  return (<span className='icon icon-lightbulbon' />)
}

export const LightbulbOffIcon = () => {
  return (<span className='icon icon-lightbulb' />)
}

export const BarsIcon = () => {
  return (<span><i className='icon icon-Element-Icon---Click-Drag-Indicator-Pill' /></span>)
}

export const MergeIcon = () => {
  return (<span className={cn('icon icon-Interactive-Icon---Corporate-Tree', cs.rotate_180)}/>)
}

export const AppsIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---App-Selector', className)}/>)
}

export const RightArrowInCircleIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Shift-Item-Right', className)}/>)
}

export const CalendarIcon = ({className}) => {
  return (<span className={cn('icon icon-Interactive-Icon---Time-Filter', className)}/>)
}

export const MoreVertIcon = () => {
  return (<span className='icon icon-Interactive-Icon---User-Account-Menu'/>)
}
export const DragIndicatorIcon = () => {
  return (<span className='icon icon-dragindicator' />)
}

export const SearchIcon = () => {
  return (<span className='icon icon-search' />)
}

export const OpenWithIcon = () => {
  return (<span className='icon icon-openwith' />)
}

export const PinIcon = () => {
  return (<span className='icon icon-pin-solid fs-unmask'/>)
}

export const UnpinIcon = () => {
  return (<span className='icon icon-pin-outline fs-unmask'/>)
}

export const SearchAgainIcon = () => {
  return (<span className='icon icon-searchagain fs-unmask' />)
}

export const CircleUncheckedIcon = () => {
  return (<span className='icon icon-circleunchecked' />)
}

export const CircleCheckedIcon = () => {
  return (<span className='icon icon-set-positive-solid' />)
}

export const TriangleOutlineIcon = () => {
  return (<span><i className='icon-triangle1' /></span>)
}

export const TriangleIcon = () => {
  return (<span><i className='icon-triangle' /></span>)
}

export const AddCircleIcon = () => {
  return (<span className='icon icon-add-plus fs-unmask' />)
}

export const PositiveLabelControlIcon = () => {
  return (<span className='icon icon-set-positive-solid' />)
}

export const NegativeLabelControlIcon = () => {
  return (<span className='icon icon-set-negative-solid' />)
}

export const IgnoreLabelControlIcon = () => {
  return (<span className='icon icon-set-ignore-solid' />)
}

export const PositiveTestLabelControlIcon = () => {
  return (<span className='icon icon-set-positive-solid-test' />)
}

export const NegativeTestLabelControlIcon = () => {
  return (<span className='icon icon-set-negative-solid-test' />)
}

export const RelevantLabelControlIcon = () => {
  return (<AddCircleIcon />)
}

export const IrrelevantLabelControlIcon = () => {
  return (<span className='icon icon-irrelevant fs-unmask' />)
}

export const BookmarkLabelControlIcon = () => {
  return (<span className='icon icon-bookmark fs-unmask' />)
}

export const ChainOpenedIcon = () => {
  return (<span className='icon icon-class-export-o' />)
}

export const ChainClosedIcon = () => {
  return (<span className='icon icon-class-export-c' />)
}
