import React, { useRef, useState } from 'react'
import cn from 'classnames'

import { PrimaryButton } from '../widgets/Button.js'
import ErrorBody from '../ErrorBody.js'
import {
  CONTEXT,
  create_search_name,
  generate_input_id,
  mask_knn_id,
  is_history_enabled,
  NAME_MAX_LENGTH,
  parse_text_input,
  save_similar_families_search_input,
  get_search_unique_name,
} from '../../utils/knn_search.js'
import { SIMILARITY_SCORE_ID } from '../../model/patent_family_fields.js'
import { DESCENDING } from '../../model/sort_directions.js'
import { KNN } from '../../constants/paths.js'
import { withRouter } from 'react-router-dom'
import { is_400_error } from '../../utils/axios_utils.js'
import BadInputAlertModal from './BadInputAlertModal.js'
import UserSearchesDisplay from './UserSearchesDisplay.js'
import SearchTextInput from './SearchTextInput.js'
import PrivateSearchModeControl from './PrivateSearchModeControl.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import { track_knn_events, track_knn_search_input } from '../../utils/tracking_utils.js'
import { SearchIcon } from '../widgets/IconSet.js'
import { is_tech_discovery } from '../../utils/utils.js'
import UserSearchesTable from './UserSearchesTable.js'

import s from './InputUnparsed.module.scss'

const InputUnparsed = (
  {
    history,
    should_save_knn_searches,
    anonymous_mode_enabled,
    toggle_anonymous_mode,
  }) => {

  const inner_content_container_ref = useRef(null)
  const input_heading_ref = useRef(null)
  const input_actions_ref = useRef(null)
  const history_wrapper_ref = useRef(null)

  const [is_fetching, set_is_fetching] = useState(false)
  const [input, set_input] = useState(null)

  const [input_error, set_input_error] = useState(null)

  const is_search_valid = (input || '').trim().length > 0

  function on_parse_input() {
    set_input_error(null)
    set_is_fetching(true)

    parse_text_input(input)
      .then(({ query }) => {
        const { pat_fam_ids, text, search_name: service_search_name } = query || {}

        const query_params = {
          ...pat_fam_ids ? { pat_fam_ids } : {},
          ...(text) ? {text} : {},
        }

        const name = ((service_search_name != null) && (service_search_name !== '')) ? service_search_name.slice(0, NAME_MAX_LENGTH) : create_search_name(query_params)

        return get_search_unique_name(name, history_enabled)
          .then(({name}) => {

            return save_similar_families_search_input(
              {
                input: query_params,
                settings: {
                  sort_field_id: SIMILARITY_SCORE_ID,
                  sort_direction_id: DESCENDING,
                },
                name
              },
              generate_input_id(),
              history_enabled ? generate_input_id() : null,
              history_enabled ? generate_input_id() : null,
              history_enabled
            )
              .then(({ id }) => {
                track_knn_search_input(query, id)
                return {id}
              })
        })
      })
      .then(({ id }) => {
        set_is_fetching(false)
        track_knn_events(`context="${CONTEXT}" action="knn_search" obj="start_search_screen" id="${mask_knn_id(id)}"`)
        history.replace(`${KNN}/${id}`)
      })
      .catch(err => {
        set_input_error(err)
        set_is_fetching(false)
      })
  }

  function get_max_height_for_input_autoresize() {
    const { clientHeight: inner_content_client_height } = (inner_content_container_ref || {}).current || {}
    const { clientHeight: input_heading_client_height } = (input_heading_ref || {}).current || {}
    const { clientHeight: input_actions_client_height } = (input_actions_ref || {}).current || {}
    if (inner_content_client_height == null) return null

    if (is_tech_discovery_context) {
      const { offsetHeight: history_wrapper_offset_height } = (history_wrapper_ref || {}).current || {}

      return (history_wrapper_offset_height == null) ? null : inner_content_client_height - history_wrapper_offset_height - (input_heading_client_height + input_actions_client_height)
    }

    return inner_content_client_height - (input_heading_client_height + input_actions_client_height)
  }

  const history_enabled = is_history_enabled({should_save_knn_searches, anonymous_mode_enabled})
  const is_tech_discovery_context = is_tech_discovery()

  return (
    <div className={cn('w-100 position-relative', s.block)}>
      <div className={cn('position-relative h-100 overflow-hidden')}>
        <ContainerFullWidth className={cn('p-0', s.breadcrumbs_wrapper)}>
          <ContainerFullWidth className={cn('d-flex', {'justify-content-between': !is_tech_discovery_context}, {'justify-content-end': is_tech_discovery_context}, s.breadcrumbs)}>
            {!is_tech_discovery_context &&<h2 className='my-auto'>TechDiscovery</h2>}

            {should_save_knn_searches &&
              <PrivateSearchModeControl
                is_enabled={should_save_knn_searches}
                is_checked={anonymous_mode_enabled}
                on_click={toggle_anonymous_mode}
                className='my-auto me-2'
              />
            }
          </ContainerFullWidth>
        </ContainerFullWidth>

        <div className={cn('container', {'d-flex flex-column justify-content-between': is_tech_discovery_context}, s.content_container)}>
          <div className='w-100 h-100 position-relative' ref={inner_content_container_ref}>

            <div className={s.input_wrapper}>
              <div className={cn('fs-unmask d-flex pb-2', s.heading)} ref={input_heading_ref}>
                <span className={s.heading_icon}><SearchIcon /></span><span className='ms-2 my-auto'>Enter one or more search criteria</span>
              </div>

              <SearchTextInput
                input_text={input}
                on_change_input_text={set_input}
                auto_focus={true}
                max_height_for_autoresize={get_max_height_for_input_autoresize()}
              />

              <div className='d-flex justify-content-end pt-2' ref={input_actions_ref}>

                {!is_tech_discovery_context &&
                  <UserSearchesDisplay className='me-2 my-auto'/>
                }
                <PrimaryButton onClick={() => on_parse_input()} disabled={!is_search_valid || is_fetching}>
                  <span className='fs-unmask'>Search</span>
                </PrimaryButton>

              </div>
            </div>
            {should_save_knn_searches && is_tech_discovery_context &&
              <div className={s.history_wrapper} ref={history_wrapper_ref}>
                <UserSearchesTable
                  hide_no_results_message={true}
                  listWrapperClassName={cn('h-100 overflow-hidden mt-3', s.history)}
                  controlsWrapperClassName={s.controls}
                  tableWrapperClassName={s.table}
                />
              </div>
            }
          </div>
        </div>

        {input_error && !is_400_error(input_error) &&
          <div className='mt-4'>
            <ErrorBody
              context={'searching for similar families'}
              error={input_error}
            />
          </div>
        }

        {input_error && is_400_error(input_error) &&
          <BadInputAlertModal
            error={input_error}
            on_hide={() => set_input_error(null)}
          />
        }
      </div>
    </div>
  )
}

export default withRouter(InputUnparsed)