import axios from 'axios'

import { add_source_err_to_target_err } from './axios_utils.js'
import { USER_INFO_BY_IDS_URL, USER_INFO_BASE_URL } from '../constants/urls.js'
import { GROUP, IS_ME, IS_SOLUTIONS, USER } from '../model/user_info.js'

export function get_user_info_by_id(user_ids) {
  return axios.post(USER_INFO_BY_IDS_URL, user_ids)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch user info: ')
    })
}

export function get_user_idp() {
  return axios.get(`${USER_INFO_BASE_URL}/idp`)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch user idp info: ')
    })
}

export function get_group_from_user(user) {
  const { group_ids, group_names } = user
  const group_id   = group_ids[0]
  const group_name = group_names[0]

  return {
    id:   group_id,
    name: group_name,
    type: GROUP
  }
}

export function clean_users(users, current_user_id, is_solutions) {
  if (!users) {
    return []
  }

  return users.map(user => {
    const { id, email, firstName, lastName, group_name, username, group_id } = user

    return {
      type: USER,
      id,
      email,
      first_name: firstName,
      last_name:  lastName,
      user_name:  username,
      group_id,
      group_name,
      [IS_SOLUTIONS]: is_solutions === true,
      [IS_ME]: current_user_id === id
    }
  })
}