import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router'
import _ from 'underscore'
import cn from 'classnames'

import {useInterval} from '../../hooks/general_hooks.js'
import {
  add_project_files,
  add_project_reports,
  are_permission_rows_identical,
  delete_project_files,
  get_project,
  get_project_file_history,
  get_project_files,
  get_project_reports,
  PostEntityPermission,
  PostProjectPermissions,
  remove_project_reports,
  update_project_permissions,
} from '../../utils/project_and_versioning_utils'
import {fetch_report_history, fetch_report_owners} from '../../utils/report_history_utils.js'
import {send_error_to_sentry} from '../../utils/sentry_utils'
import {track_project_viewer_event} from '../../utils/tracking_utils.js'
import {
  fetch_all_active_keycloak_groups,
  fetch_all_active_keycloak_users,
  fetch_my_keycloak_groups, fetch_users_in_keycloak_group
} from '../../utils/user_group_utils.js'
import {is_admin, is_aistemos} from '../../utils/user_permissions.js'
import {get_as_map} from '../../utils/utils.js'
import ErrorModal from '../ErrorModal.js'
import { get_new_selected_external_report_ids } from '../report_management/utils/sort_and_filter_utils.js'

import {withUser} from '../UserContext'
import Spinner from '../widgets/Spinner.js'
import {Entity, EntityPermissions, EntityType, Project, Report, UserFile} from './model/project'
import ProjectNavigation from './ProjectNavigation.js'
import {
  PROJECT_TRACKING_CONTEXT,
  TAB_PERMISSIONS,
  TAB_REPORTS_AND_FILES
} from '../../constants/project.js'
import FileUploadModal from './FileUploadModal.js'
import ProjectPermissions from './ProjectPermissions.js'
import ProjectReports from './ProjectReports.js'
import ProjectFiles from './ProjectFiles.js'
import ContainerFullWidth from '../ContainerFullWidth.js'
import TextLink from '../widgets/TextLink.js'
import {ArrowLeftIcon} from '../widgets/IconSet.js'
import {HISTORY} from '../../constants/paths.js'

import s from './ProjectView.module.scss'

export interface ProjectViewProps {
  user?: any,
  project_id: string,
  history?: any
}

type UserFileHistoryMap = { [key: string]: UserFile[] }
type PermissionRow = Entity & EntityPermissions

const MAX_FILE_COUNT = 20
const MAX_FILE_SIZE = 100

const ProjectView = (props: ProjectViewProps) => {
  // Data
  const [project, set_project] = useState<Project | null>(null)
  const [project_files, set_project_files] = useState<UserFile[]>([])
  const [project_reports, set_project_reports] = useState<Report[]>([])

  // Conditional rendering state
  const [is_fetching, set_is_fetching] = useState<boolean>(true)
  const [can_view, set_can_view] = useState<boolean>(false)
  const [can_edit, set_can_edit] = useState<boolean>(false)
  const [current_tab_view, set_current_tab_view] = useState<string>(TAB_REPORTS_AND_FILES)
  const [is_fetching_reports, set_is_fetching_reports] = useState<boolean>(false)
  const [is_fetching_files, set_is_fetching_files] = useState<boolean>(false)
  const [is_uploading_files, set_is_uploading_files] = useState<boolean>(false)
  const [is_deleting_file, set_is_deleting_file] = useState<boolean>(false)
  const [is_loading_files_history, set_is_loading_files_history] = useState<boolean>(false)
  const [is_loading_permission_rows,set_is_loading_permission_rows] = useState<boolean>(false)
  const [error_on_action, set_error_on_action] = useState<any>(null)

  const [user_reports, set_user_reports] = useState<Report[]>([])

  const [selected_reports_external_ids, set_selected_reports_external_ids] = useState<string[]>([])
  const [report_owners, set_report_owners] = useState<{ [key: string]: string }>({})

  function toggle_selected_reports(reports_to_toggle: Report[], is_selected: boolean) {
    const selected_reports_external_ids_updated = get_new_selected_external_report_ids(
      reports_to_toggle.map(report => report.external_report_id),
      is_selected,
      selected_reports_external_ids
    )
    set_selected_reports_external_ids(selected_reports_external_ids_updated)
  }

  function on_unlink_reports_from_project() {
    if (!project || !can_edit) {
      return
    }
    track_project_viewer_event(`obj="project" action="remove_reports_from_project" context="${PROJECT_TRACKING_CONTEXT}"`)
    remove_project_reports(project.project_id, {external_report_ids: selected_reports_external_ids})
      .then(() => {
        set_selected_reports_external_ids([])
        load_project_data()
      })
      .catch(error => {
        set_error_on_action({error, action: 'error removing link to report'})
      })
  }

  function on_delete_project_file(s3_resource_id: string) {
    track_project_viewer_event(`obj="project" action="delete_file" context="${PROJECT_TRACKING_CONTEXT}"`)
    set_is_deleting_file(true)
    delete_project_files(props.project_id, {resource_ids: [s3_resource_id]})
      .then(() => {
        set_project_files(_.filter(project_files, p => p.s3_resource_id !== s3_resource_id))
        set_is_deleting_file(false)
      })
      .catch(error => {
        set_is_deleting_file(false)
        set_error_on_action({error, action:'deleting project file'})
      })
  }

  useEffect(() => {
    fetch_report_history(null, true, false)
      .then(set_user_reports)
      .catch(error => {
        set_error_on_action({error, action: 'fetching report history'})
      })
  }, [project])

  const [is_file_upload_modal_open, set_is_file_upload_modal_open] = useState<boolean>(false)
  const [files_history, set_files_history] = useState<UserFileHistoryMap>({})

  function upload_project_files(files_to_upload: File[]) {
    track_project_viewer_event(`obj="project" action="upload_files" context="${PROJECT_TRACKING_CONTEXT}"`)
    set_is_uploading_files(true)
    add_project_files(props.project_id, files_to_upload)
      .then(() => {
        set_is_uploading_files(false)
        set_is_file_upload_modal_open(false)
      })
      .catch(error => {
        set_error_on_action({error, action: 'uploading project files'})
      })
  }

  const [current_project_permission_rows, set_current_project_permission_rows] = useState<PermissionRow[]>([])
  const [permissions_rows, set_permissions_rows] = useState<PermissionRow[]>([])
  const [updated_permissions_rows, set_updated_permissions_rows] = useState<PermissionRow[]>([])
  const [all_active_groups, set_all_active_groups] = useState<any>(null)
  const [all_active_users, set_all_active_users] = useState<any>(null)

  function on_update_project_permissions() {
    track_project_viewer_event(`obj="project" action="update_permissions" context="${PROJECT_TRACKING_CONTEXT}"`)
    set_is_loading_permission_rows(true)

    const new_user_perms: PostEntityPermission[] = _.map(
      _.filter(updated_permissions_rows, r => r.entity_type === EntityType.User),
      r => {return {entity_uuid: r.entity_id, permissions: {view: r.view, edit: r.edit }}}
    )
    const new_group_perms: PostEntityPermission[] = _.map(
      _.filter(updated_permissions_rows, r => r.entity_type === EntityType.Group),
      r => {return {entity_uuid: r.entity_id, permissions: {view: r.view, edit: r.edit }}}
    )
    const payload: PostProjectPermissions = {
      users: new_user_perms,
      groups: new_group_perms
    }

    Promise.all([
      update_project_permissions(props.project_id, payload),
      load_project_meta()
    ])
      .then(() => set_is_loading_permission_rows(false))
      .catch(error => {
        set_error_on_action({error, action: 'updating project permissions'})
      })
  }

  useEffect(() => {
    if (!project || !project.permissions) { return }

    set_is_loading_permission_rows(true)
    let current_permission_rows: PermissionRow[] = []

    for (const key of Object.keys(project.permissions.group)) {
      current_permission_rows.push({entity_id: key, entity_type: EntityType.Group, ...project.permissions.group[key]})
    }
    for (const key of Object.keys(project.permissions.user)) {
      current_permission_rows.push({entity_id: key, entity_type: EntityType.User, ...project.permissions.user[key]})
    }

    // Filter out  stuff that has view/edit false. Table keeps track but no need to display these in the ui
    current_permission_rows = _.filter(current_permission_rows, r => r.view || r.edit)

    set_permissions_rows(current_permission_rows)
    set_current_project_permission_rows(current_permission_rows)

    if (all_active_users && all_active_groups) {
      // Skip reload this if we have it already
      set_is_loading_permission_rows(false)
      return
    }
    load_kc_users_groups()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project])

  useEffect(() => {
    set_updated_permissions_rows(_.filter(permissions_rows,p => !_.find(current_project_permission_rows, c => are_permission_rows_identical(p,c))))
  }, [current_project_permission_rows, permissions_rows])

  useEffect(() => {
    if (!can_edit && current_tab_view === TAB_PERMISSIONS) {
      set_current_tab_view(TAB_REPORTS_AND_FILES)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [can_edit])

  function get_report_owners() {
    fetch_report_owners()
      .then(set_report_owners)
      .catch(err => send_error_to_sentry(err, {}))
  }

  function load_project_reports() {
    if (!project) {
      return
    }
    set_is_fetching_reports(true)
    get_project_reports(project.project_id)
      .then(reports => {
        set_project_reports(reports)
        set_is_fetching_reports(false)
        get_report_owners() // non-crucial, asynchronous call
      })
      .catch(error => {
        set_error_on_action({error, action:'loading project reports'})
        set_is_fetching_reports(false)
      })
  }

  function load_project_meta() {
    track_project_viewer_event(`obj="project" action="load_project" context="${PROJECT_TRACKING_CONTEXT}"`)
    return get_project(props.project_id)
      .then(project => {
        set_project(project)
        set_is_fetching(false)
        set_can_view(project.current_user_permissions.view)
        set_can_edit(project.current_user_permissions.edit)
      })
      .catch(() => {
        set_is_fetching(false)
        set_can_view(false)
        set_can_edit(false)
      })
  }

  function load_project_data() {
    if (!project) {
      return
    }
    set_is_fetching_files(true)
    load_project_reports()
    return get_project_files(project.project_id)
      .then(files => {
        set_project_files(files)
        set_is_fetching_files(false)
      })
      .catch(error => {
        set_is_fetching_files(false)
        set_error_on_action({error, action:'loading project files'})
      })
  }

  function load_files_history() {
    if (!project || !project_files || project_files.length === 0) {
      return
    }
    const latest_version_file_ids = project_files.map(file => file.id)
    const files_history: UserFileHistoryMap = {}
    Promise.all(project_files.map(file => get_project_file_history(project.project_id, file.s3_resource_id)))
      .then(file_histories => {
        file_histories.forEach(history => {
          const {s3_resource_id} = history[0] || {}
          files_history[s3_resource_id] = history.filter(file => !_.contains(latest_version_file_ids, file.id))
        })
        set_files_history(files_history)
        set_is_loading_files_history(false)
      })
      .catch(error => {
        set_is_loading_files_history(false)
        set_error_on_action({error, action: 'loading file history'})
      })
  }

  function load_kc_users_groups() {
    return Promise.all(
      is_admin(props.user) ? [
        // user is admin; fetch everything
        fetch_all_active_keycloak_groups(),
        fetch_all_active_keycloak_users()
      ] : [
        // user is not admin; fetch only their group
        fetch_my_keycloak_groups(),
        fetch_users_in_keycloak_group(props.user.group_ids[0])
      ])
      .then(([groups, users]) => {
        const groups_by_id = get_as_map(groups, 'id', null)
        const users_by_id = get_as_map(users, 'id', null)
        set_all_active_groups(groups_by_id)
        set_all_active_users(users_by_id)
        set_is_loading_permission_rows(false)
      })
      .catch(error => {
        set_is_loading_permission_rows(false)
        set_error_on_action({error, action: 'failed loading user share list'})
      })
  }

  useEffect(() => {
    load_project_meta()
      .catch(error => {send_error_to_sentry(error, {})})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project_id])

  useEffect(() => {
    // Exit early if it's still uploading, hook gets triggered on flip back and should only refresh after upload completes.
    if (is_uploading_files) {
      return
    }
    set_is_fetching_files(true)
    load_project_data()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.project_id, is_uploading_files])

  useEffect(() => {
    if (!project_files || project_files.length === 0) {
      return
    }
    set_is_loading_files_history(true)
    load_files_history()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project_files])

  // Refresh every 3 minutes to capture multiple users working it
  useInterval(() => {
    load_project_meta()
      .catch(error => {
        send_error_to_sentry(error, {})
      })
  }, 3 * 60 * 1000, false)

  function on_add_report_to_project(_event: any, selection: any) {
    if (!selection) {
      return
    }
    add_project_reports(props.project_id,{external_report_ids: [selection.external_report_id]})
      .then(() => load_project_reports())
      .catch(error => {
        set_error_on_action({error, action: 'updating project report links'})
      })
  }

  return (
    <div className='w-100 h-100'>
      {is_fetching &&
        <div className='text-center mt-3'>
          <Spinner size='md' />
        </div>
      }
      {!is_fetching &&
        <ContainerFullWidth
          className={cn(s.title_bar, 'd-flex')}
        >
          <TextLink
            onClick={() => props.history.push(HISTORY)}
            className={s.back_button}
            disable={false}
            element='div'
            no_decoration
            not_selectable={false}
            not_clickable={false}
          >
            <ArrowLeftIcon className={null} />
          </TextLink>
          <h3 className={s.title}>
            {project?.name}
          </h3>
        </ContainerFullWidth>
      }
      {!is_fetching && can_view &&
        <div className='px-4 w-100 h-100'>
          <ProjectNavigation
            className='mt-3'
            current_tab={current_tab_view}
            on_click_tab={(tab: string) => set_current_tab_view(tab)}
            can_edit={can_edit}
          />
          {current_tab_view === TAB_REPORTS_AND_FILES && props.project_id &&
            <>
              <ProjectReports
                project_id={props.project_id}
                can_edit={can_edit}
                user_reports={user_reports}
                project_reports={project_reports}
                report_owners={report_owners}
                is_fetching_reports={is_fetching_reports}
                selected_reports_external_ids={selected_reports_external_ids}
                toggle_selected={toggle_selected_reports}
                handle_add_reports_to_project={on_add_report_to_project}
                handle_unlink_reports_from_project={on_unlink_reports_from_project}
              />
              <ProjectFiles
                project_id={props.project_id}
                project_files={project_files}
                files_history={files_history}
                can_view={can_view}
                can_edit={can_edit}
                handle_delete={on_delete_project_file}
                handle_open_upload_modal={() => set_is_file_upload_modal_open(true)}
                is_fetching_files={is_fetching_files}
                is_loading_files_history={is_loading_files_history}
                is_deleting_file={is_deleting_file}
              />
            </>
          }
          {current_tab_view === TAB_PERMISSIONS &&
            <ProjectPermissions
              can_edit={can_edit}
              permissions_rows={permissions_rows}
              updated_permissions_rows={updated_permissions_rows}
              on_update_permissions_rows={set_permissions_rows}
              on_save_updates={on_update_project_permissions}
              all_active_groups={all_active_groups}
              all_active_users={all_active_users}
              is_loading={is_loading_permission_rows}
            />
          }
        </div>
      }

      {is_file_upload_modal_open &&
        <FileUploadModal
          title='Upload project files'
          on_hide={() => set_is_file_upload_modal_open(false)}
          on_upload={(files: File[]) => upload_project_files(files)}
          disabled={!(can_edit && is_aistemos(props.user)) || is_uploading_files}
          max_file_count={MAX_FILE_COUNT}
          max_file_size={MAX_FILE_SIZE}
        />
      }

      {!is_fetching && !can_view &&
        <div className='p-4'>You do not have permission to view this project.</div>
      }

      {error_on_action &&
        // @ts-expect-error
        <ErrorModal
          on_hide={() => set_error_on_action(null)}
          error={error_on_action.error}
          context={error_on_action.action}
        />
      }
    </div>
  )

}

export default withRouter(withUser(ProjectView))
