import React, {useState, useEffect} from 'react'
import _ from 'underscore'
import moment from 'moment'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Switch } from '@mui/material'
import ReactTable from 'react-table'
import cn from 'classnames'
import { DropdownItem } from 'reactstrap'

import SubscriptionsNavigation from './SubscriptionsNavigation.js'
import TextLink from '../widgets/TextLink.js'
import Modal from '../widgets/Modal.js'
import Spinner from '../widgets/Spinner.js'
import { PrimaryButton } from '../widgets/Button.js'
import { CogIcon, EditIcon, TrashIcon, HistoryIcon, InfoIcon } from '../widgets/IconSet.js'
import EditableTextLink from '../report_management/components/EditableTextLink.js'
import { withUser } from '../UserContext.js'
import ErrorModal from '../ErrorModal.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import PopoverAsDropdown, { PopoverAsDropdownItem } from '../widgets/PopoverAsDropdown.js'
import CheckboxStatic from '../widgets/CheckboxStatic.js'
import AlertScheduleDatePicker from './AlertScheduleDatePicker.js'
import {
  can_edit_alert_params,
  fetch_active_alerts,
  find_first_alert_date,
  find_next_date_for_saved_alert,
  get_alert_display_name,
  go_to_alert_report_history,
  rebuild_alert_report,
  unsubscribe_user,
  update_alert
} from '../../utils/alert_report_utils.js'
import { is_creator, is_view_only_user } from '../../utils/user_permissions.js'
import { is_valid_report_name } from '../../utils/name_utils.js'
import { fetch_external_report_ids } from '../../utils/report_created_utils.js'
import { get_as_map, to_local_date } from '../../utils/utils.js'
import { REPORTS, REPORT, CREATE_REPORT, HOME_PAGE } from '../../constants/paths.js'
import {
  ALERT_FREQUENCIES,
  ALERT_FREQUENCIES_BY_ID,
  ALERT_REPORT_TYPE_NAME_DELTA,
  FIELD_ALERT_FREQUENCY,
  FIELD_ALERT_NAME,
  FIELD_ALERT_TYPE,
  FIELD_CONFIGURE,
  FIELD_HISTORY,
  FIELD_LAST_ALERT,
  FIELD_NEXT_ALERT,
  FIELD_ORIGINAL_REPORT,
  FIELD_REMOVE_ALERT,
  FIELD_SKIP_EMPTY_ALERTS
} from '../../model/alert_reports.js'
import {
  ALERT_FREQUENCY_ID_QUARTERLY,
  ALERT_TYPE_ID_DELTA,
  FIELD_ID_ALERT_NAME,
  FIELD_ID_ALERT_TYPE,
  FIELD_ID_ORIGINAL_REPORT,
  NOTHING_TO_ALERT_DESCRIPTION
} from '../../constants/alert_reports.js'
import { EXPORT_SUBPATH } from '../../constants/viewer_paths.js'
import { ALERT_SETUP_ID } from '../../model/hyperscripts.js'
import { track_subscriptions_event } from '../../utils/tracking_utils.js'
import { ExternalTextLink } from '../widgets/ExternalTextLink.js'
import { get_alerts_help_url } from '../../utils/help_utils.js'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import { ASCENDING } from '../../model/sort_directions.js'
import ShareAlertModal from './ShareAlertModal.js'
import BaseDropdown from '../widgets/BaseDropdown.js'

import cs from '../cipher_styles.module.scss'
import s from './ReportBasedAlertSubscriptions.module.scss'


const ReportBasedAlertSubscriptions = ({user, history}) => {
  const [is_fetching, set_is_fetching] = useState(true)
  const [subscriptions, set_subscriptions] = useState([])
  const [report_id_lookup, set_report_id_lookup] = useState({})

  const [alert_to_unsubscribe_id, set_alert_to_unsubscribe_id] = useState(null)
  const [alert_to_rename_id, set_alert_to_rename_id] = useState(null)
  const [alert_to_reschedule_id, set_alert_to_reschedule_id] = useState(null)
  const [alert_to_change_freq_id, set_alert_to_change_freq_id] = useState(null)
  const [alert_to_share_id, set_alert_to_share_id] = useState(null)

  const [is_updating, set_is_updating] = useState(false)

  const [selected_sort_field_id, set_selected_sort_field_id] = useState(FIELD_ID_ALERT_NAME)
  const [selected_sort_direction_id, set_selected_sort_direction_id] = useState(ASCENDING)

  const [error_fetching_data, set_error_fetching_data] = useState(null)
  const [error_updating_subscriptions, set_error_updating_subscriptions] = useState(null)

  const subscriptions_by_id = get_as_map(subscriptions, 'alert_id')
  const alert_to_share = alert_to_share_id ? subscriptions_by_id[alert_to_share_id] : null

  function load_data() {
    return fetch_active_alerts()
      .then(db_subs => {
        if (_.isEmpty(db_subs)) {
          set_is_fetching(false)
          return
        }
        const original_report_ids = db_subs.map(alert => alert.original_report_internal_id).filter(id => !!id)
        const last_alert_report_ids = db_subs.map(alert => alert.last_alert_report_internal_id).filter(id => !!id)
        return fetch_external_report_ids([...original_report_ids, ...last_alert_report_ids])
          .then(internal_to_external_report_id_mapping => {
            set_subscriptions(db_subs)
            set_report_id_lookup(internal_to_external_report_id_mapping)
            set_is_fetching(false)
          })
      })
  }

  useEffect(() => {
    let did_cancel = false
    load_data()
      .catch(err => {
        if (!did_cancel) {
          set_error_fetching_data(err)
          set_is_fetching(false)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [])

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    set_selected_sort_field_id(sort_field_id)
    set_selected_sort_direction_id(sort_direction_id)
  }

  function on_unsubscribe() {
    set_alert_to_share_id(null)
    set_is_updating(true)
    track_unsubscribe()
    unsubscribe_user(alert_to_unsubscribe_id)
      .catch(err => {
        set_is_updating(false)
        set_alert_to_unsubscribe_id(null)
        set_error_updating_subscriptions(err)
        throw err
      })
      .then(() => {
        const updated_subscriptions = subscriptions.filter(subscription => subscription.alert_id !== alert_to_unsubscribe_id)
        set_is_updating(false)
        set_alert_to_unsubscribe_id(null)
        set_alert_to_rename_id(null)
        set_alert_to_change_freq_id(null)
        set_subscriptions(updated_subscriptions)
      })
  }

  function track_unsubscribe() {
    const alert_to_unsubscribe = subscriptions_by_id[alert_to_unsubscribe_id]
    const {alert_type_id} = alert_to_unsubscribe
    const alert_type_name = alert_type_id === ALERT_TYPE_ID_DELTA ? ALERT_REPORT_TYPE_NAME_DELTA : get_alert_display_name(alert_type_id)
    track_subscriptions_event(`obj="report_based_alert" action="unsubscribe_alert" origin="alert_list" alert_type="${alert_type_name}"`)
  }

  function handle_error_updating_subscriptions(err) {
    set_error_updating_subscriptions(err)
    set_is_updating(false)
    set_alert_to_rename_id(null)
    set_alert_to_rename_id(null)
    set_alert_to_change_freq_id(null)
    throw err
  }

  function on_update_alert_name(new_name) {
    set_is_updating(true)
    track_subscriptions_event('obj="report_based_alert" action="edit_alert_report_name" origin="alert_list"')
    update_alert(alert_to_rename_id, {alert_report_name: new_name})
      .catch(err => handle_error_updating_subscriptions(err))
      .then(() => {
        const subscriptions_updated = subscriptions.map(alert => {
          if (alert.alert_id === alert_to_rename_id) {
            return { ...alert, alert_report_name: new_name }
          }
          return alert
        })
        set_is_updating(false)
        set_alert_to_rename_id(null)
        set_subscriptions(subscriptions_updated)
      })
  }

  function on_update_skip_empty_alerts(alert_id, should_skip_empty_alerts) {
    const subscriptions_before_update = subscriptions
    const subscriptions_updated = subscriptions.map(alert => {
      if (alert.alert_id === alert_id) {
        return {...alert, skip_empty_alerts: should_skip_empty_alerts}
      }
      return alert
    })
    set_subscriptions(subscriptions_updated)
    track_subscriptions_event(`obj="report_based_alert" action="set_skip_empty_alerts" origin="alert_list" value="${should_skip_empty_alerts}"`)
    update_alert(alert_id, {skip_empty_alerts: should_skip_empty_alerts})
      .catch(err => {
        set_subscriptions(subscriptions_before_update)
        handle_error_updating_subscriptions(err)
      })
  }

  function on_update_alert_frequency(alert_id, new_alert_frequency_id) {
    set_alert_to_change_freq_id(alert_id)
    set_is_updating(true)
    const new_frequency_name = ALERT_FREQUENCIES_BY_ID[new_alert_frequency_id].name
    track_subscriptions_event(`obj="report_based_alert" action="update_alert_frequency" origin="alert_list" value="${new_frequency_name}"`)
    update_alert(alert_id, {alert_frequency_id: new_alert_frequency_id})
      .catch(err => handle_error_updating_subscriptions(err))
      .then(() => {
        const subscriptions_updated = subscriptions.map(alert => {
          if (alert.alert_id === alert_id) {
            return { ...alert, alert_frequency_id: new_alert_frequency_id }
          }
          return alert
        })
        set_is_updating(false)
        set_subscriptions(subscriptions_updated)
        set_alert_to_change_freq_id(null)
      })
  }

  function on_update_next_alert_date(alert_id, new_next_alert_date) {
    set_alert_to_reschedule_id(alert_id)
    set_is_updating(true)
    track_subscriptions_event('obj="report_based_alert" action="update_next_alert_date" origin="alert_list"')
    update_alert(alert_id, {next_alert: new_next_alert_date})
      .catch(err => handle_error_updating_subscriptions(err))
      .then(() => {
        const subscriptions_updated = subscriptions.map(alert => {
          if (alert.alert_id === alert_id) {
            return { ...alert, next_alert: new_next_alert_date }
          }
          return alert
        })
        set_is_updating(false)
        set_alert_to_reschedule_id(null)
        set_subscriptions(subscriptions_updated)
      })
  }

  function render_config_menu_button({original: alert}) {
    const {alert_id, original_report_internal_id, is_owner, is_stopped} = alert
    const original_report = original_report_internal_id ? report_id_lookup[original_report_internal_id] : {}
    const {external_report_id} = original_report || {}
    const edit_url = external_report_id ? `${REPORT}/${external_report_id}/${EXPORT_SUBPATH}/${ALERT_SETUP_ID}/${alert_id}` : null

    if (is_stopped) {
      return render_stopped_alert_message_popup(alert, true)
    }

    return (
      <BaseDropdown
        hide_chevron={true}
        label={<CogIcon/>}
        buttonClassName={cn(s.alert_param_dropdown_button, 'w-100')}
        labelClassName={cn(s.alert_param_dropdown_button, {[cs.link_text]: is_owner}, 'w-100 d-flex justify-content-center')}
        menuClassName='text-wrap'
        disabled={!is_owner}
      >
        <DropdownItem
          onClick={() => history.push(edit_url)}
          disabled={!edit_url}
        >
          <span>Edit parameters</span>
        </DropdownItem>
        <DropdownItem
          onClick={() => set_alert_to_share_id(alert_id)}
        >
          <span>Edit recipients</span>
        </DropdownItem>
      </BaseDropdown>
    )
  }

  function render_alert_name({original: alert}) {
    const {alert_report_name, alert_id} = alert

    if (alert_to_rename_id !== alert_id) {
      return (
        <div className='d-flex overflow-hidden'>
          <div className='overflow-hidden'>{alert_report_name}</div>
          {can_edit_alert_params(alert) &&
            <TextLink
              title='Rename'
              onClick={() => set_alert_to_rename_id(alert_id)}
              className='ps-2'
              no_decoration
            >
              <EditIcon/>
            </TextLink>
          }
        </div>
      )
    }
    if (is_updating) {
      return <Spinner size='sm'/>
    }
    return (
      <EditableTextLink
        is_edit={true}
        link_text={alert_report_name}
        on_confirm={on_update_alert_name}
        on_cancel={() => set_alert_to_rename_id(null)}
        is_valid={is_valid_report_name}
        inputClassName={cs.in_table_input}
      />
    )
  }
  
  function render_alert_frequency({original: alert}) {
    const {alert_id, alert_frequency_id} = alert
    const selected_frequency = ALERT_FREQUENCIES_BY_ID[alert_frequency_id].name
    if (is_updating && alert_id === alert_to_change_freq_id) {
      return (<Spinner size='sm'/>)
    }
    if (!can_edit_alert_params(alert)) {
      return (<div>{selected_frequency}</div>)
    }
    return (
      <PopoverAsDropdown
        label={selected_frequency}
        buttonClassName={s.alert_param_dropdown_button}
        labelClassName={s.alert_param_dropdown_button}
        popupClassName={s.frequency_menu}
      >
        {ALERT_FREQUENCIES.map(frequency => {
          return (
            <PopoverAsDropdownItem
              className='transparent'
              key={frequency.id}
              toggle={true}
              disabled={is_updating}
              is_active={frequency.id === alert_frequency_id}
              on_click={() => on_update_alert_frequency(alert_id, frequency.id)}
            >
              {frequency.name}
            </PopoverAsDropdownItem>
          )
        })}
      </PopoverAsDropdown>
    )
  }

  function render_skip_empty_alerts({original: alert}) {
    const {alert_id, skip_empty_alerts} = alert
    return (
      <div className='d-flex justify-content-center'>
        <Switch
          checked={!skip_empty_alerts}
          onClick={() => on_update_skip_empty_alerts(alert_id, !skip_empty_alerts)}
        />
        <CheckboxStatic
          className='d-none'
          is_checked={!skip_empty_alerts}
          onChange={() => on_update_skip_empty_alerts(alert_id, !skip_empty_alerts)}/>
      </div>
    )
  }

  function render_stopped_alert_message_popup(alert, icon_only) {
    const {original_report_internal_id} = alert
    const can_fix_alert = can_edit_alert_params(alert) && is_creator(user)
    const original_report = original_report_internal_id ? report_id_lookup[original_report_internal_id] : {}
    const {external_report_id} = original_report || {}
    return (
      <InfoPopover
        interactive={true}
        toggler={icon_only ? <span className='d-flex justify-content-center mt-2'><InfoIcon/></span> : <span className='ms-1'><InfoIcon/><span className={cn('ms-1', cs.underline_on_hover)}>Alert paused</span></span>}
        placement='bottom'
        arrow={false}
        popupClassName={s.popover}
      >
        <div className='text-wrap'>
          <div className='mb-2'>
            This alert is currently paused because there is a problem with its input{can_fix_alert ? '.' : ', and the owner has been notified.'}
          </div>
          <div className='mb-2'>
            It is likely that something has changed with the organisations or technologies from the original report, and these parameters now need updating to resume the alert.
          </div>
          {can_fix_alert &&
            <TextLink
              element='div'
              onClick={() => rebuild_alert_report(alert, external_report_id, history)}
            >
              Create {external_report_id ? 'an updated' : 'a new'} base report to update the alert
            </TextLink>
          }
        </div>
      </InfoPopover>
    )
  }

  function render_next_alert_due({original: alert}) {
    const {alert_id, is_stopped} = alert
    const next_alert_date = find_next_date_for_saved_alert(alert)
    const max_date = find_first_alert_date(ALERT_FREQUENCY_ID_QUARTERLY)
    const today_date = moment(new Date()).startOf('day').toDate()
    const is_rescheduling = (is_updating && alert_to_reschedule_id === alert_id)

    if (is_rescheduling) {
      return (<Spinner size='sm'/>)
    }
    if (is_stopped) {
      return render_stopped_alert_message_popup(alert)
    }
    return (
      <AlertScheduleDatePicker
        id={alert_id}
        min_date={today_date}
        max_date={max_date}
        on_select={selected_date => on_update_next_alert_date(alert_id, selected_date)}
        selected_date={next_alert_date}
        disabled={!can_edit_alert_params(alert)}
        className='h-100'
        iconClassName={s.calendar_icon}
      />
    )
  }

  function render_unsubscribe_link({original: alert}) {
    const {alert_id} = alert
    return (
      <TextLink
        title='Unsubscribe'
        onClick={() => set_alert_to_unsubscribe_id(alert_id)}
        no_decoration
        className='d-flex justify-content-center mt-2'
      >
        <TrashIcon/>
      </TextLink>
    )
  }

  function render_history_link({original: alert}) {
    return (
      <TextLink
        title='History for this alert'
        onClick={() => go_to_alert_report_history(alert, history)}
        no_decoration
        className='d-flex justify-content-center mt-2'
      >
        <HistoryIcon/>
      </TextLink>
    )
  }

  function render_recent_report_link({original: alert}) {
    const {last_alert_report_internal_id} = alert
    const last_alert_report = last_alert_report_internal_id ? report_id_lookup[last_alert_report_internal_id] : {}
    const {external_report_id, created_at} = last_alert_report || {}
    return render_report_link(to_local_date(created_at), external_report_id)
  }

  function render_original_report_link({original: alert}) {
    const {original_report_internal_id} = alert
    const original_report = original_report_internal_id ? report_id_lookup[original_report_internal_id] : {}
    const {external_report_id, created_at} = original_report || {}
    return render_report_link(to_local_date(created_at), external_report_id)
  }

  function render_report_link(link_text, external_report_id) {
    if (external_report_id) {
      return (
        <TextLink
          element={Link}
          to={`${REPORT}/${external_report_id}`}
        >
          {link_text}
        </TextLink>
      )
    }
    return ''
  }

  function render_column_header(field) {
    return (
      <SortingColumnHeaderCell
        field={field}
        selected_sort_field_id={selected_sort_field_id}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}
      />
    )
  }

  const columns = [
    {
      ...FIELD_ALERT_NAME,
      Header: render_column_header(FIELD_ALERT_NAME),
      Cell: render_alert_name,
      sortable: false,
      minWidth: 220
    },
    {
      ...FIELD_CONFIGURE,
      Header: FIELD_CONFIGURE.name,
      className: s.field_with_overflow,
      Cell: render_config_menu_button,
      width: 100,
      sortable: false
    },
    {
      ...FIELD_ALERT_TYPE,
      Header: render_column_header(FIELD_ALERT_TYPE),
      Cell: ({row: alert}) => {
        const {alert_type_id} = alert
        return get_alert_display_name(alert_type_id)
      },
      width: 170,
      sortable: false
    },
    {
      ...FIELD_ALERT_FREQUENCY,
      Header: render_column_header(FIELD_ALERT_FREQUENCY),
      className: s.field_with_overflow,
      Cell: render_alert_frequency,
      width: 150,
      sortable: false
    },
    {
      ...FIELD_ORIGINAL_REPORT,
      Header: render_column_header(FIELD_ORIGINAL_REPORT),
      Cell: render_original_report_link,
      sortable: false,
      align: 'center',
      width: 150
    },
    {
      ...FIELD_LAST_ALERT,
      Header: render_column_header(FIELD_LAST_ALERT),
      Cell: render_recent_report_link,
      align: 'center',
      width: 125,
      sortable: false
    },
    {
      ...FIELD_HISTORY,
      Header: FIELD_HISTORY.name,
      Cell: render_history_link,
      align: 'center',
      width: 85
    },
    {
      ...FIELD_NEXT_ALERT,
      Header: render_column_header(FIELD_NEXT_ALERT),
      Cell: render_next_alert_due,
      className: s.field_with_overflow,
      align: 'left',
      width: 140,
      sortable: false
    },
    {
      ...FIELD_SKIP_EMPTY_ALERTS,
      Header: () => {
        return (
          <>
            <span className={s.column_header_title}>Empty alerts</span>
            <InfoPopover
              buttonClassName='ms-1'
              placement='bottom'
              arrow={false}
              popupClassName={s.popover}
            >
              {NOTHING_TO_ALERT_DESCRIPTION}.
            </InfoPopover>
          </>
        )
      },
      Cell: render_skip_empty_alerts,
      width: 140
    },
    {
      ...FIELD_REMOVE_ALERT,
      Header: FIELD_REMOVE_ALERT.name,
      Cell: render_unsubscribe_link,
      align: 'center',
      width: 85
    }
  ]

  function get_row_value_for_sorting(row) {
    const row_value = row[selected_sort_field_id]
    switch (selected_sort_field_id) {
      case FIELD_ID_ALERT_TYPE:
        return get_alert_display_name(row_value)
      case FIELD_ID_ORIGINAL_REPORT:
        const original_report = row_value ? report_id_lookup[row_value] : {}
        const {created_at} = original_report || {}
        return created_at
      default:
        return row_value
    }
  }

  function sort_subscriptions_rows() {
    const sorted_data = _.sortBy(subscriptions, row => {
      const value = get_row_value_for_sorting(row) || ''
      return _.isString(value) ? value.toLowerCase() : value
    })
    return selected_sort_direction_id === ASCENDING ? sorted_data : sorted_data.slice().reverse()
  }

  const alert_to_unsubscribe = alert_to_unsubscribe_id ? _.findWhere(subscriptions, {alert_id: alert_to_unsubscribe_id}).alert_report_name : null
  const has_alerts_to_show = subscriptions && !_.isEmpty(subscriptions)

  const subscriptions_sorted = sort_subscriptions_rows()

  return (
    <div className='w-100 h-100'>
      {(error_updating_subscriptions || error_fetching_data) &&
        <ErrorModal
          on_hide={() => error_updating_subscriptions ? set_error_updating_subscriptions(null) : error_fetching_data(null)}
          error={error_updating_subscriptions || error_fetching_data}
          context={error_updating_subscriptions ? 'updating subscriptions' : 'fetching subscriptions data'}
        />
      }

      {alert_to_share &&
        <ShareAlertModal
          alert={alert_to_share}
          on_close={() => set_alert_to_share_id(null)}
          handle_confirm_unsubscribe_self={set_alert_to_unsubscribe_id}
        />
      }

      {alert_to_unsubscribe_id &&
        <Modal
          title={`Unsubscribing from '${alert_to_unsubscribe}'`}
          on_hide={() => set_alert_to_unsubscribe_id(null)}
          close_label='Cancel'

          confirm_label='Unsubscribe'
          on_confirm={on_unsubscribe}
          size='sm'
          primary_button={
            <PrimaryButton onClick={on_unsubscribe} disabled={is_updating}>
              Unsubscribe
            </PrimaryButton>
          }
          footer={is_updating ? <Spinner size='sm'/> : null}
        >
          <div>Are you sure you want to unsubscribe from this alert?</div>
          <div>This action can not be undone.</div>
        </Modal>
      }

      <div className='w-100 h-100 p-3'>
        <SubscriptionsNavigation
          current_page={REPORTS}
        />

        {is_fetching &&
          <div className='text-center'>
            <Spinner/>
          </div>
        }

        {!is_fetching && !error_fetching_data && !has_alerts_to_show &&
          <div className='mt-3'>
            {is_view_only_user(user) &&
              <p>You don't have any active report-based alerts.</p>
            }
            {!is_view_only_user(user) &&
              <>
                <p>To begin monitoring organisations, technologies or patent families,&nbsp;
                  <TextLink element={Link} to={is_creator(user) ? CREATE_REPORT : HOME_PAGE}>
                    build a report
                  </TextLink>
                  &nbsp;and then choose 'Create an alert' from the Exports menu.
                </p>
                <ExternalTextLink url={get_alerts_help_url()} target='_blank'>
                  Help centre article
                </ExternalTextLink>
              </>
            }
          </div>
        }

        {!is_fetching && !error_fetching_data && has_alerts_to_show &&
          <div className='h-100 overflow-auto'>
            <ReactTable
              className={cn('border-0 w-100 -striped', s.table)}
              manual={true}
              showPagination={false}
              sortable={false}
              filterable={false}
              columns={columns}
              data={subscriptions_sorted}
              minRows={5}
              getTableProps={() => ({className: 'overflow-visible'})}
              getTbodyProps={() => ({className: 'overflow-visible'})}
              getTrProps={(final_state, row_info) => {
                // make row_info accessible for extra styling
                return {row_info}
              }}
              TrComponent={({children, className, row_info, ...rest}) => {
                // add special styling for a broken/ stopped alert
                const {original} = row_info || {} // i.e. header doesn't have a row_info object

                return (
                  <div
                    className={cn(
                      'rt-tr',
                      (original && original.is_stopped ? s.stopped_alert_row : className)
                    )}
                    role='row'
                    {...rest}
                  >
                    {children}
                  </div>
                )
              }}
            />
          </div>

        }
      </div>
    </div>
  )
}

export default withRouter(withUser(ReportBasedAlertSubscriptions))