import { createFilterOptions } from '@mui/material/Autocomplete'
import { GROUP, IS_ME, IS_ME_PREFIX, IS_SOLUTIONS, USER } from '../model/user_info.js'
import { format_string_only_first_character_capitalised } from './utils'

const GROUP_PREFIX = '(GROUP)'
const SOLUTIONS_TEAM_PREFIX = '(CIPHER)'

function get_solutions_label(destination) {
  const { first_name, last_name } = destination
  const last_name_initial = last_name ? last_name[0].toUpperCase() : ''
  const fist_name_string = first_name ? format_string_only_first_character_capitalised(first_name) : ''
  return `${SOLUTIONS_TEAM_PREFIX} ${fist_name_string} ${last_name_initial}.`
}

export function get_destination_label(destination) {
  const { type, email, name, [IS_ME]: is_me, [IS_SOLUTIONS]: is_solutions } = destination

  if (type === USER) {
    if (is_solutions) {
      const label = get_solutions_label(destination)
      return label
    }
    return (is_me ? `${IS_ME_PREFIX} ` : '') + email
  }

  if (type === GROUP) {
    return `${GROUP_PREFIX} ${name}`
  }

  throw Error(`type not recognised: '${type}'`)
}

function stringify_destination(destination) {
  const { type, name, email, first_name, last_name, [IS_ME]: is_me, [IS_SOLUTIONS]: is_soltions } = destination
  const name_string = [first_name, last_name].filter(n => n != null).join(' ')

  if (type === USER) {
    const base_user_string = email + name_string
    if (is_me) {
      return IS_ME_PREFIX + base_user_string
    }
    if (is_soltions) {
      const solutions_label = get_solutions_label(destination)
      return solutions_label + base_user_string
    }
    return base_user_string
  }

  if (type === GROUP) {
    return `${GROUP_PREFIX} ${name} group ${name}` // handle case with "(group)" and "group"
  }

  throw Error(`type not recognised: ${type}`)
}

export const MUI_destination_filter_options = createFilterOptions({
  limit: 200, // limit number of results, otherwise searches for a single letter render sloooow
  stringify: stringify_destination
})