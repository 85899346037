import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { withRouter } from 'react-router-dom'
import { Switch } from '@mui/material'

import {
  get_cipher_other_hostname,
  should_enable_switch
} from '../../utils/switch_cipher.js'
import { get_static_classname, track_user_event } from '../../utils/tracking_utils.js'

import cs from '../cipher_styles.module.scss'

const CipherSwitch = ({location}) => {

  const [show_other_cipher, set_show_other_cipher] = useState(false)

  useEffect(() => {
    if (show_other_cipher) {
      const {pathname, search} = location
      const url = `${get_cipher_other_hostname()}${pathname}${search ? search : ''}`
      window.location.replace(url)
    }
  }, [show_other_cipher, location])

  function switch_to_other_cipher() {
    track_user_event('action="switch_cipher" target="old_design"')
    set_show_other_cipher(true)
  }

  const is_enabled = should_enable_switch()

  if (!is_enabled) return null

  return (
    <div className={cn('d-flex flex-nowrap me-3 fs-unmask', get_static_classname('switch_to_old'), cs.cursor_pointer )} onClick={switch_to_other_cipher} title='Switch to current design'>
      <span className={cn('my-auto', cs.white_text)}>
        New Design
      </span>
      <Switch
        sx={{
          '.MuiSwitch-track' : {
            backgroundColor: '#006ebb !important', //ps light blue 800
            opacity: '0.7 !important'
          },

          '&.MuiSwitch-root .Mui-checked': {
            color: '#4FC3F7' //ps light blue 300
          }
        }}
        className='my-auto'
        checked={true}
      />
    </div>
  )
}

export default withRouter(CipherSwitch)
