import _ from 'underscore'
import axios from 'axios'
import qs from 'query-string'
import moment from 'moment'

import {
  CLASSIFIER_LANDSCAPE_REPORT_TYPE,
  COMPARABLES_IN_SPEEDY_REPORT_COUNT,
  DEFAULT_REPORT_TYPE,
  MAX_REPORT_NAME_LENGTH,
  ND_REPORT_TYPE,
  NO_REBUILD_REPORT_TYPES,
  UTT_LANDSCAPE_REPORT_TYPE,
  UTT_REPORT_TYPE
} from '../constants/constants.js'

import {
  get_org_as_portfolio_item,
  get_patent_upload_as_portfolio_item,
  is_family_tag_type,
  is_patent_families_type,
  is_tech_search_type
} from '../model/portfolio_basket.js'
import { get_as_map, is_number } from './utils.js'
import { add_source_err_to_target_err } from './axios_utils.js'
import { save_report_created, update_report_internal_id } from './report_created_utils.js'
import { check_for_existing_and_in_progress_reports, create_report_on_choreo } from './choreo_utils.js'
import { STATUS_QUEUED } from '../model/report_tasks_and_statuses.js'
import { get_data_version } from './domain_utils.js'
import { fetch_report_input } from './report_reader_utils.js'
import { REPORT_INPUT_BASE_URL, REPORT_STREAM_BASE_URL, SUBSET_REPORT_GET_INPUT_URL } from '../constants/urls.js'

import { get_short_company_name, remove_last_special_character_from_text } from './name_utils.js'
import {
  check_for_invalid_organisations,
  get_org_item_size_input,
  get_org_suggestions_by_org_ids,
  get_organisation_size,
  is_organisation,
  is_org_group_type,
  is_org_type
} from './organisation_utils.js'
import {
  build_classifier_alert_report_input,
  build_generic_builder_report_input,
  build_manual_clustering_report_input,
  build_utt_landscape_report_input
} from './report_input_utils.js'
import { is_nd_report_type } from './report_utils.js'
import { get_family_ids_filtered, get_bool_search_items_sizes } from './patent_family_list_utils.js'
import { create_search_name, find_similar_families_by_input_id } from './knn_search.js'
import { COMPARABLES_BY_SIMILAR_SIZE } from '../model/organisation.js'
import { get_id_to_classifier } from './classifier_group_utils.js'
import { get_clean_classifier_description } from './classifier_description_utils.js'
import { BUILD_ND_REPORT, BUILD_REPORT, REPORT } from '../constants/paths.js'
import { EXPORT_SUBPATH, SELECTED_SUBPATH } from '../constants/viewer_paths.js'
import { ALERT_SETUP_ID, GOOGLE_VALUATION_ID, ND_REPORT_ID } from '../model/hyperscripts.js'
import { CN, EP, IP5_COUNTRIES, JP, KR, UPC_COUNTRIES, US } from '../constants/countries.js'
import { has_us_centric_territories } from './user_permissions.js'
import { TECH_PARTITIONING_TYPE_UTT } from '../model/technology_basket.js'
import { add_parent_refs, get_leaf_nodes_as_array } from './classifier_tree_utils'
import { PORTFOLIO_SIZE_GROUP_ID } from '../model/spec_groups/spec_group_ids.js'
import { ALL_FAMILIES_BY_TECH_ID } from '../model/spec_ids.js'
import { save_eval_report_selected_charts_in_state } from './report_state_utils.js'

export const PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID = 'org_search'
export const PORTFOLIO_SEARCH_TYPE_FAMILIES_SEARCH_ID = 'families_search'
export const PORTFOLIO_SEARCH_TYPE_CUSTOM_UPLOAD_ID = 'upload'
export const PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID = 'landscape'
export const PORTFOLIO_SEARCH_TYPE_ORG_SETS_ID = 'org_sets' //used in previous versions of the portfolio basket

const PORTFOLIO_SEARCH_TYPE = [
  {id: PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID, title: 'Landscape', short_title: 'Landscape'},
  {id: PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID, title: 'Organisation search', short_title: 'Org search'},
  {id: PORTFOLIO_SEARCH_TYPE_FAMILIES_SEARCH_ID, title: 'Boolean search', short_title: 'Boolean search'},
  {id:'tag_sets', title: 'Tagged families', short_title: 'Tags'},
  {id: PORTFOLIO_SEARCH_TYPE_CUSTOM_UPLOAD_ID, title: 'Patent upload', short_title: 'Patent upload'},
]

export const PORTFOLIO_SEARCH_TYPE_BY_ID = get_as_map(PORTFOLIO_SEARCH_TYPE, 'id')


export function get_default_portfolio_search_mode({portfolio_search_mode, is_valuation_report, is_nd_report}) {
  const available_portfolio_search_modes = [...Object.keys(PORTFOLIO_SEARCH_TYPE_BY_ID), PORTFOLIO_SEARCH_TYPE_ORG_SETS_ID]

  if (portfolio_search_mode == null || (available_portfolio_search_modes.indexOf(portfolio_search_mode) === -1)) {
    if (is_valuation_report) {
      return PORTFOLIO_SEARCH_TYPE_BY_ID[PORTFOLIO_SEARCH_TYPE_CUSTOM_UPLOAD_ID]
    }

    if (is_nd_report) {
      return PORTFOLIO_SEARCH_TYPE_BY_ID[PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID]
    }

    return PORTFOLIO_SEARCH_TYPE_BY_ID[PORTFOLIO_SEARCH_TYPE_LANDSCAPE_SEARCH_ID]
  }

  return portfolio_search_mode === 'org_sets' ? PORTFOLIO_SEARCH_TYPE_BY_ID[PORTFOLIO_SEARCH_TYPE_ORG_SEARCH_ID] : PORTFOLIO_SEARCH_TYPE_BY_ID[portfolio_search_mode]
}

export const REPORT_BUILDER_MODE_ID_PORTFOLIO = 'portfolio'
export const REPORT_BUILDER_MODE_ID_TECHNOLOGY = 'technology'
export const REPORT_BUILDER_MODE_ID_OPTIONS = 'options'

const REPORT_BUILDER_MODE = [
  {id: REPORT_BUILDER_MODE_ID_PORTFOLIO,  title: 'Portfolio search'},
  {id: REPORT_BUILDER_MODE_ID_TECHNOLOGY, title: 'Technology filter'},
  {id: REPORT_BUILDER_MODE_ID_OPTIONS,    title: 'Options'}
]

export const REPORT_BUILDER_MODE_BY_ID = get_as_map(REPORT_BUILDER_MODE, 'id')

export const CACHED_REPORT_THRESHOLD_RIGID = 'rigid'
export const CACHED_REPORT_THRESHOLD_STRICT = 'strict'
export const CACHED_REPORT_THRESHOLD_RELAXED = 'relaxed'

const CACHED_REPORT_THRESHOLDS = [
  {id: [CACHED_REPORT_THRESHOLD_RIGID], data_version_difference: 0},
  {id: [CACHED_REPORT_THRESHOLD_STRICT], age_in_days: 14, data_version_difference: 2},
  {id: [CACHED_REPORT_THRESHOLD_RELAXED], age_in_days: 28, data_version_difference: 4}
]

export const CACHED_REPORT_THRESHOLDS_BY_ID = get_as_map(CACHED_REPORT_THRESHOLDS, 'id')

export const PORTFOLIO_SIZE_WARNING_THRESHOLD = 2000000
export const PORTFOLIO_SIZE_NO_PASS_THRESHOLD = 4500000

export const CLASSIFIER_LANDSCAPE_LIMIT = 25
export const AISTEMOS_CLASSIFIER_LANDSCAPE_LIMIT = 50

export const ALL_FAMILIES_LANDSCAPE_OPTION_ID = 'all_families'

const ALL_FAMILIES_LANDSCAPE_OPTION    = { id: ALL_FAMILIES_LANDSCAPE_OPTION_ID,  name: 'All families' }
const ACTIVE_FAMILIES_LANDSCAPE_OPTION = { id: 'active_families', name: 'Active families', search_phrase: 'ST:(granted OR pending)' }
const EXCLUDE_CHINA_LANDSCAPE_OPTION   = { id: 'exclude_cn', name: 'Exclude China only', search_phrase: `NOT OIT:${CN}`, check_if_available: ({user}) => has_us_centric_territories(user) }

const ALL_FAMILIES_IP5 = { id: 'all_ip5', name: 'All families in IP5 countries', short_name: 'IP5 countries', search_phrase: `EEIT:(${IP5_COUNTRIES.join(' OR ')})` }
const ALL_FAMILIES_UPC = { id: 'all_upc', name: 'All families in UPC countries', short_name: 'UPC countries', search_phrase: `EEIT:(${UPC_COUNTRIES.join(' OR ')})` }
const ALL_FAMILIES_US =  { id: 'all_us',  name: 'All families in US',            short_name: 'US',            search_phrase: `EEIT:${US}` }

export const ACTIVE_FAMILIES_GEO_OPTIONS = [
  { id: 'active_us',  name: 'Active families in US',              short_name: 'US',            search_phrase: `GIT:${US} OR PIT:${US}` },
  { id: 'active_cn',  name: 'Active families in China',           short_name: 'China',         search_phrase: `GIT:${CN} OR PIT:${CN}` },
  { id: 'active_jp',  name: 'Active families in Japan',           short_name: 'Japan',         search_phrase: `GIT:${JP} OR PIT:${JP}` },
  { id: 'active_kr',  name: 'Active families in Korea',           short_name: 'Korea',         search_phrase: `GIT:${KR} OR PIT:${KR}` },
  { id: 'active_epo', name: 'Active families in EPO',             short_name: 'EPO',           search_phrase: `GIT:${EP} OR PIT:${EP}` },
  { id: 'active_ip5', name: 'Active families in IP5 countries',   short_name: 'IP5 countries', search_phrase: `GIT:(${IP5_COUNTRIES.join(' OR ')}) OR PIT:(${IP5_COUNTRIES.join(' OR ')})` },
  { id: 'active_upc', name: 'Active families in UPC countries',   short_name: 'UPC countries', search_phrase: `GIT:(${UPC_COUNTRIES.join(' OR ')}) OR PIT:(${UPC_COUNTRIES.join(' OR ')})` },
]

export const ALL_FAMILIES_GEO_OPTIONS = [
  ALL_FAMILIES_US,
  { id: 'all_cn',  name: 'All families in China',         short_name: 'China',         search_phrase: `EEIT:${CN}` },
  { id: 'all_jp',  name: 'All families in Japan',         short_name: 'Japan',         search_phrase: `EEIT:${JP}` },
  { id: 'all_kr',  name: 'All families in Korea',         short_name: 'Korea',         search_phrase: `EEIT:${KR}` },
  { id: 'all_epo', name: 'All families in EPO',           short_name: 'EPO',           search_phrase: `EEIT:${EP}` },
  ALL_FAMILIES_IP5,
  ALL_FAMILIES_UPC,
]

const ALL_LANDSCAPES = [
  ALL_FAMILIES_LANDSCAPE_OPTION,
  ACTIVE_FAMILIES_GEO_OPTIONS,
  EXCLUDE_CHINA_LANDSCAPE_OPTION,
  ...ALL_FAMILIES_GEO_OPTIONS,
  ...ACTIVE_FAMILIES_GEO_OPTIONS
]

export const ID_TO_LANDSCAPE = get_as_map(ALL_LANDSCAPES, 'id')

export const WIZARD_LANDSCAPES = [
  ALL_FAMILIES_LANDSCAPE_OPTION,
  { ...ALL_FAMILIES_IP5, name: 'IP5 countries' },
  {...ALL_FAMILIES_US, name: 'including US'},
  {...ALL_FAMILIES_UPC, name: 'UPC countries'},
  EXCLUDE_CHINA_LANDSCAPE_OPTION
]

export const ID_TO_WIZARD_LANDSCAPE = get_as_map(WIZARD_LANDSCAPES, 'id')

export const LANDSCAPE_GROUPS = [
  { ...ALL_FAMILIES_LANDSCAPE_OPTION, options: ALL_FAMILIES_GEO_OPTIONS },
  { ...ACTIVE_FAMILIES_LANDSCAPE_OPTION,  options: ACTIVE_FAMILIES_GEO_OPTIONS },
  EXCLUDE_CHINA_LANDSCAPE_OPTION
]

export const ID_TO_LANDSCAPE_GROUP = get_as_map(LANDSCAPE_GROUPS, 'id')

export const OPTION_ID_TO_LANDSCAPE_GROUP_ID = LANDSCAPE_GROUPS.reduce((acc, item) => {
  const { options=[] } = item
  options.forEach(option => {
    acc[option.id] = item.id
  })
  return acc
}, {})

export function get_preselected_technology_basket_for_eval_classifier(classifier_id, classifier_groups) {
  const id_to_classifier = get_id_to_classifier(classifier_groups)

  const eval_classifier = id_to_classifier[classifier_id]
  if (!eval_classifier) {
    return []
  }

  const { name, description, taxonomy_path } = eval_classifier
  return build_technology_basket_for_single_classifier(classifier_id, name, description, taxonomy_path)
}

export function build_technology_basket_for_single_classifier(classifier_id, classifier_title, classifier_description, taxonomy_path) {

  const description = get_clean_classifier_description(classifier_description)

  return [{
    path: taxonomy_path || [], // must be non-null
    classifier_id,
    name: classifier_title,
    ...description ? {description} : {}
  }]
}

function get_comparables_for_speedy_org_report(org, is_fetch_comparables) {
  if (!is_fetch_comparables) return Promise.resolve({})

  return get_org_suggestions_by_org_ids([org])
}

export function run_speedy_org_report(org, report_type, is_fetch_comparables, utt_version) {
  const report_name = org.name

  return get_comparables_for_speedy_org_report(org, is_fetch_comparables)
    .then(comparables => {
      const org_suggestions = (comparables || {})[COMPARABLES_BY_SIMILAR_SIZE] || []
      const portfolios = [get_org_as_portfolio_item(org), ...org_suggestions.slice(0, COMPARABLES_IN_SPEEDY_REPORT_COUNT).map(item => get_org_as_portfolio_item(item))]
      const report_input = build_generic_builder_report_input({
        report_name,
        portfolios,
        report_type: report_type || DEFAULT_REPORT_TYPE,
        portfolios_to_cluster: [0],
        utt_version
      })

      return existing_or_new_report(report_input, report_name, CACHED_REPORT_THRESHOLD_STRICT)
    })
}

export function run_speedy_utt_landscape_report(classifier, report_name, utt_version) {
  const report_input = build_utt_landscape_report_input({report_name, classifier, utt_version})
  return existing_or_new_report(report_input, report_name, CACHED_REPORT_THRESHOLD_STRICT)
}

export function run_manual_clustering_report(user_report_name, portfolios, technologies, evaluation_classifier_id) {
  const auto_report_name = 'Custom clustered report'
  const report_input = build_manual_clustering_report_input({report_name: auto_report_name, portfolios, technologies, evaluation_classifier_id})

  return build_report(report_input, user_report_name)
}

export function run_classifier_alert_report(report_name, pat_fam_ids, classifier_name)
{
  const report_input = build_classifier_alert_report_input({
    report_name,
    pat_fam_ids,
    classifier_name
  })
  return build_report(report_input, report_name)
}

export function existing_or_new_report(report_input, report_name, cached_report_threshold_type) {
  if (cached_report_threshold_type == null) {
    return build_report(report_input, report_name)
  }

  return Promise.all([check_for_existing_and_in_progress_reports(report_input), get_data_version()])
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to check for existing reports: ')
      throw wrapped_err
    })
    .then(([{completed_report}, current_data_version]) => {
      if (can_use_existing_report(completed_report, current_data_version.data_version, cached_report_threshold_type)) {
        const {report_id:existing_internal_report_id} = completed_report || {}
        return existing_internal_report_id ? save_new_report_with_existing_internal_id({existing_internal_report_id, report_name, report_input}) : build_report(report_input, report_name)
      } else {
        return build_report(report_input, report_name)
      }
    })
}

export function can_use_existing_report(existing_report, current_data_version, threshold_mode) {
  if (!existing_report) return false

  const {data_version_difference, age_in_days} = CACHED_REPORT_THRESHOLDS_BY_ID[threshold_mode] || {}

  const is_age_ok = age_in_days ? is_existing_report_age_ok(existing_report, age_in_days) : true // no need to check if reports must be from the same data version
  const is_version_ok = is_existing_report_data_version_ok(existing_report, current_data_version, data_version_difference)

  return ( is_age_ok && is_version_ok )
}

export function get_version_appropriate_existing_reports(existing_report, in_progress_report, data_version) {
  const existing_report_clean    = can_use_existing_report(existing_report,    data_version, CACHED_REPORT_THRESHOLD_RELAXED) ? existing_report    : null
  const in_progress_report_clean = can_use_existing_report(in_progress_report, data_version, CACHED_REPORT_THRESHOLD_RELAXED) ? in_progress_report : null
  return {
    existing_report:    existing_report_clean,
    in_progress_report: in_progress_report_clean
  }
}

function is_existing_report_age_ok(existing_report, threshold) {
  const { timestamp } = existing_report
  const now = moment(new Date())
  const report_date_created = moment(timestamp)
  const report_age = moment.duration(now.diff(report_date_created))

  return report_age.asDays() <= threshold
}

function is_existing_report_data_version_ok(existing_report, current_data_version, threshold) {
  const report_data_version = existing_report.data_version
  return (report_data_version !== -1) && (is_number(current_data_version)) && (parseInt(current_data_version) - report_data_version <= threshold)
}

export function save_new_report_with_existing_internal_id({existing_internal_report_id, report_name, report_input}) {
  const report_type = get_report_type(report_input)
  const { meta={}, name } = report_input
  const { evaluation_classifier_id } = (meta || {})
  return save_report_created(existing_internal_report_id, (report_name || name), report_type, null /* external_report_id */, evaluation_classifier_id)
}

export function rerun_report_and_replace_id(internal_report_id, external_report_id) {
  return fetch_report_input(internal_report_id)
    .then(report_input => create_report_on_choreo(report_input))
    .then(choreo_response => choreo_response.report_id)
    // update the frontend database with the new report id
    .then(new_internal_report_id => update_report_internal_id(external_report_id, new_internal_report_id))
}

export function get_report_type(report_input) {
  return report_input['report_type'] || DEFAULT_REPORT_TYPE
}

export function build_report(report_input, db_meta_report_name) {
  const report_type = get_report_type(report_input)

  const { meta={}, name } = report_input
  const { evaluation_classifier_id } = (meta || {})
  const title = db_meta_report_name || name // save user-entered report name to the FE only

  return create_report_on_choreo(report_input)
    .then(choreo_response => choreo_response.report_id)
    .then(internal_report_id => save_report_created(internal_report_id, title, report_type, null /* external_report_id */, evaluation_classifier_id))
}

export function get_new_report_from_existing_url(external_report_id, report_type, start) {
  let new_url = report_type && is_nd_report_type(report_type) ? BUILD_ND_REPORT : BUILD_REPORT

  if (external_report_id) {
    const new_query = {
      base_report: external_report_id,
      ...(start ? {start} : {})
    }
    return `${new_url}?${qs.stringify(new_query)}`
  }
  return new_url
}

export function new_report_from_existing(external_report_id, history, report_type, start) {
  history.push(get_new_report_from_existing_url(external_report_id, report_type, start) )
}

export function report_can_be_built_from_params(report_type, report_status) {
  if (report_status && report_status === STATUS_QUEUED) {
    return false
  }
  return !_.contains(NO_REBUILD_REPORT_TYPES, report_type)
}

export function get_subpath_for_report_link({is_nd_report, is_valuation_report, evaluation_classifier_id, alert_id, is_comparison_report}) {
  const export_base_subpath = `/${EXPORT_SUBPATH}/`
  if (alert_id) {
    return export_base_subpath + `${ALERT_SETUP_ID}/${alert_id}`
  } else if (is_nd_report) {
    return export_base_subpath + ND_REPORT_ID
  } else if (is_valuation_report) {
    return export_base_subpath + GOOGLE_VALUATION_ID
  } else if (is_comparison_report) {
    return `/g/${PORTFOLIO_SIZE_GROUP_ID}/d/${ALL_FAMILIES_BY_TECH_ID}` // show all families by tech dataset
  }
  return (evaluation_classifier_id != null) ? `/${SELECTED_SUBPATH}` : ''
}

export function new_eval_report(report_input, report_name, evaluation_classifier_id, history) {
  return build_report(report_input, report_name)
    .then(external_report_id => {
      const report_subpath = get_subpath_for_report_link({evaluation_classifier_id})
      return save_eval_report_selected_charts_in_state(external_report_id)
        .then(() => {
          history.push(`${REPORT}/${external_report_id}${report_subpath}`)
        })
    })
}

export function get_classifier_landscape_report_name(selected_classifiers) {
  const suffix = `${selected_classifiers.length > 4 ? ' etc.' : ''} (landscape)`
  const available_length = MAX_REPORT_NAME_LENGTH - suffix.length

  const titles = [...selected_classifiers].slice(0, (selected_classifiers.length < 5) ? selected_classifiers.length : 3).map(item => (item.name)).join(', ')

  function build_report_name_from_titles(titles, available_length) {
    if (titles.length <= available_length) {
      return titles
    }

    const report_name = titles.substr(0, available_length)

    if (titles[available_length] !== ' ' && report_name[available_length - 1] !== ' ') {
      //doing this to remove the last word if it got truncated in the middle by the substr operation
      const chunks = report_name.split(' ')

      chunks.pop()

      return chunks.join(' ')
    }

    return report_name
  }

  return build_report_name_from_titles(titles, available_length) + suffix
}

export function get_report_type_from_input({portfolios, technology_partitioning, selected_landscape_option, selected_new_families_option, is_nd_report}) {
  const {type} = technology_partitioning || {}

  if (is_nd_report) {
    return ND_REPORT_TYPE
  }

  const is_tech_landscape = _.some(portfolios || [], item => item.is_landscape)
  if (is_tech_landscape) {
    if (TECH_PARTITIONING_TYPE_UTT === type) {
      const {id} = selected_landscape_option

      const is_utt_landscape = (id === ALL_FAMILIES_LANDSCAPE_OPTION_ID) && (selected_new_families_option == null)

      return is_utt_landscape ? UTT_LANDSCAPE_REPORT_TYPE : UTT_REPORT_TYPE
    }

    return CLASSIFIER_LANDSCAPE_REPORT_TYPE
  }

  return TECH_PARTITIONING_TYPE_UTT === type ? UTT_REPORT_TYPE : DEFAULT_REPORT_TYPE
}

export function get_default_report_name({portfolios, classifiers, technology_partitioning, selected_landscape_option, selected_new_families_option, is_nd_report }) {
  const report_type = get_report_type_from_input({portfolios, technology_partitioning, selected_landscape_option, selected_new_families_option})

  const portfolio_names = (portfolios || []).map(item => item.name || '')
  const classifier_names = (classifiers || []).map(item => item.name || '')

  if ([CLASSIFIER_LANDSCAPE_REPORT_TYPE, UTT_LANDSCAPE_REPORT_TYPE].indexOf(report_type) !== -1) {
    return get_tech_landscape_report_name({classifier_names, portfolio_names, default_name: 'landscape', suffix: 'landscape'})
  }

  //utt landscapes with options selected are not landscapes
  const is_tech_landscape = _.some(portfolios || [], item => item.is_landscape)
  if (is_tech_landscape && (selected_new_families_option != null)) {
    return get_tech_landscape_report_name({default_name: 'New families in technology space', suffix: 'new families', portfolio_names, classifier_names})
  }

  if (is_tech_landscape && (selected_landscape_option != null)) {
    return get_tech_landscape_report_name({default_name: 'Technology landscape', suffix: 'landscape', portfolio_names, classifier_names})
  }

  if (is_nd_report) {
    const prefix = 'n/d '
    return `${prefix}${pick_report_name(portfolio_names.map(name => ({name})), MAX_REPORT_NAME_LENGTH - prefix.length)}`
  }

  return pick_report_name(portfolios || [])
}


export function get_tech_landscape_report_name({default_name, suffix, portfolio_names, classifier_names}) {
  if ((classifier_names || []).length === 0) return default_name

  const portfolio_names_suffix = (portfolio_names || []).length > 0 ? portfolio_names.join(' '): ''

  const suffix_extended = ` - ${suffix}${portfolio_names_suffix.trim() !== '' ? `, ${portfolio_names_suffix.trim()}` : ''}`

  return `${pick_report_name(classifier_names.map(name => ({name})), MAX_REPORT_NAME_LENGTH - suffix_extended.length)}${suffix_extended}`
}

export function pick_report_name(items=[], max_length=MAX_REPORT_NAME_LENGTH) {
  let report_name = ''

  //this is literally taken from the old portfolio_basket and just tweaked a little to adjust to different portfolio_basket structure
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    let name = is_org_type(item) ? get_short_company_name(item.name) : item.name
    name = remove_last_special_character_from_text(name)
    if (i !== 0) {
      if (report_name.length + name.length + 9 > max_length) {
        return report_name + ' et al.'
      }
      report_name += ', '
    }
    report_name += name
  }
  return report_name
}

export function get_verified_portfolios({portfolios}) {
  const portfolio_org_ids = []
  portfolios.forEach(item => {
    const {id, members=[]} = item

    if (is_organisation(item)) {
      portfolio_org_ids.push(id)
    }

    if (is_org_group_type(item)) {
      members.forEach(group_item => {
        const {id: group_item_id} = group_item

        if (is_organisation(group_item)) {
          portfolio_org_ids.push(group_item_id)
        }})
    }
  })
  
  return check_for_invalid_organisations({org_ids: _.uniq(portfolio_org_ids)})
    .then(invalid_org_ids => {
      const updated_portfolios = []
      const invalid_portfolios = []

      if (invalid_org_ids.length === 0) {
        return { portfolios }
      }

      portfolios.forEach(item => {
        const {id, members=[]} = item

        let is_valid = true


        if (is_organisation(item)) {
          is_valid = invalid_org_ids.indexOf(id) === -1
        }

        if (is_org_group_type(item)) {
          const group_members_org_ids = []

          members.forEach(group_item => {
            const {id: group_item_id} = group_item
            if (is_organisation(group_item)) {
              group_members_org_ids.push(group_item_id)
          }})

          is_valid = _.intersection(group_members_org_ids, invalid_org_ids).length === 0
        }

        return is_valid ? updated_portfolios.push(item) : invalid_portfolios.push(item)
      })

      return { portfolios: updated_portfolios, invalid_portfolios }
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to verify base report portfolios: ')
      throw wrapped_err
    })
}

export function get_portfolio_basket_orgs_total_size(portfolio_basket) {
  let all_organisation_ids = []
  let all_assignee_ids = []

  portfolio_basket.forEach(item => {
    if (!is_org_type(item) && !is_org_group_type(item)) return
    const {organisation_ids=[], assignee_ids=[]} = get_org_item_size_input(item)

    all_organisation_ids = [...all_organisation_ids, ...organisation_ids]
    all_assignee_ids = [...all_assignee_ids, ...assignee_ids]
  })

  return get_organisation_size({organisation_ids: all_organisation_ids, assignee_ids: all_assignee_ids})
}

export function prepare_base_report_portfolios({portfolios}) {
  return get_verified_portfolios({portfolios})
    .then(({ portfolios, invalid_portfolios }) =>{
      const organisations = []
      const keyword_search = []

      portfolios.forEach(item => {
        if (is_org_type(item) || is_org_group_type(item)) {
          organisations.push(item)
        }

        if (is_tech_search_type(item)) {
          keyword_search.push(item.search_term)
        }
      })

      return Promise.all([
        get_portfolio_basket_orgs_total_size(portfolios),
        get_bool_search_items_sizes(keyword_search)
      ])
        .then(([organisations_total_size, keyword_search_items_sizes]) => {
          const portfolio_sizes = (portfolios || []).map(item => {
            const {pat_fam_ids, search_term} = item || {}

            if (is_patent_families_type(item) || is_family_tag_type(item)) return (pat_fam_ids || []).length

            if (is_tech_search_type(item)) {
              return (keyword_search_items_sizes|| {})[search_term] || 0
            }

            return 0 //we only care for keyword search and patent upload sizes (org sizes are accumulated in organisations_total_size)
          })
          return {portfolios, portfolio_sizes, organisations_total_size, invalid_portfolios}
        })
    })
}

export function get_basket_portfolio_total_size(portfolio_basket, portfolio_basket_sizes, portfolio_basket_orgs_total_size=0) {
  if (!portfolio_basket || portfolio_basket.length === 0) {return 0}

  let non_org_items_total_size = 0

  portfolio_basket.forEach((item, i) => {
    if(!is_org_type(item) && !is_org_group_type(item)) {
      non_org_items_total_size = non_org_items_total_size + (portfolio_basket_sizes[i] || 0)
    }
  })

  return non_org_items_total_size + portfolio_basket_orgs_total_size
}

function find_handler_to_get_input_by_type(input_type) {
  switch (input_type) {
    case 'report_stream': return fetch_report_stream_report_input
    default: return fetch_clickthrough_report_input
  }
}

export function fetch_input_by_id({input_id, knn_search_input}) {
  if (knn_search_input != null) {
    return fetch_similar_families_report_input(knn_search_input)
  }

  return axios.get(`${REPORT_INPUT_BASE_URL}/${input_id}/type`)
    .then(response => {
      const {data} = response || {}
      const {input_type} = data || {}
      const handler = find_handler_to_get_input_by_type(input_type)
      return handler(input_id)
    })
}

export function fetch_similar_families_report_input(id) {
  return find_similar_families_by_input_id(id)
    .then(response => {
      const { results, input, settings, name } = response || {}
      const { search_phrase = '' } = settings || {}

      return Promise.all([get_family_ids_filtered(results || [], search_phrase), input, settings, name])
    })
    .then(([pat_fam_ids, input, settings, name]) => {
      const { report_type, evaluation_classifier_id } = settings || {}

      const portfolio_name = name || create_search_name(input)

      return {
        portfolios: [get_patent_upload_as_portfolio_item({
          name: portfolio_name !== '' ? portfolio_name : 'similar families',
          pat_fam_ids: pat_fam_ids,
          group_by_owner: true
        })],
        portfolio_sizes: [pat_fam_ids.length],
        report_type: report_type || DEFAULT_REPORT_TYPE,
        evaluation_classifier_id
      }
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch similar families report input by id ${id}: `)
      throw wrapped_err
    })
}

export function fetch_clickthrough_report_input(input_id) {
  return axios.get(`${SUBSET_REPORT_GET_INPUT_URL}/${input_id}`)
    .then(response => {
      const {data} = response || {}

      const {items=[], technology_partitioning, evaluation_classifier_id} = data || {}
      const portfolios = []
      const portfolio_sizes = []

      items.forEach(item => {
        const {families, name, is_rollup, group_by_owner} = item
        portfolios.push(get_patent_upload_as_portfolio_item({
          name,
          pat_fam_ids: families,
          group_by_owner: (group_by_owner != null) ? group_by_owner : (is_rollup === true)
        }))
        portfolio_sizes.push(families.length)
      })

      return {portfolios, portfolio_sizes, technology_partitioning, evaluation_classifier_id}
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch clickthrough report input by id ${input_id}: `)
      throw wrapped_err
    })
}

export function fetch_report_stream_report_input(input_id) {
  const data = {id: input_id}

  return axios.post(`${REPORT_STREAM_BASE_URL}/get_input`, data)
    .then(response => {
      const { request_data } = response.data

      const {name, portfolios, technology_partitioning } = request_data || {}

      return prepare_base_report_portfolios({portfolios})
        .then(response => {

          return {
            ...response || {},
            report_name: name,
            technology_partitioning
          }
        })
    })

    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch report stream input by id ${input_id}: `)
      throw wrapped_err
    })
}

export function run_report_for_similar_families({report_name, pat_fam_ids, use_utt, utt_version}) {
  const portfolios = [get_patent_upload_as_portfolio_item({
    name: 'similar families',
    pat_fam_ids,
    group_by_owner: true
  })]

  const report_input = build_generic_builder_report_input({
    report_name,
    report_type: use_utt === true ? UTT_REPORT_TYPE : DEFAULT_REPORT_TYPE,
    portfolios,
    portfolios_to_cluster: [0],
    utt_version
  })

  return existing_or_new_report(report_input, report_name, CACHED_REPORT_THRESHOLD_STRICT)
}

export function get_classifier_path(classifier) {
  // find path value (if available) for a user-built or taxonomy classifier
  const {parent, taxonomy_path} = classifier
  return parent || taxonomy_path || []
}

export function build_technology_basket_from_product(classifiers) {
  const classifiers_with_parent_ref = add_parent_refs(classifiers)

  return get_leaf_nodes_as_array(classifiers_with_parent_ref).map(item => {
    const {name, description, classifier_id, version} = item
    const path = get_classifier_path(item)
    return {name, description, classifier_id, version, path}
  })
}
