import { is_tech_discovery } from './utils.js'

export const CIPHER_AWS_HOSTNAME = 'app.aws.cipher.ai'
export const CIPHER31_PREV_HOSTNAME = 'app31.cipher.ai'
export const CIPHER40_PREV_HOSTNAME = 'app4.cipher.ai'
export const CIPHER_5_HOSTNAME = 'app5.cipher.ai'
export const BM_PREV_HOSTNAME = 'bm.cipher.ai'

export const CIPHER_HOSTNAME = 'https://app.cipher.ai'

const CIPHER_OLD_HOSTNAME = 'app3.cipher.ai'

const CIPHER_OTHER_HOSTNAME = 'https://' + CIPHER_OLD_HOSTNAME

export function get_cipher_other_hostname() {
  return CIPHER_OTHER_HOSTNAME
}

export function should_enable_switch() {
  return !is_tech_discovery()
}
