import React from 'react'
import { Container } from 'reactstrap'
import cn from 'classnames'

import s from './ContainerFullWidth.module.scss'

const ContainerFullWidth = ({className, children}) => {
  return (
    <Container fluid className={cn(s.block, className)}>
      {children}
    </Container>
  )
}

export const ContainerFullWidthWithScroll = ({className, children}) => {
  return (
    <ContainerFullWidth  className={cn(s.block__with_scroll, className)}>
      {children}
    </ContainerFullWidth>
  )
}

export default ContainerFullWidth
