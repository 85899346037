import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { DropdownItem, FormGroup, Input, Label } from 'reactstrap'
import qs from 'query-string'
import cn from 'classnames'

import { FEEDBACK_CATEGORIES, FEEDBACK_CATEGORIES_BY_ID } from '../model/feedback.js'
import { FormFeedback } from './widgets/FormFeedback.js'
import Spinner from './widgets/Spinner.js'
import { extract_external_report_id } from '../utils/report_url_utils.js'
import { read_report_user_state_from_window } from '../utils/user_state_utils.js'
import { get_url_with_user_state, send_feedback_email } from '../utils/feedback_utils.js'
import { get_user_idp } from '../utils/user_info.js'
import { BUILD_REPORT } from '../constants/paths.js'
import { PORTFOLIO_SEARCH_TYPE_BY_ID } from '../utils/report_builder_utils.js'
import { track_support_event } from '../utils/tracking_utils.js'
import { PrimaryButton, TertiaryButton } from './widgets/Button.js'
import SupportInfoDisplay from './widgets/SupportInfoDisplay.js'
import { withUser } from './UserContext.js'
import BaseDropdown from './widgets/BaseDropdown.js'

import cs from './cipher_styles.module.scss'
import s from './FeedbackForm.module.scss'

const FeedbackForm = ({ user, location, subject, category, additional_comment, disable_subject_change, on_submit, on_cancel }) => {

  const [feedback_subject, set_feedback_subject] = useState(null)
  const [feedback_category, set_feedback_category] = useState(null)
  const [comment, set_comment] = useState(null)
  const [is_sending, set_is_sending] = useState(false)
  const [feedback_validation_error, set_feedback_validation_error] = useState(false)
  const [send_feedback_error, set_send_feedback_error] = useState(null)
  const [idp_meta, set_idp_meta] = useState([])

  useEffect(() => {
    get_user_idp(user.user_id).then(result => set_idp_meta(result))
  }, [user])

  useEffect(() => {
    let did_cancel = false
    if (!is_sending) return

    const url = window.location.href
    const external_report_id = extract_external_report_id(url)

    const user_state = !external_report_id ? null : read_report_user_state_from_window() // user_state only exists for reports

    get_url_with_user_state({external_report_id, user_state, location, idp_meta})
      .then(response => {
        const { url: user_state_url} = response
        return send_feedback_email({url, category: selected_category, subject: email_subject, comment, additional_comment, user_state_url})
      })
      .then(() => {
        // SUCCESS
        // close the modal
        if (!did_cancel) {
          set_is_sending(false)
          on_submit()
        }
      })
      .catch(err => {
        // ERROR
        // For now, show error inline. User can retry, or close the modal.
        if (!did_cancel) {
          set_is_sending(false)
          set_send_feedback_error(err)
          throw err
        }
      })
    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_sending])

  function get_default_category() {
    const { pathname, search } = location
    const query_params = qs.parse(search)

    if ((pathname ===  BUILD_REPORT) &&
      (!query_params.portfolio_search_mode || query_params.portfolio_search_mode===PORTFOLIO_SEARCH_TYPE_BY_ID.org_search.id)) {
      //the location is  organisation search in report builder
      return FEEDBACK_CATEGORIES_BY_ID.grouping.label
    }

    return FEEDBACK_CATEGORIES_BY_ID.request.label
  }

  function on_change_comment(event) {
    set_comment(event.target.value)
    set_feedback_validation_error(false)
  }

  function on_change_subject(event) {
    set_feedback_subject(event.target.value)
    set_feedback_validation_error(false)
  }

  function on_category_change(category_id) {
    const selected_category = FEEDBACK_CATEGORIES_BY_ID[category_id] || {}
    set_feedback_category(selected_category.label || '')
    set_feedback_validation_error(false)
  }

  function send_feedback() {
    track_support_event('action="submit"')

    if ((email_subject === '') || (comment === '')) {
      set_feedback_validation_error(true)
      return
    }

    set_is_sending(true)
    set_send_feedback_error(null)
  }

  const selected_category = feedback_category || category || get_default_category()
  const email_subject = feedback_subject || subject || ''

  const is_subject_invalid = feedback_validation_error && (email_subject === '')
  const is_comment_invalid = feedback_validation_error && (!comment || comment === '')

  return (
    <div>
      <FormGroup>
        <Label>Category</Label>
        <BaseDropdown
          label={(!selected_category || (selected_category === '')) ? 'Select' : selected_category}
          menuClassName={s.category_menu}
          buttonClassName={s.category_btn}
        >
          {FEEDBACK_CATEGORIES.map(category => {
            const {id, label} = category
            return (
              <DropdownItem key={id} onClick={() => on_category_change(id)} active={selected_category === label}>{label}</DropdownItem>
            )
          })}
        </BaseDropdown>
      </FormGroup>

      <FormGroup>
        <Label>Subject</Label>
        {disable_subject_change &&
          <div>{subject}</div>
        }

        {!disable_subject_change &&
          <Input
            type='text'
            autoFocus
            value={email_subject}
            onChange={on_change_subject}
            placeholder='Please type a short summary here (max 200 chars)'
            maxLength={200}
            invalid={is_subject_invalid}
            autoComplete='off'
          />
        }
        <FormFeedback valid={!is_subject_invalid} validation_text='Subject must not be empty'/>
      </FormGroup>
      <FormGroup>
        <Label>Comment</Label>
        <Input
          type='textarea'
          className={cn('h-100', cs.resize_disabled)}
          value={comment || ''}
          onChange={on_change_comment}
          rows='5'
          placeholder='Please type your comment or request here'
          invalid={is_comment_invalid}
          autoComplete='off'
        />
        <FormFeedback valid={!is_comment_invalid} validation_text='Comment must not be empty'/>
      </FormGroup>
      <div className={cn('d-flex justify-content-between pt-3', s.footer)}>
        <div className='d-flex align-items-center'>
          {is_sending &&
            <>
              <Spinner />
              <span>Sending feedback...</span>
            </>
          }

          {send_feedback_error &&
            <SupportInfoDisplay message='Unfortunately, Classification experienced problems when submitting your feedback.' />
          }
        </div>

        <div className='d-flex justify-content-end'>
          {on_cancel &&
            <TertiaryButton onClick={on_cancel}>Cancel</TertiaryButton>
          }
          <PrimaryButton onClick={send_feedback} className='ms-2'>Send</PrimaryButton>
        </div>
      </div>

    </div>
  )
}

export default withUser(withRouter(FeedbackForm))