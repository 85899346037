import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'
import ReactTable from 'react-table'
import { DropdownItem } from 'reactstrap'

import BaseDropdown from '../widgets/BaseDropdown.js'
import Spinner from '../widgets/Spinner.js'
import TextLink from '../widgets/TextLink.js'
import {
  CONTEXT,
  create_search_name,
  delete_search, mask_knn_id,
  get_recent_and_saved_searches,
  update_similar_families_input_saved_status
} from '../../utils/knn_search.js'
import ErrorBody from '../ErrorBody.js'
import { KNN } from '../../constants/paths.js'
import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { get_as_map, to_local_datetime } from '../../utils/utils.js'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import { DESCENDING } from '../../model/sort_directions.js'
import PinSearchControl from './PinSearchControl.js'
import { PaginationControls } from '../widgets/Pagination.js'
import DeleteSearchControl from './DeleteSearchControl.js'
import { track_knn_events } from '../../utils/tracking_utils.js'

import cs from '../cipher_styles.module.scss'
import s from './UserSearchesTable.module.scss'

const PAGE_SIZES = [5, 10, 20, 50]
const DEFAULT_PAGE_SIZE = 20

const USER_SEARCH_TYPE_ID_ALL = 'all'
const USER_SEARCH_TYPE_ID_PINNED = 'pinned'
const USER_SEARCH_TYPE_ID_UNPINNED = 'unpinned'

const USER_SEARCH_TYPES = [
  {id: USER_SEARCH_TYPE_ID_ALL,      label: 'All searches'},
  {id: USER_SEARCH_TYPE_ID_PINNED,   label: 'Pinned'},
  {id: USER_SEARCH_TYPE_ID_UNPINNED, label: 'Unpinned'},
]

const ID_TO_USER_SEARCH_TYPE = get_as_map(USER_SEARCH_TYPES, 'id')

const UserSearchesTable = (
  {
    history,

    current_id,
    on_update_current_search,
    on_select_search,

    hide_no_results_message,
    listWrapperClassName,
    controlsWrapperClassName,
    tableWrapperClassName
  }) => {

  const [should_fetch, set_should_fetch] = useState(true)
  const [is_fetching, set_is_fetching] = useState(true)

  const [user_searches, set_user_searches] = useState(null)
  const [sort, set_sort] = useState({sort_field_id: 'used_at', sort_direction_id: DESCENDING})
  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(DEFAULT_PAGE_SIZE)
  const [type, set_type] = useState(USER_SEARCH_TYPE_ID_ALL)

  const [fetch_error, set_fetch_error] = useState(null)
  const [update_input_error, set_update_input_error] = useState(null)

  const {sort_field_id, sort_direction_id} = sort || {}

  useEffect(() => {
    set_page(0)
  }, [type])

  useEffect(() => {
    if (!should_fetch) return

    let did_cancel = false

    get_recent_and_saved_searches()
      .then(({recent, saved}) => {
        if (!did_cancel) {

          const saved_search_ids = (saved || []).map(item => item.id)
          const recent_not_saved = saved_search_ids.length === 0 ? (recent || []) : (recent || []).filter(item => saved_search_ids.indexOf(item.id) === -1)

          const searches = _.union(saved || [], recent_not_saved).map(search => {
            const {id, input, name} = search || {}
            const {query_params} = input || {}
            const is_current = id === current_id

            return {
              ...search,

              ...(name || '') !== '' ? {name} : {name: create_search_name(query_params)},
              ...is_current ? {is_current} : {},
            }
          })

          set_user_searches(searches)
          set_should_fetch(false)
          set_is_fetching(false)
        }
      })
      .catch(error => {
        if (!did_cancel) {
          set_fetch_error(error)
          set_should_fetch(false)
          set_is_fetching(false)
        }
      })
    return () => {
      did_cancel = true
    }
  }, [should_fetch, current_id])

  function on_sort_update(sort_field_id, sort_direction_id) {
    set_sort({sort_field_id, sort_direction_id})
  }

  function on_search_select(search) {
    const {id, is_saved} = search || {}

    if (on_select_search) {
      on_select_search()
    }
    track_knn_events(`context="${CONTEXT}" action="knn_search" obj="user_searches" pinned="${is_saved===1}" id="${mask_knn_id(id)}"`)
    history.push(`${KNN}/${id}`)
  }

  function handle_save_flag_change(id, should_save) {
    const handler = id === current_id ? on_update_current_search : update_similar_families_input_saved_status
    return handler(id, should_save)
      .then(() => {
        const updated_searches = user_searches.map(item => {
          const {id: item_id} = item || {}

          return id !== item_id ? item : {...item, is_saved: should_save}
        })

        set_user_searches(updated_searches)
      })
      .catch(error => {
        set_update_input_error(error)
      })
  }

  function handle_delete(id) {

    if (id === current_id)  return

    return delete_search(id)
      .then(() => {
        const updated_searches = user_searches.filter(item => {
          const {id: item_id} = item || {}

          return id !== item_id
        })

        set_user_searches(updated_searches)
      })
      .catch(error => {
        set_update_input_error(error)
      })
  }

  function set_selected_page_size(selected_page_size) {
    set_page_size(selected_page_size)
    set_current_page(0)
  }

  function set_current_page(current_page) {
    set_page(current_page)
  }

  function filter_searches(searches, type) {
    if (type === USER_SEARCH_TYPE_ID_ALL) return searches

    const filtered = searches.filter(item => {
      const {is_saved} = item

      return (type === USER_SEARCH_TYPE_ID_PINNED) ? is_saved : !is_saved
    })

    return filtered
  }

  function sort_searches(searches, sort_field_id, sort_direction_id) {
    const searches_sorted = _.sortBy(searches || [], (search) => {
      const value = search[sort_field_id]

      if (value == null) {
        return ''
      }
      return _.isString(value) ? value.toLowerCase() : value
    })
    return (sort_direction_id === DESCENDING) ? searches_sorted.slice().reverse() : searches_sorted
  }

  function get_paginated_searches(searches, page, page_size) {
    const first_row_idx = page * page_size
    const last_row_idx = ( page * page_size) + page_size

    return (searches || []).slice(first_row_idx, last_row_idx)
  }

  function get_column_header(field) {
    return (
      <SortingColumnHeaderCell
        field={field}
        selected_sort_field_id={sort_field_id}
        selected_sort_direction_id={sort_direction_id}
        on_change_sort_field_id_and_sort_direction_id={on_sort_update}
      />
    )
  }

  const table_columns = [
    {
      ...TABLE_COLUMN_HEADER,
      accessor: 'name',
      Header: () => get_column_header({id: 'name', name: 'Name', sortable: true}),
      headerClassName: 'no-sort-mark',
      resizable: false,
      sortable: false,
      Cell: (row) => {
        const {original} = row || {}
        const {name, input, is_current} = original || {}
        const {query_params} = input || {}

        const name_to_display = name || create_search_name(query_params)

        return (
          <span className='d-flex'>
            <TextLink onClick={() => on_search_select(original)} title={name_to_display} disable={is_current}>{name_to_display}</TextLink>
            {is_current && <span className={cn('ms-2 my-auto', cs.disabled_text)}>(current)</span>}
          </span>
        )
      }
    },
    {
      ...TABLE_COLUMN_HEADER,
      accessor: 'created_at',
      Header: () => get_column_header({id: 'created_at', name: 'Created', sortable: true}),
      width: 120,
      headerClassName: 'no-sort-mark',
      resizable: false,
      sortable: false,
      Cell: (row) => {
        const {value} = row || {}
        return <span>{to_local_datetime(value)}</span>
      }
    },
    {
      ...TABLE_COLUMN_HEADER,
      accessor: 'used_at',
      Header: () => get_column_header({id: 'used_at', name: 'Last used', sortable: true}),
      width: 120,
      headerClassName: 'no-sort-mark',
      resizable: false,
      sortable: false,
      Cell: (row) => {
        const {value} = row || {}
        return <span>{to_local_datetime(value)}</span>
      }
    },
    {
      ...TABLE_COLUMN_HEADER,
      accessor: 'id',
      Header: () => get_column_header({id: 'id', name: 'Actions', sortable: false}),
      width: 80,
      headerClassName: 'no-sort-mark',
      resizable: false,
      sortable: false,
      Cell: (row) => {
        const {original} = row || {}
        const {id, is_saved, is_current} = original || {}

        return (
          <div className='d-flex justify-content-center'>
            <PinSearchControl
              is_saved={is_saved}
              on_change={() => handle_save_flag_change(id, !is_saved)}
              className='me-2'
            />

            <DeleteSearchControl
              handle_delete={() => handle_delete(id)}
              is_disabled={is_current}
            />
          </div>
        )
      }
    }
  ]

  const filtered_searches = filter_searches(user_searches, type)
  const sorted_searches = sort_searches(filtered_searches, sort_field_id, sort_direction_id)
  const searches_to_display = get_paginated_searches(sorted_searches, page, page_size)

  const total_data_rows = (filtered_searches || []).length
  const num_pages = Math.ceil(total_data_rows / page_size)

  return (
    <>

      {is_fetching &&
        <div>
          <Spinner />
        </div>
      }

      {!is_fetching && fetch_error &&
        <ErrorBody
          error={fetch_error}
          content='fetching recent and pinned searches'
        />
      }

      {!is_fetching && (user_searches || []).length > 0 &&
        <div className={cn(listWrapperClassName)}>
          <div className={cn('d-sm-flex justify-content-between', controlsWrapperClassName)}>

            <BaseDropdown label={(ID_TO_USER_SEARCH_TYPE[type] || {}).label || ''}>
              {USER_SEARCH_TYPES.map((item, i) => {
                const {id, label} = item

                return (
                  <DropdownItem key={i} onClick={() => set_type(id)} active={id === type}>{label}</DropdownItem>
                )
              })}
            </BaseDropdown>

            <PaginationControls
              num_pages={num_pages}
              selected_page_size={page_size}
              on_change_page_size={set_selected_page_size}
              current_page={page}
              on_change_current_page={set_current_page}
              available_page_sizes={PAGE_SIZES}
              className='mt-2 mt-sm-0'
            />
          </div>
          <div className={cn('w-100', s.table_wrapper, tableWrapperClassName)}>
            <ReactTable
              className='border-0 -striped'
              manual={true}
              showPagination={false}
              filterable={false}
              sortable={false}

              columns={table_columns}
              data={searches_to_display}

              minRows={3}
              resizable={false}

              defaultSortDesc={true}
            />
          </div>
        </div>
      }

      {!is_fetching && (user_searches || []).length === 0 && !hide_no_results_message &&
        <div>No searches to display</div>
      }

      {update_input_error &&
        <ErrorBody
          error={update_input_error}
          context='updating search params'
        />
      }

    </>

  )
}

export default withRouter(UserSearchesTable)