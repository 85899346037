import {
  CIPHER_FAMILY_ID_FIELD_ID, CPC_CODES_FIELD_ID,
  EXPIRED_TERRITORIES_FIELD_ID,
  GRANTED_DATE_FIELD_ID,
  GRANTED_TERRITORIES_FIELD_ID,
  OWNERS_FIELD_ID,
  PENDING_TERRITORIES_FIELD_ID,
  PRIMARY_CPC_CODE_FIELD_ID,
  PRIORITY_DATE_FIELD_ID,
  PUBLICATION_DATE_FIELD_ID,
  SIMILARITY_SCORE_ID,
  STATUS_FIELD_ID,
  TITLE_FIELD_ID
} from '../../../model/patent_family_fields.js'
import { get_as_map } from '../../../utils/utils.js'
import {
  EXTRA_LARGE,
  EXTRA_SMALL, FOUR_EXTRA_LARGE,
  LARGE,
  MEDIUM,
  SMALL,
  THREE_EXTRA_LARGE,
  TWO_EXTRA_LARGE
} from '../../../constants/screen_breakpoints.js'

export const ACTIONS_FIELD_ID = 'actions'
export const INPUT_STATUS_FIELD_ID = 'input_status'

const COLUMN_PROPS = {
  id: null,
  field: null,
  label: '',
  sortable: true,
  width: null,
  render_cell: ({row, field}) => ((row || {})[field] || ''),
  render_header: ({column}) => (column.label || ''),
  min_screen_breakpoint: null,
}

export const COLUMNS = [
  {
    ...COLUMN_PROPS,
    id: INPUT_STATUS_FIELD_ID,
    field: null,
    sortable: false,
    width: 4,
  },

  {
    ...COLUMN_PROPS,
    id: CIPHER_FAMILY_ID_FIELD_ID,
    field: CIPHER_FAMILY_ID_FIELD_ID,
    label: 'Family ID',
    width: 180,
  },

  {
    ...COLUMN_PROPS,
    id: OWNERS_FIELD_ID,
    field: OWNERS_FIELD_ID,
    label: 'Owners',
    width: 140,
    min_screen_breakpoint: SMALL
  },

  {
    ...COLUMN_PROPS,
    id: PRIMARY_CPC_CODE_FIELD_ID,
    field: PRIMARY_CPC_CODE_FIELD_ID,
    label: 'Primary CPC',
    width: 120,
    min_screen_breakpoint: TWO_EXTRA_LARGE
  },

  {
    ...COLUMN_PROPS,
    id: CPC_CODES_FIELD_ID,
    field: CPC_CODES_FIELD_ID,
    label: 'CPCs',
    width: 120,
    sortable: false,
    min_screen_breakpoint: THREE_EXTRA_LARGE
  },

  {...COLUMN_PROPS,
    id: STATUS_FIELD_ID,
    field: STATUS_FIELD_ID,
    label: 'Status',
    width: 90,
    min_screen_breakpoint: MEDIUM
  },

  {
    ...COLUMN_PROPS,
    id: TITLE_FIELD_ID,
    field: TITLE_FIELD_ID,
    label: 'Title',
    min_screen_breakpoint: LARGE
  },

  {
    ...COLUMN_PROPS,
    id: PRIORITY_DATE_FIELD_ID,
    field: PRIORITY_DATE_FIELD_ID,
    label: 'Priority',
    width: 120,
    min_screen_breakpoint: LARGE
  },

  {
    ...COLUMN_PROPS,
    id: GRANTED_DATE_FIELD_ID,
    field: GRANTED_DATE_FIELD_ID,
    label: 'Grant',
    width: 120,
    min_screen_breakpoint: EXTRA_LARGE
  },

  {
    ...COLUMN_PROPS,
    id: PUBLICATION_DATE_FIELD_ID,
    field: PUBLICATION_DATE_FIELD_ID,
    label: 'Publication',
    width: 120,
    min_screen_breakpoint: THREE_EXTRA_LARGE
  },

  {
    ...COLUMN_PROPS,
    id: PENDING_TERRITORIES_FIELD_ID,
    field: PENDING_TERRITORIES_FIELD_ID,
    label: 'Pending territories',
    width: 100,
    sortable: false,
    min_screen_breakpoint: TWO_EXTRA_LARGE
  },

  {
    ...COLUMN_PROPS,
    id: GRANTED_TERRITORIES_FIELD_ID,
    field: GRANTED_TERRITORIES_FIELD_ID,
    label: 'Granted territories',
    width: 100,
    sortable: false,
    min_screen_breakpoint: TWO_EXTRA_LARGE
  },

  {
    ...COLUMN_PROPS,
    id: EXPIRED_TERRITORIES_FIELD_ID,
    field: EXPIRED_TERRITORIES_FIELD_ID,
    label: 'Expired territories',
    width: 100,
    sortable: false,
    min_screen_breakpoint: FOUR_EXTRA_LARGE
  },

  {
    ...COLUMN_PROPS,
    id: SIMILARITY_SCORE_ID,
    field: SIMILARITY_SCORE_ID,
    label: 'Score',
    width: 60,
    min_screen_breakpoint: EXTRA_SMALL
  },

  {
    ...COLUMN_PROPS,
    id: ACTIONS_FIELD_ID,
    field: null,
    label: 'Actions',
    sortable: false,
    width: 80,
  }

]

export const ID_TO_COLUMN = get_as_map(COLUMNS, 'id')

export const RESULTS_COLUMN_IDS = [
  INPUT_STATUS_FIELD_ID,
  CIPHER_FAMILY_ID_FIELD_ID,
  OWNERS_FIELD_ID,
  PRIMARY_CPC_CODE_FIELD_ID,
  CPC_CODES_FIELD_ID,
  STATUS_FIELD_ID,
  TITLE_FIELD_ID,
  PRIORITY_DATE_FIELD_ID,
  GRANTED_DATE_FIELD_ID,
  PUBLICATION_DATE_FIELD_ID,
  PENDING_TERRITORIES_FIELD_ID,
  GRANTED_TERRITORIES_FIELD_ID,
  EXPIRED_TERRITORIES_FIELD_ID,
  SIMILARITY_SCORE_ID,
  ACTIONS_FIELD_ID
]

export const INPUT_COLUMN_IDS = RESULTS_COLUMN_IDS.filter(id => id !== SIMILARITY_SCORE_ID)