import {
  ALERT_PARAM_PUB_TYPE,
  ALERT_FILTER_TYPE_PVIX_THRESHOLD,
  ALERT_FILTER_TYPE_STATUS,
  ALERT_FREQUENCY_ID_BIWEEKLY,
  ALERT_FREQUENCY_ID_MONTHLY,
  ALERT_FREQUENCY_ID_QUARTERLY,
  ALERT_FREQUENCY_ID_WEEKLY,
  ALERT_PUBLICATION_TYPE_APPLICATION,
  ALERT_PUBLICATION_TYPE_GRANT,
  ALERT_TYPE_ID_NEW_FAMILIES,
  ALERT_TYPE_ID_NEW_FILINGS,
  ALERT_TYPE_ID_PVIX_INCREASE,
  ALERT_TYPE_ID_STATUS_CHANGE,
  ALERT_PARAM_NEW_FAMS,
  FIELD_ID_ALERT_NAME,
  FIELD_ID_CONFIGURE,
  FIELD_ID_ALERT_TYPE,
  FIELD_ID_ALERT_FREQUENCY_ID,
  FIELD_ID_ORIGINAL_REPORT,
  FIELD_ID_LAST_ALERT,
  FIELD_ID_HISTORY,
  FIELD_ID_NEXT_ALERT,
  FIELD_ID_SKIP_EMPTY_ALERTS,
  FIELD_ID_REMOVE_ALERT
} from '../constants/alert_reports.js'
import { STATUS_EXPIRED, STATUS_GRANTED, STATUS_PENDING } from './statuses.js'
import { get_as_map } from '../utils/utils.js'

export const ALERT_REPORT_TYPES = [
  { id: ALERT_TYPE_ID_NEW_FAMILIES, name: 'New patent families'},
  { id: ALERT_TYPE_ID_NEW_FILINGS, name: 'New patent filings'},
  { id: ALERT_TYPE_ID_STATUS_CHANGE, name: 'Family status'},
  { id: ALERT_TYPE_ID_PVIX_INCREASE, name: 'PVIX increases', description: 'Alert on families whose PVIX score has increased above a particular threshold for the first time during the alert reporting period.'},
]
export const ALERT_REPORT_TYPE_NAME_DELTA = 'Delta'

export const ALERT_REPORT_TYPES_BY_ID = get_as_map(ALERT_REPORT_TYPES, 'id')

export const PUBLICATION_TYPES = [
  { id: ALERT_PUBLICATION_TYPE_APPLICATION, name: 'Application', label: 'New applications'},
  { id: ALERT_PUBLICATION_TYPE_GRANT, name: 'Grant', label: 'New grants' }
]

export const DEFAULT_SELECTED_PUBLICATION_TYPES = PUBLICATION_TYPES.map(pub_type => pub_type.id)

export const ALERT_FAMILY_STATUSES = [
  STATUS_PENDING,
  STATUS_GRANTED,
  STATUS_EXPIRED
].map(status => ({...status, label: `Newly ${status.name.toLowerCase()}`}))

export const DEFAULT_SELECTED_STATUSES = [STATUS_PENDING.id, STATUS_GRANTED.id]
export const ALL_ALERT_STATUSES = ALERT_FAMILY_STATUSES.map(status => status.id)

export const DEFAULT_ALERT_FILTERS = {
  [ALERT_FILTER_TYPE_STATUS]: DEFAULT_SELECTED_STATUSES,
  [ALERT_FILTER_TYPE_PVIX_THRESHOLD]: 50,
  [ALERT_PARAM_PUB_TYPE]: DEFAULT_SELECTED_PUBLICATION_TYPES,
  [ALERT_PARAM_NEW_FAMS]: true
}

export const ALERT_FREQUENCIES = [
  { id: ALERT_FREQUENCY_ID_WEEKLY, name: 'Weekly'},
  { id: ALERT_FREQUENCY_ID_BIWEEKLY, name: 'Biweekly'},
  { id: ALERT_FREQUENCY_ID_MONTHLY, name: 'Monthly'},
  { id: ALERT_FREQUENCY_ID_QUARTERLY, name: 'Quarterly'}
]

export const ALERT_FREQUENCIES_BY_ID = get_as_map(ALERT_FREQUENCIES, 'id')

export const FIELD_ALERT_NAME = {id: FIELD_ID_ALERT_NAME, accessor: FIELD_ID_ALERT_NAME, name: 'Alert name', sortable: true}
export const FIELD_CONFIGURE = {id: FIELD_ID_CONFIGURE, accessor: FIELD_ID_CONFIGURE, name: 'Configure', sortable: false}
export const FIELD_ALERT_TYPE = {id: FIELD_ID_ALERT_TYPE, accessor: FIELD_ID_ALERT_TYPE, name: 'Alert type', sortable: true}
export const FIELD_ALERT_FREQUENCY = {id: FIELD_ID_ALERT_FREQUENCY_ID, accessor: FIELD_ID_ALERT_FREQUENCY_ID, name: 'Frequency', sortable: true}
export const FIELD_ORIGINAL_REPORT = {id: FIELD_ID_ORIGINAL_REPORT, accessor: FIELD_ID_ORIGINAL_REPORT, name: 'Original report', sortable: true}
export const FIELD_LAST_ALERT = {id: FIELD_ID_LAST_ALERT, accessor: FIELD_ID_LAST_ALERT, name: 'Latest alert', sortable: true}
export const FIELD_HISTORY = {id: FIELD_ID_HISTORY, accessor: FIELD_ID_HISTORY, name: 'Reports', sortable: false}
export const FIELD_NEXT_ALERT = {id: FIELD_ID_NEXT_ALERT, accessor: FIELD_ID_NEXT_ALERT, name: 'Next alert', sortable: true}
export const FIELD_SKIP_EMPTY_ALERTS = {id: FIELD_ID_SKIP_EMPTY_ALERTS, accessor: FIELD_ID_SKIP_EMPTY_ALERTS, name: 'Empty Alerts', sortable: false}
export const FIELD_REMOVE_ALERT = {id: FIELD_ID_REMOVE_ALERT, accessor: FIELD_ID_REMOVE_ALERT, name: 'Unsubscribe', sortable: false}