import React from 'react'

import PopoverAsDropdown, { PopoverAsDropdownItem } from '../../widgets/PopoverAsDropdown.js'

export const SelectablePublication = ({options, selected, change_selection, ignore, row_number}) => {

  const valid_choice_idx = (selected === -1) ? 0 : selected
  const label = (options && options.length > 0) ? options[valid_choice_idx].serial : 'No options'

  return (
    <PopoverAsDropdown
      label={label}

      toggleClassName='fs-mask ms-1'
      popupClassName='fs-mask'
      disabled={ignore}
    >
      {options.map((item, i) => {
        return (
          <PopoverAsDropdownItem key={row_number + '_' + i} is_active={i === valid_choice_idx} on_click={() => change_selection(row_number, i)}>
            {item.serial}
          </PopoverAsDropdownItem>
        )
      })}

    </PopoverAsDropdown>
  )
}