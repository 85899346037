import React, { useState } from 'react'
import cn from 'classnames'

import { InfoPopover } from './Tooltip.js'
import DropdownChevron from './DropdownChevron.js'

import s from './PopoverAsDropdown.module.scss'

const PopoverAsDropdown = (
  {
    label,
    className,
    toggleClassName,
    popupClassName,
    disabled,
    children,
  }) => {

  const [is_open, set_is_open] = useState(false)

  return (
    <InfoPopover
      interactive={true}
      arrow={false}
      placement='bottom-start'
      is_in_modal={true}
      wrapper={document.body}
      disabled={disabled}
      toggler={(
        <span className={cn('d-flex', s.menu_toggle, toggleClassName)}>
          <span className='my-auto'>{label || 'None'}</span>
          <DropdownChevron is_down_arrow={!is_open}/>
        </span>
      )}

      className={cn(s.filter_menu, className)}
      popupClassName={cn( s.menu, popupClassName)}

      on_show={() => set_is_open(true)}
      on_hide={() => set_is_open(false)}
    >
      {children}
    </InfoPopover>
  )
}

export const PopoverAsDropdownItem = ({is_active, on_click, className, children}) => {
  return (
    <div className={cn('dropdown-item', {'active': is_active}, className)} onClick={on_click}>
      {children}
    </div>
  )

}

export default PopoverAsDropdown