import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import qs from 'query-string'
import cn from 'classnames'

import {
  IS_SELECTED_FIELD_ID,
  NAME_FIELD_ID,
  OWNER_FIELD_ID,
  CREATED_AT_FIELD_ID,
  LAST_VIEWED_FIELD_ID,
  STATUS_FIELD_ID,
  ID_TO_REPORT_FIELD,
  ACTIONS_FIELD_ID
} from '../model/report_fields.js'
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../model/page_sizes.js'
import { ID_TO_REPORT_FILTER, NO_FILTER, PROJECTS_FILTER } from '../model/filters.js'
import { ACTION_DELETE_ID, ACTION_UNSAVE_ID } from '../model/multi_report_actions.js'
import { REPORT } from '../../../constants/paths.js'

import { is_creator, is_admin, is_aistemos, has_classifiers_edit } from '../../../utils/user_permissions.js'
import { is_failed_status } from '../../../utils/report_progress_utils.js'
import {
  fetch_eval_classifier_names,
  fetch_report_history,
  fetch_report_owners, REPORT_SEARCH_PARAM_NAME,
  update_reports_with_latest_status
} from '../../../utils/report_history_utils.js'
import { delete_reports_viewed, update_report_title } from '../../../utils/report_created_utils.js'
import { fetch_all_tags, update_reports_saved, update_saved_reports_with_tag, create_new_tag, update_tag_name, delete_tag, report_has_tag } from '../../../utils/report_management_utils.js'
import {
  get_new_report_from_existing_url,
  report_can_be_built_from_params,
  rerun_report_and_replace_id
} from '../../../utils/report_builder_utils.js'
import {
  filter_reports,
  sort_reports,
  get_new_selected_external_report_ids,
  report_can_be_saved,
  alphabetise_tags,
  get_searchable_tag_name,
  get_filter_params_from_url,
  update_query_params_for_filtering,
  get_new_selected_project_ids, is_all_reports_selected
} from '../utils/sort_and_filter_utils.js'
import { get_as_map, is_array_non_empty_non_null, pluralise_text } from '../../../utils/utils.js'
import { track_visit_event, track_report_viewer_event, track_report_builder_event, track_report_management_event } from '../../../utils/tracking_utils.js'
import { is_not_found } from '../../../utils/axios_utils.js'
import { send_error_to_sentry } from '../../../utils/sentry_utils.js'
import { useInterval } from '../../../hooks/general_hooks.js'

import { ContainerFullWidthWithScroll } from '../../ContainerFullWidth.js'
import ErrorModal from '../../ErrorModal.js'
import ConfirmModal from '../../ConfirmModal.js'
import PageSizeControl from '../../PageSizeControl.js'
import PageControl from '../../PageControl.js'
import { withUser } from '../../UserContext.js'

import TextLink from '../../widgets/TextLink.js'
import {
  NewReportFromParamsIcon,
  SaveIcon,
  UnsaveIcon,
  TrashIcon,
  ProjectIcon,
  EyeBlockedIcon,
  TagsIcon
} from '../../widgets/IconSet.js'
import ClearableSearchInput from '../../widgets/ClearableSearchInput.js'
import ProjectNameField from './ProjectNameField'

import ReportManagementTable from './ReportManagementTable.js'
import ManageSelectedReportsControl from './ManageSelectedReportsControl.js'

import TagsFilter from './TagsFilter.js'
import { CreateNewTagModal } from './CreateNewTagModal.js'
import TagManagementModal from './TagManagementModal.js'
import ReportNameField from './ReportNameField.js'
import { ReportActionConfirmModal } from './ReportActionConfirmModal.js'
import { normalise_search_phrase, update_url_with_search_phrase } from '../../../utils/url_utils.js'
import {
  add_project_reports,
  create_project,
  delete_project,
  get_project_reports,
  get_user_project_history,
  update_project_name,
  update_project_permissions
} from '../../../utils/project_and_versioning_utils'
import AddToProjectModal from './AddToProjectModal'
import CreateProjectModal from './CreateProjectModal'
import { DownloadAllProjectFiles } from '../../project_management/ProjectControls.js'
import { get_from_local_storage, save_to_local_storage } from '../../../utils/local_storage_utils.js'

import s from './Reports.module.scss'
import cs from '../../cipher_styles.module.scss'

const ACTION_FETCHING_HISTORY = 'fetching history'
const TRACKING_CONTEXT = 'report_history'
const FETCH_INTERVAL_TIME = 30 * 1000

const FIELDS = [
  IS_SELECTED_FIELD_ID,
  NAME_FIELD_ID,
  OWNER_FIELD_ID,
  CREATED_AT_FIELD_ID,
  LAST_VIEWED_FIELD_ID,
  STATUS_FIELD_ID,
  ACTIONS_FIELD_ID
].map(field_id => ID_TO_REPORT_FIELD[field_id])

const REPORT_HISTORY_TABLE_SORT_LS_KEY = 'report_history_sort'

const Reports = ({ history, location, user }) => {

  const query_params = qs.parse(location.search)

  const {tag_id_from_url, filter_id_from_url} = get_filter_params_from_url()

  const {sort_field_id: ls_sort_field_id, sort_direction_id: ls_sort_direction_id} = get_from_local_storage(REPORT_HISTORY_TABLE_SORT_LS_KEY) || {}

  const [reports, set_reports] = useState([])
  const [projects, set_projects] = useState([])
  const [data_rows, set_data_rows] = useState([])
  const [tags, set_tags] = useState([])
  const [is_fetching, set_is_fetching] = useState(true)
  const [polling_time, set_polling_time] = useState(FETCH_INTERVAL_TIME)

  const has_projects = is_aistemos(user) || projects.length > 0
  const can_evaluate_classifiers = has_classifiers_edit(user)

  const [report_owners, set_report_owners] = useState({})
  const [is_fetching_owners, set_is_fetching_owners] = useState(false)
  const [eval_classifier_names, set_eval_classifier_names] = useState({})
  const [is_fetching_classifier_names, set_is_fetching_classifier_names] = useState(false)

  const [error_on_report_action, set_error_on_report_action] = useState(null)
  const [error_in_tag_modal, set_error_in_tag_modal] = useState(null)

  const [sort_field_id, set_sort_field_id]         = useState(ls_sort_field_id)
  const [sort_direction_id, set_sort_direction_id] = useState(ls_sort_direction_id)
  const [page_size, set_page_size]                 = useState(DEFAULT_PAGE_SIZE)
  const [page_number, set_page_number]             = useState(0)

  const [report_search_input, set_report_search_input] = useState(decodeURIComponent(query_params[REPORT_SEARCH_PARAM_NAME] || ''))
  const [tag_search_input, set_tag_search_input] = useState('')

  const can_use_filter_from_url_param = ID_TO_REPORT_FILTER[filter_id_from_url || NO_FILTER].can_show({has_projects, can_evaluate_classifiers})
  const [report_filter_id, set_report_filter_id] = useState(can_use_filter_from_url_param ? filter_id_from_url : NO_FILTER)
  const [tag_id_to_filter_by, set_tag_id_to_filter_by] = useState(tag_id_from_url || null)

  const [is_managing_tags, set_is_managing_tags] = useState(false)
  const [dropdown_is_showing_selected_tags, set_dropdown_is_showing_selected_tags] = useState(false)

  const [reports_to_delete_external_ids, set_reports_to_delete_external_ids] = useState([])
  const [reports_to_unsave_external_ids, set_reports_to_unsave_external_ids] = useState([])
  const [project_to_delete_id, set_project_to_delete_id] = useState(null)

  const [selected_reports_external_ids, set_selected_reports_external_ids] = useState([])
  const [report_to_rename_external_id, set_report_to_rename_external_id] = useState(null)
  const [report_being_tagged_external_id, set_report_being_tagged_external_id] = useState(null)
  const [selected_project_ids, set_selected_project_ids] = useState([])
  const [project_to_rename_id, set_project_to_rename_id] = useState(null)
  const [project_to_hide_id, set_project_to_hide_id] = useState(null)

  const [is_editing_tags_for_selected_reports, set_is_editing_tags_for_selected_reports] = useState(false)
  const [is_show_create_tag_modal, set_is_show_create_tag_modal] = useState(false)
  const [is_creating_tag, set_is_creating_tag] = useState(false)
  const [tag_to_delete, set_tag_to_delete] = useState(null)

  const [is_show_add_to_project_modal, set_is_show_add_to_project_modal] = useState(false)
  const [is_adding_reports_to_project, set_is_adding_reports_to_project] = useState(false)
  const [is_show_create_project_modal, set_is_show_create_project_modal] = useState(false)
  const [is_creating_project, set_is_creating_project] = useState(false)

  /* Data loading and react hooks */
  function fetch_reports_and_tags() {
    return fetch_report_history(null, false, true, false)
      .then(reports => {
        return Promise.all([
          update_reports_with_latest_status(reports),
          fetch_all_tags()
        ])
      })
      .then(([reports, tags]) => {
        const tag_id_to_tag = get_as_map(tags, 'tag_id')

        const reports_with_tags = reports.map(r => {
          const tags = alphabetise_tags(r.tag_ids.map(tag_id => tag_id_to_tag[tag_id]))
          // to make searching by tag a bit quicker
          const tag_names = tags.map(get_searchable_tag_name)
          return { ...r, tags, tag_names }
        })

        return [reports_with_tags, tags]
      })
  }

  function load_table_data() {
    if (!is_fetching_owners) {
      set_is_fetching_owners(true)
      fetch_report_owners()
        .then(report_id_to_owner => set_report_owners(report_id_to_owner))
        .catch(error => {
          // owner names are non-essential metadata; don't stop the user from getting on with things
          send_error_to_sentry(error, {})
        })
        .finally(() => set_is_fetching_owners(false))
    }
    if (!is_fetching_classifier_names) {
      set_is_fetching_classifier_names(true)
      fetch_eval_classifier_names()
        .then(classifier_id_to_name => set_eval_classifier_names(classifier_id_to_name))
        .catch(error => {
          // classifier names are non-essential metadata; don't stop the user from getting on with things
          send_error_to_sentry(error, {})
        })
        .finally(() => set_is_fetching_classifier_names(false))
    }
    return Promise.all([fetch_reports_and_tags(), get_user_project_history()])
      .then(([[fetched_reports, fetched_tags], fetched_projects]) => {
        set_is_fetching(false)
        set_tags(fetched_tags)
        set_reports(fetched_reports)
        set_projects(fetched_projects)

        clean_up_selected()
      })
      .catch(error => {
        set_polling_time(null) // stop polling -- something seems to be wrong
        set_is_fetching(false)
        show_error_message_and_rethrow(error, ACTION_FETCHING_HISTORY)
      })
  }

  useEffect(() => {
    document.title = 'Cipher: Report History'
    track_visit_event(`page="${TRACKING_CONTEXT}"`)
    // do initial fetch
    load_table_data()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useInterval(load_table_data, polling_time, false)

  useEffect(() => {
    // reset page number when paging or filtering parameters are updated
    set_page_number(0)
  }, [report_search_input, tag_id_to_filter_by, report_filter_id, page_size, sort_direction_id, sort_field_id])
  
  useEffect(() => {
    set_data_rows(projects.concat(reports))
  }, [projects, reports])

  /* navigation, filtering and selections */
  const external_report_id_to_report = get_as_map(reports, 'external_report_id')
  const project_id_to_project = get_as_map(projects, 'project_id')

  const selected_reports = selected_reports_external_ids.map(id => external_report_id_to_report[id])
  const selected_and_saveable_reports = selected_reports.filter(report => report_can_be_saved(report))
  const selected_and_saveable_reports_external_ids = selected_and_saveable_reports.map(report => report.external_report_id)

  const data_rows_filtered = filter_reports(data_rows, report_owners, normalise_search_phrase(report_search_input), report_filter_id, tag_id_to_filter_by, eval_classifier_names)
  const data_rows_sorted = sort_reports(data_rows_filtered, report_owners, sort_field_id, sort_direction_id)

  const has_data_rows = data_rows_filtered && data_rows_filtered.length > 0
  const has_report_rows = data_rows_filtered && _.filter(data_rows_filtered, r => r.external_report_id).length > 0

  const is_select_all_reports = (report_filter_id !== PROJECTS_FILTER) && has_report_rows && is_all_reports_selected(data_rows_filtered, selected_reports_external_ids)

  const start_index = page_number * page_size
  const page_reports = !has_data_rows ? [] : data_rows_sorted.slice(start_index, start_index + page_size)
  const page_reports_with_owner_names = page_reports.map(r => ({...r, owner_name: report_owners[r.external_report_id]}))

  const num_pages = !has_data_rows ? 0 : Math.ceil(data_rows_filtered.length / page_size)

  const tag_modal_is_open = is_managing_tags || is_show_create_tag_modal

  function pause_or_resume_polling(is_pause) {
    // while showing a modal we often want to pause polling, to avoid fresh changes being overwritten by stale fetched data
    set_polling_time(is_pause ? null : FETCH_INTERVAL_TIME)
  }

  function clean_up_selected() {
    // ensure 'selected ids' lists don't contain any old ids (eg for reports that have recently been deleted)
    const selected_reports_external_ids_updated = selected_reports_external_ids.filter(report_id => external_report_id_to_report[report_id])
    set_selected_reports_external_ids(selected_reports_external_ids_updated)

    const selected_project_ids_updated = selected_project_ids.filter(project_id => project_id_to_project[project_id])
    set_selected_project_ids(selected_project_ids_updated)
  }

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    set_sort_field_id(sort_field_id)
    set_sort_direction_id(sort_direction_id)

    save_to_local_storage(REPORT_HISTORY_TABLE_SORT_LS_KEY, {sort_field_id, sort_direction_id})
  }

  function toggle_selected(data_rows_to_toggle, is_selected, reports_only = false) {
    const reports_to_toggle = _.filter(data_rows_to_toggle, row => row.external_report_id)
    const projects_to_toggle = _.filter(data_rows_to_toggle, row => row.project_id)

    track_report_management_event(`obj="${pluralise_text(reports_to_toggle.length, 'report')}" action="${is_selected ? 'select': 'deselect'}" context="${TRACKING_CONTEXT}"`)
    const reports_to_toggle_external_ids = reports_to_toggle.map(report => report.external_report_id)

    const selected_reports_external_ids_updated = get_new_selected_external_report_ids(reports_to_toggle_external_ids, is_selected, selected_reports_external_ids)
    set_selected_reports_external_ids(selected_reports_external_ids_updated)

    if (!reports_only) {
      track_report_management_event(`obj="${pluralise_text(projects_to_toggle.length, 'project')}" action="${is_selected ? 'select': 'deselect'}" context="${TRACKING_CONTEXT}"`)
      const projects_to_toggle_ids = projects_to_toggle.map(project => project.project_id)
      set_selected_project_ids(get_new_selected_project_ids(projects_to_toggle_ids, is_selected, selected_project_ids))
    }
  }

  function handle_update_report_filters(tag_id, report_filter_id) {
    track_report_management_event(`obj="reports" action="filter_by_${tag_id ? 'tag' : 'status'}" context="${TRACKING_CONTEXT}"`)

    const new_query = update_query_params_for_filtering(tag_id, report_filter_id)
    history.replace({pathname: window.location.pathname, search: `?${qs.stringify(new_query)}`})

    set_selected_reports_external_ids([])
    set_tag_id_to_filter_by(tag_id)
    set_report_filter_id(report_filter_id)
  }

  /* saving  */

  function save_or_unsave_selected(is_save) {
    if (is_save) {
      return on_toggle_save_reports(selected_and_saveable_reports_external_ids, is_save)
    }
    show_reports_to_unsave_modal(selected_and_saveable_reports_external_ids)
  }

  function show_tag_management_modal(is_show) {
    pause_or_resume_polling(is_show)
    set_error_in_tag_modal(null)  // reset in-modal error
    set_is_managing_tags(is_show)
  }

  function show_create_tag_modal(is_show) {
    pause_or_resume_polling(is_show)
    if (!is_show) {
      // reset in-modal error
      set_error_in_tag_modal(null)
      set_is_creating_tag(false)
    }
    set_is_show_create_tag_modal(is_show)
  }

  function on_toggle_save_reports(reports_to_toggle_external_ids, is_save) {
    track_report_viewer_event(`obj="${pluralise_text(reports_to_toggle_external_ids.length, 'report')}" action="${is_save ? 'save' : 'unsave'}" context="${TRACKING_CONTEXT}"`)
    show_reports_to_unsave_modal(null)

    const reports_before_update = reports
    const tags_before_update = tags

    if (!is_save) {
      const tags_updated = tags.map(tag => {
        const tagged_reports = reports.filter(r => _.contains(reports_to_toggle_external_ids, r.external_report_id) && report_has_tag(r, tag))
        return {...tag, report_count: tag.report_count - tagged_reports.length}
      })
      set_tags(tags_updated)
    }

    const reports_updated = reports.map(r => {
      if (!_.contains(reports_to_toggle_external_ids, r.external_report_id)) {
        return r
      }
      return is_save ? {...r, is_saved: true} : {...r, is_saved: false, tags: [], tag_ids: []}
    })
    set_reports(reports_updated)

    update_reports_saved(reports_to_toggle_external_ids, is_save)
      .catch(error => {
        set_reports(reports_before_update)
        set_tags(tags_before_update)
        show_error_message_and_rethrow(error, 'updating saved status')
      })
  }

  /* tagging */

  function update_id_of_report_being_tagged(report) {
    if (report_being_tagged_external_id === report.external_report_id) {
      pause_or_resume_polling(false)
      set_report_being_tagged_external_id(null)
      set_tag_search_input('')
    } else {
      track_report_management_event(`obj="report" action="open_tag_dropdown" context="${TRACKING_CONTEXT}"`)
      set_report_being_tagged_external_id(report.external_report_id)
      show_create_tag_modal(!is_array_non_empty_non_null(tags))
      set_dropdown_is_showing_selected_tags(false)
    }
  }

  function update_tagged_report_from_state(report, tag, to_tag) {
    // add or remove tag from report, depending on the action
    const unsorted_tags = to_tag ? _.uniq([...report.tags, tag]) : report.tags.filter(t => t.tag_id !== tag.tag_id)
    const tags = alphabetise_tags(unsorted_tags)
    // update tag info properties for search and display
    const tag_ids = tags.map(t => t.tag_id)
    const tag_names = tags.map(get_searchable_tag_name)
    // a newly tagged report will have been saved also
    const is_saved = to_tag ? true : report.is_saved

    return {...report, is_saved, tags, tag_names, tag_ids}
  }

  function toggle_reports_tagged(external_report_ids, tag, to_tag) {
    track_report_management_event(`obj="${pluralise_text(external_report_ids.length, 'report')}" action="${to_tag ? 'add_tag' : 'remove_tag'}" context="${TRACKING_CONTEXT}"`)

    const reports_before_update = reports
    const tags_before_update = tags

    // update state optimistically (roll back on error)
    const reports_updated = reports.map(report => {
      if (_.contains(external_report_ids, report.external_report_id)) {
        return update_tagged_report_from_state(report, tag, to_tag)
      }
      return report
    })

    // update report counts for the relevant tag
    const reports_tagged_count = external_report_ids.length
    const tags_updated = tags.map(t => {
      if (t.tag_id === tag.tag_id) {
        const report_count_updated = to_tag ? t.report_count + reports_tagged_count : t.report_count - reports_tagged_count
        return {...t, report_count: report_count_updated}
      }
      return t
    })

    set_tags(tags_updated)
    set_reports(reports_updated)

    update_saved_reports_with_tag(external_report_ids, tag.tag_id, to_tag)
      .catch(error => {
        set_tags(tags_before_update)
        set_reports(reports_before_update)
        show_error_message_and_rethrow(error, 'updating report tags')
      })
  }

  function handle_create_new_tag(name) {
    const reports_to_tag = is_managing_tags ? [] : get_report_ids_to_apply_new_tag_to()
    create_and_apply_new_tag(reports_to_tag, name)
  }

  function get_report_ids_to_apply_new_tag_to() {
    return report_being_tagged_external_id ? [report_being_tagged_external_id] : (selected_reports_external_ids || [])
  }

  function create_and_apply_new_tag(reports_to_tag_external_ids, tag_name) {
    set_is_creating_tag(true)
    track_report_management_event(`obj="tag" action="${reports_to_tag_external_ids.length > 0 ? 'create_and_add' : 'create'}" context="${TRACKING_CONTEXT}"`)
    if (reports_to_tag_external_ids > 0) {
      track_report_management_event(`obj="${pluralise_text(reports_to_tag_external_ids.length, 'report')}" action="tag" context="${TRACKING_CONTEXT}"`)
    }

    create_new_tag(reports_to_tag_external_ids, tag_name)
      .catch(error => {
        show_create_tag_modal(false)
        show_tag_management_modal(false)
        show_error_message_and_rethrow(error, 'creating a new report tag')
      })
      .then(tag => {
        const tags_updated = alphabetise_tags([...tags, tag])
        set_tags(tags_updated)

        const reports_updated = reports.map(report => {
          if (_.contains(reports_to_tag_external_ids, report.external_report_id)) {
            return update_tagged_report_from_state(report, tag, true)
          }
          return report
        })

        set_reports(reports_updated)
        show_create_tag_modal(false)
      })
  }

  /* tag management */

  function rename_existing_tag(tag, name) {
    track_report_management_event(`obj="tag" action="rename" context="${TRACKING_CONTEXT}"`)
    set_error_in_tag_modal(null)

    const tags_before_update = tags
    const reports_before_update = reports

    // update state optimistically (roll back on error)
    const tag_updated = {...tag, name}
    const tags_updated = tags.map(t => t.tag_id === tag.tag_id ? tag_updated : t)

    set_tags(alphabetise_tags(tags_updated))

    const reports_updated = reports.map(report => {
      if (report_has_tag(report, tag)) {
        const updated_tags = report.tags.map(t => t.tag_id === tag.tag_id ? tag_updated : t)
        const tag_names = tags.map(get_searchable_tag_name)
        return {...report, tags: updated_tags, tag_names}
      }
      return report
    })

    set_reports(reports_updated)

    update_tag_name(tag.tag_id, name)
      .catch(error => {
        set_error_in_tag_modal({error, action: 'renaming tag'})
        set_tags(tags_before_update)
        set_reports(reports_before_update)
        throw error
      })
  }

  function handle_select_tag_to_delete(tag) {
    set_error_in_tag_modal(null)
    // only show a confirm modal if the tag has been added to some reports
    return tag.report_count > 0 ? show_tag_to_delete_modal(tag) : on_confirm_delete_tag(tag)
  }

  function on_confirm_delete_tag(tag) {
    track_report_management_event(`obj="tag" action="delete" context="${TRACKING_CONTEXT}"`)

    show_tag_to_delete_modal(null)

    const tags_before_update = tags
    const reports_before_update = reports

    const {tag_id: tag_id_to_delete} = tag || {}

    const reports_updated = reports.map(report => {
      if (report_has_tag(report, tag)) {
        return update_tagged_report_from_state(report, tag, false)
      }
      return report
    })
    set_reports(reports_updated)

    const tags_updated = tags.filter(t => t.tag_id !== tag_id_to_delete)
    set_tags(tags_updated)

    if (tag_id_to_filter_by === tag_id_to_delete) {
      set_tag_id_to_filter_by(null)
    }

    const { tag_id_from_url} = get_filter_params_from_url()
    if (tag_id_from_url && tag_id_from_url === tag_id_to_delete) {
      const new_query =  update_query_params_for_filtering(null, null)
      history.replace({pathname: window.location.pathname, search: `?${qs.stringify(new_query)}`})
    }

    delete_tag(tag_id_to_delete)
      .catch(error => {
        set_error_in_tag_modal({error, action: 'deleting a tag'})
        set_tags(tags_before_update)
        set_reports(reports_before_update)
        throw error
      })
  }

  /* deleting reports */

  function handle_select_reports_to_delete(reports) {
    const external_report_ids = reports.map(report => report.external_report_id)
    // only show a confirm modal if reports were built successfully
    const all_selected_are_failed = _.all(reports, report => is_failed_status(report.status))
    return all_selected_are_failed ? on_delete_reports_from_history(external_report_ids) : show_reports_to_delete_modal(external_report_ids)
  }

  function on_delete_reports_from_history(external_report_ids) {
    track_report_management_event(`obj="${pluralise_text(external_report_ids.length, 'report')}" action="delete" context="${TRACKING_CONTEXT}"`)
    show_reports_to_delete_modal([])

    const reports_before_update = reports
    const tags_before_update = tags
    const selected_reports_external_ids_before_update = selected_reports_external_ids

    const tags_updated = tags.map(tag => {
      const tagged_reports_removed = reports.filter(report => _.contains(external_report_ids, report.external_report_id) && report_has_tag(report, tag))
      const report_count = tag.report_count - tagged_reports_removed.length
      return {...tag, report_count}
    })

    set_tags(tags_updated)

    const reports_updated = reports.filter(report => !_.contains(external_report_ids, report.external_report_id))
    const selected_external_report_ids_updated = selected_reports_external_ids.filter(report_id => !_.contains(external_report_ids, report_id))

    set_selected_reports_external_ids(selected_external_report_ids_updated)
    set_reports(reports_updated)

    delete_reports_viewed(external_report_ids)
      .catch(error => {
        set_reports(reports_before_update)
        set_selected_reports_external_ids(selected_reports_external_ids_before_update)
        set_tags(tags_before_update)
        show_error_message_and_rethrow(error, 'deleting a report')
      })
  }

  function on_delete_project_from_history(project_id) {
    track_report_management_event(`obj="project" action="delete" context="${TRACKING_CONTEXT}"`)
    const projects_before_update = projects
    const selected_project_ids_before_update = selected_project_ids

    const selected_project_ids_updated = selected_project_ids.filter(p => p.project_id !== project_id)
    const projects_updated = projects.filter(p => p.project_id !== project_id)

    show_project_to_delete_modal(null)
    set_selected_project_ids(selected_project_ids_updated)
    set_projects(projects_updated)

    delete_project(project_id)
      .catch(error => {
        set_projects(projects_before_update)
        set_selected_project_ids(selected_project_ids_before_update)
        show_error_message_and_rethrow(error, 'deleting a project')
      })
  }

  function on_hide_project_from_history() {
    track_report_management_event(`obj="project" action="hide" context="${TRACKING_CONTEXT}"`)
    const project = project_id_to_project[project_to_hide_id]
    const payload = {
      users: [
        { entity_uuid: user.user_id,
          permissions: {view: false, edit: project.current_user_permissions.edit}
        }
      ],
      groups: []
    }
    update_project_permissions(project.project_id, payload)
      .catch(error => {
        show_error_message_and_rethrow(error, 'updating project permissions')
      })
      .then(() => {
        set_selected_project_ids(selected_project_ids.filter(p => p.project_id !== project.project_id))
        set_projects(projects.filter(p => p.project_id !== project.project_id))
        show_project_to_hide_modal(null)
      })
  }

  /* retrying and rebuilding */

  function on_retry_failed_report(report) {
    const {internal_report_id, external_report_id} = report

    track_report_builder_event(`action="retry_failed_report" context="${TRACKING_CONTEXT}"`)
    rerun_report_and_replace_id(internal_report_id, external_report_id)
      .catch(error => {
        const action = `retrying this failed report${is_not_found(error) ? '. Input selections could not be found for the report' : ''}`
        show_error_message_and_rethrow(error, action)
      })
      .then(() => {
        // go to the report (or, most likely, the progress view)
        history.push(`${REPORT}/${external_report_id}`)
      })
  }

  /* report & project renaming */

  function on_report_rename_confirm(title) {
    track_report_management_event(`obj="report" action="rename" context="${TRACKING_CONTEXT}"`)

    const reports_before_update = reports

    const updated_reports = reports.map(r => {
      return r.external_report_id === report_to_rename_external_id ? {...r, title} : r
    })

    // update state optimistically (roll back on error)
    set_reports(updated_reports)
    set_report_to_rename_external_id(null)

    update_report_title(report_to_rename_external_id, title)
      .catch(error => {
        set_reports(reports_before_update)
        show_error_message_and_rethrow(error, 'renaming report')
      })
  }

  function on_project_rename_confirm(name) {
    track_report_management_event(`obj="project" action="rename" context="${TRACKING_CONTEXT}"`)
    const projects_before_update = projects
    const updated_projects = projects.map(p => p.project_id === project_to_rename_id ? {...p, name} : p)

    // update state optimistically (roll back on error)
    set_projects(updated_projects)
    set_project_to_rename_id(null)

    update_project_name(project_to_rename_id, name)
      .catch(error => {
        set_projects(projects_before_update)
        show_error_message_and_rethrow(error )
      })
  }

  /* table rendering */

  function render_report_save_field(report) {
    const {is_saved, external_report_id} = report
    if (is_saved) {
      return (
        <TextLink
          onClick={() => show_reports_to_unsave_modal([external_report_id])}
          title='Remove from saved'
          no_decoration
        >
          <UnsaveIcon/>
        </TextLink>
      )
    }
    return (
      <TextLink
        onClick={() => on_toggle_save_reports([external_report_id], true)}
        title='Save'
        disable={!report_can_be_saved(report)}
        no_decoration
      >
        { is_saved ? <UnsaveIcon/> : <span className={s.save_icon}><SaveIcon font_size='small'/></span> }
      </TextLink>
    )
  }

  function render_build_new_from_params_link(report) {
    const {created_by_user, report_type, status, external_report_id, evaluation_classifier_id} = report

    const user_has_rebuild_permissions = (is_admin(user) && !evaluation_classifier_id) || (created_by_user && is_creator(user))
    const report_can_be_rebuilt_from_params = report_can_be_built_from_params(report_type, status)

    return (
      <TextLink
        title='Copy parameters'
        onClick={() => window.open(get_new_report_from_existing_url(external_report_id, report_type), '_blank')}
        disable={!user_has_rebuild_permissions || !report_can_be_rebuilt_from_params}
        no_decoration
      >
        <NewReportFromParamsIcon/>
      </TextLink>
    )
  }

  function render_delete_report_button(report) {
    return (
      <TextLink
        onClick={() => handle_select_reports_to_delete([report])}
        title={`Delete report ${report.title}`}
        disable={report.external_report_id == null}
        no_decoration
      >
        <TrashIcon/>
      </TextLink>
    )
  }

  function render_report_actions_field(report) {
    return (
      <div className='d-flex flex-wrap align-items-right justify-content-around'>
        {render_report_save_field(report)}
        {render_build_new_from_params_link(report)}
        {render_delete_report_button(report)}
      </div>
    )
  }

  function render_project_actions_field(project) {
    const {project_id, name, files_count, current_user_permissions} = project || {}

    const {view, edit} = current_user_permissions || {}

    const can_view = view || edit
    const can_edit = edit
    return (
      <div className='d-flex flex-wrap align-items-right justify-content-around'>
        <DownloadAllProjectFiles
          project_id={project_id}
          is_disabled={!can_view || files_count === 0}
        />
        <TextLink
          onClick={() => show_project_to_hide_modal(project_id)}
          title='Hide project'
          disable={can_edit}
          no_decoration
        >
          <EyeBlockedIcon/>
        </TextLink>
        <TextLink
          onClick={() => show_project_to_delete_modal(project_id)}
          title={`Delete project ${name}`}
          disable={!can_edit}
          no_decoration
        >
          <TrashIcon/>
        </TextLink>
      </div>
    )
  }

  function on_change_from_report_search_input(search_phrase) {
    if (search_phrase === report_search_input) return

    set_report_search_input(search_phrase)
    update_url_with_search_phrase(search_phrase, REPORT_SEARCH_PARAM_NAME, location, history)
  }

  function show_add_to_project_modal(is_show) {
    pause_or_resume_polling(is_show)
    set_is_show_add_to_project_modal(is_show)
  }

  function show_create_project_modal(is_show) {
    pause_or_resume_polling(is_show)
    set_is_show_create_project_modal(is_show)
  }

  function show_reports_to_delete_modal(report_ids) {
    const is_show_modal = report_ids && !_.isEmpty(report_ids)
    pause_or_resume_polling(is_show_modal)
    set_reports_to_delete_external_ids(report_ids)
  }

  function show_reports_to_unsave_modal(report_ids) {
    const is_show_modal = report_ids && !_.isEmpty(report_ids)
    pause_or_resume_polling(is_show_modal)
    set_reports_to_unsave_external_ids(report_ids)
  }

  function show_project_to_delete_modal(project_id) {
    pause_or_resume_polling(!!project_id)
    set_project_to_delete_id(project_id)
  }

  function show_project_to_hide_modal(project_id) {
    pause_or_resume_polling(!!project_id)
    set_project_to_hide_id(project_id)
  }

  function show_tag_to_delete_modal(tag) {
    pause_or_resume_polling(!!tag)
    set_tag_to_delete(tag)
  }

  /* project and version management */

  function add_reports_to_project(selected_project) {
    const {project_id} = selected_project
    set_is_adding_reports_to_project(true)
    get_project_reports(project_id)
      .then(project_reports => {
        const project_report_ids = project_reports.map(report => report.external_report_id)
        // filter selected reports to remove any that have failed status, or are already in the project
        const report_ids_to_add = selected_reports_external_ids.filter(ext_id => {
          const selected_report = external_report_id_to_report[ext_id]
          return (project_report_ids.indexOf(ext_id) === -1 && !is_failed_status(selected_report.status))
        })

        if (!report_ids_to_add) {
          show_add_to_project_modal(false)
          return
        }
        add_project_reports(project_id, {external_report_ids: report_ids_to_add})
          .then(() => load_table_data())
          .then(() => {
            set_is_adding_reports_to_project(false)
            show_add_to_project_modal(false)
          })
      })
      .catch(error => {
        set_is_adding_reports_to_project(false)
        show_add_to_project_modal(false)
        show_error_message_and_rethrow(error, 'adding reports to project')
      })
  }

  function on_create_new_project(name) {
    set_is_creating_project(true)
    create_project(name)
      .catch(error => {
        show_error_message_and_rethrow(error, 'creating project')
      })
      .then(() => load_table_data())
      .then(() => {
        set_is_creating_project(false)
        show_create_project_modal(false)
      })
  }

  function show_error_message_and_rethrow(error, action) {
    set_error_on_report_action({error, action})
    throw error
  }

  return (

    <ContainerFullWidthWithScroll>
      <h2 className='mt-4'>History</h2>

      { data_rows &&
        <div>
          <ClearableSearchInput
            containerClassName={cn('mt-2')}
            value={report_search_input}
            auto_focus={true}
            handle_change={on_change_from_report_search_input}
            show_clear={true}
          />
          { data_rows.length > 0 &&
            <div className='d-flex flex-wrap'>
              <TagsFilter
                tags={tags}
                selected_tag_id={tag_id_to_filter_by}
                select_tag_id_to_filter_by={(tag_id) => handle_update_report_filters(tag_id, NO_FILTER)}
                report_filter_id={report_filter_id}
                select_report_filter_id={(report_filter_id) => handle_update_report_filters(null, report_filter_id)}
                has_projects={has_projects}
                can_evaluate_classifiers={can_evaluate_classifiers}
              />
              <div className='d-flex align-items-center ms-auto pt-1'>
                { is_aistemos(user) &&
                  <TextLink
                    onClick={() => show_create_project_modal(!is_show_create_project_modal)}
                    className={'me-3'}
                    no_decoration
                  >
                    <ProjectIcon/>
                    <span className={cn('ms-1', cs.underline_on_hover)}>Create project</span>
                  </TextLink>
                }
                <TextLink no_decoration onClick={() => show_tag_management_modal(!is_managing_tags)}>
                  <TagsIcon/>
                  <span className={cn('ms-1', cs.underline_on_hover)}>Manage tags</span>
                </TextLink>
              </div>
            </div>
          }
          <div className={cn('py-3 d-flex flex-wrap align-items-center sticky-top', s.list_controls)}>
            <ManageSelectedReportsControl
              disabled={!has_report_rows}
              toggle_select_all={() => toggle_selected(data_rows_sorted, !is_select_all_reports, true)} // ALL reports (not just the current page)
              on_delete_selected={() => handle_select_reports_to_delete(selected_reports)}
              on_save_or_unsave_selected={(is_save) => save_or_unsave_selected(is_save)}

              total_selected={selected_reports.length}
              selected_and_saveable_reports={selected_and_saveable_reports}
              tags={tags}
              is_editing_tags_for_selected={is_editing_tags_for_selected_reports}
              edit_tags_for_selected={() => {
                if (tag_modal_is_open) return

                if (is_array_non_empty_non_null(tags)) {
                  return set_is_editing_tags_for_selected_reports(!is_editing_tags_for_selected_reports)
                }

                return is_editing_tags_for_selected_reports ? set_is_editing_tags_for_selected_reports(false) : show_create_tag_modal(true)
              }}
              select_tag={(tag, to_tag) => toggle_reports_tagged(selected_and_saveable_reports_external_ids, tag, to_tag)}
              tag_search_string={tag_search_input}
              handle_update_tag_search_string={set_tag_search_input}
              is_select_all={is_select_all_reports}
              handle_create_new_tag={() => show_create_tag_modal(true)}
              handle_manage_tags={() => show_tag_management_modal(true)}

              on_add_reports_to_project={() => show_add_to_project_modal(true)}
            />

            {/* Right aligned items */}
            <div className='d-flex align-items-center ms-auto'>

              <PageSizeControl
                className='me-3 d-flex align-items-center'
                page_sizes={PAGE_SIZES}
                selected_page_size={page_size}
                on_change_page_size={set_page_size}
              />
              <PageControl
                current_page={page_number}
                num_pages={num_pages}
                on_change_current_page={set_page_number}
              />
            </div>
          </div>

          <ReportManagementTable
            fields={FIELDS}

            field_id_to_render_fn={{
              [NAME_FIELD_ID]: function NameField(data_row) {
                const report = data_row.external_report_id ? data_row : null
                const project = !report ? data_row : null
                const {external_report_id, evaluation_classifier_id} = report || {}

                return (
                  <div>
                    {report &&
                      <ReportNameField
                        report={report}
                        tags={tags}
                        eval_classifier_name={evaluation_classifier_id ? eval_classifier_names[evaluation_classifier_id] : null}
                        is_renaming_report={report_to_rename_external_id === external_report_id}
                        tracking_context={TRACKING_CONTEXT}
                        on_edit_report_name={() => set_report_to_rename_external_id(external_report_id)}
                        on_confirm_rename_report={(name_updated) => on_report_rename_confirm(name_updated)}
                        on_cancel_edit_report_name={() => set_report_to_rename_external_id(null)}

                        is_editing_report_tags={report_being_tagged_external_id === external_report_id}
                        handle_edit_report_tags={() => tag_modal_is_open ? null : update_id_of_report_being_tagged(report)}
                        tag_search_input={tag_search_input}
                        is_showing_selected_tags={dropdown_is_showing_selected_tags}
                        toggle_is_showing_selected_tags={() => set_dropdown_is_showing_selected_tags(!dropdown_is_showing_selected_tags)}
                        handle_select_tag={(tag) => handle_update_report_filters(tag.tag_id, NO_FILTER)}
                        handle_update_tag_search_string={set_tag_search_input}
                        handle_create_new_tag={() => show_create_tag_modal(true)}
                        handle_manage_tags={() => show_tag_management_modal(true)}
                        handle_tag_or_untag={(tag, to_tag) => toggle_reports_tagged([external_report_id], tag, to_tag)}
                      />
                    }
                    {project &&
                      <ProjectNameField
                        project={project}
                        tracking_context={TRACKING_CONTEXT}
                        is_renaming_project={project_to_rename_id === project.project_id}
                        on_edit_project_name={() => set_project_to_rename_id(project.project_id)}
                        on_confirm_project_rename={(name_updated) => on_project_rename_confirm(name_updated)}
                        on_cancel_edit_project_name={() => set_project_to_rename_id(null)}
                      />
                    }
                  </div>
                )
              },
              [ACTIONS_FIELD_ID]: function ActionsField(data_row) {
                return data_row.external_report_id ? render_report_actions_field(data_row) : render_project_actions_field(data_row)
              }
            }}

            field_id_to_className={{
              [NAME_FIELD_ID]: s.report_name_field,
            }}

            reports={page_reports_with_owner_names}

            selected_external_report_ids={selected_reports_external_ids}
            selected_project_ids={selected_project_ids}
            selected_sort_field_id={sort_field_id}
            selected_sort_direction_id={sort_direction_id}
            on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}

            user={user}
            retry_failed_report={on_retry_failed_report}
            on_delete_reports={(reports) => handle_select_reports_to_delete(reports)}

            toggle_selected={toggle_selected}

            no_data_text={is_fetching ? null : ((report_search_input || tag_id_to_filter_by) ? 'No search results found' : `Start ${is_creator(user) ? 'creating reports' : 'using Cipher' } to build your report history`)}

            loading_text='Loading history'

            loading={is_fetching}

            className={cn('pb-3 w-100', s.table)}
          />
        </div>
      }

      { error_on_report_action &&
        <ErrorModal
          on_hide={() => set_error_on_report_action(null)}
          error={error_on_report_action.error}
          context={error_on_report_action.action}
          on_retry={error_on_report_action.action === ACTION_FETCHING_HISTORY ? load_table_data : null}
        />
      }

      { reports_to_delete_external_ids && reports_to_delete_external_ids.length > 0 &&
        <ReportActionConfirmModal
          modal_report_action_id={ACTION_DELETE_ID}
          report_to_display={external_report_id_to_report[reports_to_delete_external_ids[0]]}
          external_report_ids={reports_to_delete_external_ids}
          on_confirm={() => on_delete_reports_from_history(reports_to_delete_external_ids)}
          on_cancel={() => show_reports_to_delete_modal([])}
          tracking_context={TRACKING_CONTEXT}
        />
      }

      { project_to_delete_id &&
        <ConfirmModal
          title={'Are you sure you want to delete this project?'}
          on_cancel={() => show_project_to_delete_modal(null)}
          on_confirm={() => on_delete_project_from_history(project_to_delete_id)}
        >
          <span>{`Project '${project_id_to_project[project_to_delete_id].name}' will be deleted including all attached files.`}</span>
          <br/>
          <span>The project reports will NOT be deleted.</span>
        </ConfirmModal>
      }

      { reports_to_unsave_external_ids && reports_to_unsave_external_ids.length > 0 &&
        <ReportActionConfirmModal
          modal_report_action_id={ACTION_UNSAVE_ID}
          report_to_display={external_report_id_to_report[reports_to_unsave_external_ids[0]]}
          external_report_ids={reports_to_unsave_external_ids}
          on_confirm={() => on_toggle_save_reports(reports_to_unsave_external_ids, false)}
          on_cancel={show_reports_to_unsave_modal}
          tracking_context={TRACKING_CONTEXT}
        />
      }

      {is_show_create_tag_modal &&
        <CreateNewTagModal
          handle_create_new_tag={(name) => handle_create_new_tag(name)}
          existing_tags={tags}
          is_creating_tag={is_creating_tag}
          on_close={() => show_create_tag_modal(false)}
        />
      }

      {tag_to_delete &&
        <ConfirmModal
          title={'Are you sure you want to delete this tag?'}
          on_cancel={() => show_tag_to_delete_modal(null)}
          on_confirm={() => on_confirm_delete_tag(tag_to_delete)}
          confirm_label='Delete'
        >
          {`Tag '${tag_to_delete.name}' will be removed from ${tag_to_delete.report_count} reports.`}
        </ConfirmModal>
      }

      {is_managing_tags && !is_show_create_tag_modal && !tag_to_delete &&
        <TagManagementModal
          tags={tags}
          on_hide={() => show_tag_management_modal(false)}
          error_managing_tags={error_in_tag_modal}
          handle_create_new_tag={() => show_create_tag_modal(true)}
          handle_rename_existing_tag={rename_existing_tag}
          handle_select_tag_to_delete={(tag) => handle_select_tag_to_delete(tag)}
        />
      }

      {is_show_add_to_project_modal &&
        <AddToProjectModal
          on_hide={() => show_add_to_project_modal(false)}
          reports_count={selected_reports.length}
          on_add_to_project={add_reports_to_project}
          is_adding_reports_to_project={is_adding_reports_to_project}
        />
      }

      {is_show_create_project_modal &&
        <CreateProjectModal
          on_hide={() => show_create_project_modal(false)}
          on_create_project={on_create_new_project}
          is_creating_project={is_creating_project}
        />
      }

      {project_to_hide_id &&
        <ConfirmModal
          title={'Are you sure you want to hide this project?'}
          on_cancel={() => show_project_to_hide_modal(null)}
          on_confirm={() => on_hide_project_from_history()}
          confirm_label='Hide'
        >
          <div>Once you hide this project only users with edit permissions can restore your access.</div>
        </ConfirmModal>
      }

    </ContainerFullWidthWithScroll>
  )
}

export default withUser(withRouter(Reports))