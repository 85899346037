import React, { useEffect } from 'react'
import { Autocomplete, TextField } from '@mui/material'

import { Highlighter } from './Highlighter.js'
import { get_destination_label, MUI_destination_filter_options } from '../../utils/destination_utils.js'

const SingleDestinationSelector = ({
  label,       // i.e. 'Share with'
  destinations,
  selected_destination,
  on_change,
  focus_on_mount,
  placeholder,
  disabled
}) => {

  let input_ref = null

  useEffect(() => {
    if (focus_on_mount) {
      input_ref.focus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Autocomplete
      disabled={disabled}
      className='flex-grow-1'
      options={destinations}
      value={selected_destination}
      onChange={on_change}
      filterOptions={MUI_destination_filter_options}
      getOptionLabel={get_destination_label}
      multiple={false}
      freeSolo={false} // enable to allow user to enter their own inputs
      filterSelectedOptions={true}
      disablePortal={true}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={input => { input_ref = input }}
          label={label}
          placeholder={placeholder || 'User or group'}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const label = get_destination_label(option)
        return (
          <li {...props}>
            <Highlighter
              search_words={[inputValue]}
              text_to_highlight={label}
            />
          </li>
        )
      }}
    />
  )
}

export default SingleDestinationSelector