import React from 'react'
import cn from 'classnames'

import Header from './header/Header.js'
import DefaultContentContainer from './DefaultContentContainer.js'
import DefaultPageContainer from './DefaultPageContainer.js'
import { withUser } from './UserContext.js'

import s from './DefaultPageWrapper.module.scss'

const DefaultPageWrapper = (
  {
    display_header,
    dark,
    with_page_scroll,
    with_background_image,
    contentWrapperClassName,
    children
  }) => {
  return (
    <DefaultPageContainer
      dark={dark}
      className={cn(s.block, {[s.__bg_img]: with_background_image})}
    >
      {display_header !== false &&
        <Header dark={dark} />
      }
      <DefaultContentContainer className={cn({[s.__bg_img_content]: with_background_image}, contentWrapperClassName)} dark={dark} page_scroll={with_page_scroll}>
        {children}
      </DefaultContentContainer>
    </DefaultPageContainer>
  )
}

export default withUser(DefaultPageWrapper)