import axios from 'axios'

import { RESOURCE_ACCESS_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err, JSON_POST_HEADER } from './axios_utils.js'

export function user_has_resource_access(user_id, resources) {
  const data = {user_id, resources}
  return axios.post(`${RESOURCE_ACCESS_BASE_URL}/check`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to check user resource permissions: ')
    })
}