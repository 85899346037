import _ from 'underscore'
import React, {useState, useEffect} from 'react'

import Modal from '../widgets/Modal.js'
import {
  fetch_alert_subscribers,
  get_alert_display_name,
  get_permissions_to_check,
  subscribe_user,
  unsubscribe_user
} from '../../utils/alert_report_utils.js'
import ErrorBody from '../ErrorBody.js'
import { Table } from '../widgets/Table.js'
import { withUser } from '../UserContext.js'
import { is_aistemos } from '../../utils/user_permissions.js'
import {
  fetch_all_active_keycloak_users,
  fetch_all_users_in_my_group
} from '../../utils/user_group_utils.js'
import {
  clean_users
} from '../../utils/user_info.js'
import { array_to_wordy_string, get_as_map, pluralise_text } from '../../utils/utils.js'
import { IS_ME_PREFIX } from '../../model/user_info.js'
import TextLink from '../widgets/TextLink.js'
import { RemoveIcon } from '../widgets/IconSet.js'
import { PrimaryButton } from '../widgets/Button.js'
import Spinner from '../widgets/Spinner.js'
import SingleDestinationSelector from '../widgets/SingleDestinationSelector.js'
import { user_has_resource_access } from '../../utils/resource_access_utils.js'
import { track_subscriptions_event } from '../../utils/tracking_utils.js'
import { ALERT_TYPE_ID_DELTA } from '../../constants/alert_reports.js'
import { ALERT_REPORT_TYPE_NAME_DELTA } from '../../model/alert_reports.js'
import { FormFeedback } from '../widgets/FormFeedback.js'
import { is_not_found } from '../../utils/axios_utils.js'

const MAX_RESOURCE_NAMES_TO_SHOW = 3

const ShareAlertModal = ({alert, user, on_close, handle_confirm_unsubscribe_self}) => {
  const [users, set_users] = useState([])

  const [is_fetching_inputs, set_is_fetching_inputs] = useState(true)
  const [subscribers, set_subscribers] = useState([])

  const [error_fetching_data, set_error_fetching_data] = useState(null)
  const [error_updating_subscribers, set_error_updating_subscribers] = useState(null)
  const [error_checking_permissions, set_error_checking_permissions] = useState(null)

  const [selected_destination, set_selected_destination] = useState(null)

  const [is_saving, set_is_saving] = useState(false)
  const [is_checking, set_is_checking] = useState(false)
  const [is_success, set_is_success] = useState(false)
  const [permissions_issues, set_permissions_issues] = useState(null)

  const [permissions_to_check, set_permissions_to_check] = useState({})

  const {alert_id, alert_type_id, alert_report_name, skip_empty_alerts} = alert

  const alert_type_name = alert_type_id === ALERT_TYPE_ID_DELTA ? ALERT_REPORT_TYPE_NAME_DELTA : get_alert_display_name(alert_type_id)

  const is_group_aistemos = is_aistemos(user)

  function load_alert_and_subscribers() {
    return Promise.all(
      [
        get_permissions_to_check(alert_id),
        fetch_alert_subscribers(alert_id),
        is_group_aistemos ? fetch_all_active_keycloak_users() : fetch_all_users_in_my_group(), // users
      ]
    )
      .then(([alert_permissions_to_check, existing_subscribers, users]) => {
        const cleaned_users = _.sortBy(clean_users(users, user.user_id), user => user.email)
        set_permissions_to_check(alert_permissions_to_check)
        set_users(cleaned_users)
        set_subscribers(existing_subscribers)

        set_is_fetching_inputs(false)
      })
  }

  useEffect(() => {
    let did_cancel = false
    load_alert_and_subscribers()
      .catch(err => {
        if (!did_cancel) {
          set_error_fetching_data(err)
          set_is_fetching_inputs(false)
        }
      })
    return () => {
      did_cancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset_ui_feedback()
    if (!selected_destination) {
      return
    }
    set_is_success(false)
    if (_.isEmpty(_.keys(permissions_to_check))) {
      return
    }
    set_is_checking(true)
    const {id: user_id} = selected_destination
    user_has_resource_access(user_id, permissions_to_check)
      .then(({no_access}) => {
        set_permissions_issues(_.isEmpty(no_access) ? null : no_access)
        set_is_checking(false)
      })
      .catch(error => {
        set_is_checking(false)
        if (is_not_found(error)) {
          const {response} = error
          const {data} = response || {}
          const {unknown_input} = data || {}
          if (unknown_input) {
            // edge case: sharing isn't possible because something in the alert input looks broken/ missing
            // for now show error feedback; when we next try to run this alert, the owner will get a message to help them fix it
            set_permissions_issues({unknown_input})
            return
          }
        }
        set_error_checking_permissions(error)
      })
  }, [selected_destination, permissions_to_check])

  function reset_ui_feedback() {
    set_permissions_issues(null)
    set_error_fetching_data(null)
    set_error_checking_permissions(null)
    set_error_updating_subscribers(null)
  }

  function on_remove_recipient(user_id) {
    reset_ui_feedback()
    set_is_success(false)
    set_is_saving(true)
    track_subscriptions_event(`obj="report_based_alert" action="remove_recipient" origin="alert_list_share_modal" alert_type="${alert_type_name}"`)
    unsubscribe_user(alert_id, user_id)
      .then(() => {
        const updated_subscribers = subscribers.filter(subscriber => subscriber.user_id !== user_id)
        set_subscribers(updated_subscribers)
        set_is_saving(false)
        set_is_success(true)
      })
      .catch(error => {
        set_is_saving(false)
        set_is_success(false)
        set_error_updating_subscribers(error)
      })
  }

  function on_add_recipient() {
    reset_ui_feedback()
    set_is_success(false)
    set_is_saving(true)
    const {id} = selected_destination
    track_subscriptions_event(`obj="report_based_alert" action="add_recipient" origin="alert_list_share_modal" alert_type="${alert_type_name}"`)
    subscribe_user(alert_id, id, skip_empty_alerts)
      .then(() => {
        const updated_subscribers = [...subscribers, {user_id: id, is_owner: false}]
        set_selected_destination(null)
        set_is_saving(false)
        set_is_success(true)
        set_subscribers(updated_subscribers)
      })
      .catch(error => {
        set_is_saving(false)
        set_error_updating_subscribers(error)
      })

  }

  const id_to_user_all_active = get_as_map(users, 'id')

  const subscribers_data = subscribers.map(subscriber => {
    const {user_id, is_owner} = subscriber
    const subscribing_user = id_to_user_all_active[user_id]
    return {
      ...subscribing_user,
      is_owner
    }
  })

  const subscriber_ids = subscribers.map(subscriber => subscriber.user_id)
  const available_users = users.filter(user => !_.contains(subscriber_ids, user.id))

  const show_spinner = is_saving || is_checking
  const can_share = selected_destination && !show_spinner && !is_success && !error_checking_permissions && !permissions_issues

  function get_permissions_message() {
    const {taxonomies, classifiers, unknown_input} = permissions_issues || {}
    if (taxonomies && !_.isEmpty(taxonomies)) {
      return `The selected user does not have access to ${pluralise_text(taxonomies.length, 'the taxonomy', 'taxonomies')} for this alert.`
    } else if (classifiers && !_.isEmpty(classifiers)) {
      const classifiers_to_list = classifiers.slice(0, MAX_RESOURCE_NAMES_TO_SHOW)
      const names = array_to_wordy_string(classifiers_to_list.map(classifier => `'${classifier.name}'`))
      const not_listed_count = classifiers.length - classifiers_to_list.length
      return `The selected user does not have access to the ${pluralise_text(classifiers.length, 'classifier')} ` + names + (not_listed_count ? `, plus ${not_listed_count} more` : '') + ' for this alert.'
    } else if (unknown_input) {
      return unknown_input
    }
    return ''
  }

  return (
    <Modal
      size='md'
      title={`Share '${alert_report_name}'`}
      close_label='OK'
      on_hide={on_close}
      no_close_on_esc={true}
      footer={(
        <>
          {show_spinner && !is_success &&
            <div className='d-flex'>
              <Spinner />
              <span className='ms-2 mt-1'>
                {is_saving ? 'Saving' : 'Checking permissions'}...
              </span>
            </div>
          }
          {is_success && !show_spinner &&
            <div className='ms-2'>Changes saved.</div>
          }
        </>
      )}
    >
      {error_fetching_data &&
        <ErrorBody
          error={error_fetching_data}
          context='fetching alerts or subscribers'
        />
      }

      {error_updating_subscribers &&
        <ErrorBody
          error={error_updating_subscribers}
          context='updating alert subscribers'
        />
      }

      {error_checking_permissions &&
        <ErrorBody
          error={error_checking_permissions}
          context='checking permissions for alert'
        />
      }

      { !is_fetching_inputs &&
        <>
          <div className='d-flex mt-1'>
            <SingleDestinationSelector
              destinations={available_users}
              selected_destination={selected_destination}
              on_change={(event, destination) => set_selected_destination(destination)}
              focus_on_mount={true}
              placeholder='User'
              disabled={show_spinner}
            />
            <PrimaryButton
              className='ms-2'
              onClick={on_add_recipient}
              disabled={!can_share}
            >
              Share
            </PrimaryButton>
          </div>
          <FormFeedback valid={!permissions_issues} validation_text={get_permissions_message()} />

          <Table
            className='mt-3'
            data={subscribers_data}
            columns={[
              {
                field: 'user',
                label: 'Shared with',
                cell_render: (row) => {
                  const {original} = row
                  const {email, is_me} = original
                  return (
                    <span className='my-auto'>
                      {(is_me ? `${IS_ME_PREFIX} ` : '') + email}
                    </span>
                  )
                }
              },
              {
                field: 'is_owner',
                label: 'Permission',
                cell_render: (row) => {
                  const {value: is_owner} = row
                  return <span>{is_owner ? 'Owner' : 'Subscriber'}</span>
                },
                width: 140
              },
              {
                field: 'unsubscribe',
                label: 'Remove',
                cell_render: (row) => {
                  const {original} = row
                  const {is_me, id, is_owner} = original
                  return (
                    <TextLink
                      className='h-100 align-items-center d-flex justify-content-center'
                      no_decoration
                      disable={is_owner && !is_me}
                      onClick={() => is_me ? handle_confirm_unsubscribe_self(alert_id) : on_remove_recipient(id)}
                    >
                      <RemoveIcon/>
                    </TextLink>
                  )
                },
                width: 90
              }
            ]}
          />
        </>
      }
    </Modal>
  )
}

export default withUser(ShareAlertModal)