import React from 'react'

import UserSearchesTable from './UserSearchesTable.js'
import Modal from '../widgets/Modal.js'

import s from './UserSearchesModal.module.scss'

const UserSearchesModal = (
  {
    current_id,
    on_update_current_search,
    on_modal_hide,
  }) => {

  return (
    <Modal on_hide={on_modal_hide} title='Pinned and recent searches' no_footer={true} className={s.modal} bodyClassName='h-100 overflow-hidden' contentClassName='h-100'>

      <UserSearchesTable
        current_id={current_id}
        on_update_current_search={on_update_current_search}

        on_select_search={on_modal_hide}

        listWrapperClassName='h-100 overflow-hidden'
        controlsWrapperClassName={s.controls}
        tableWrapperClassName={s.table}
      />

    </Modal>

  )
}

export default UserSearchesModal