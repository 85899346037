import React, { useState, useRef, useEffect, useContext } from 'react'
import cn from 'classnames'

import {
  SAMPLE_LIMIT,
  NAV_TOP_SECTION_ID,
  NAV_COSTS_SECTION_ID,
  NAV_LEGAL_SECTION_ID,
  NAV_DISPUTES_SECTION_ID,
  NAV_CLAIMS_SECTION_ID,
  NAV_PAT_MEMBERS_SECTION_ID,
  NAV_OUT_CITATIONS_SECTION_ID,
  NAV_IMAGES_SECTION_ID,
  NAV_TOP_SECTION,
  NAV_COSTS_SECTION,
  NAV_LEGAL_SECTION,
  NAV_DISPUTES_SECTION,
  NAV_CLAIMS_SECTION,
  NAV_IMAGES_SECTION,
  NAV_DESCRIPTION_SECTION_ID,
  NAV_DESCRIPTION_SECTION,
  NAV_ABSTRACT_SECTION,
  NAV_ABSTRACT_SECTION_ID
} from '../../model/family_view.js'
import {
  get_family_data,
  get_legal_events_sorted,
  IN_CITATIONS_TITLE,
  OUT_CITATIONS_TITLE,
  order_patent_numbers_and_select_best_representation,
} from '../../utils/family_view_utils.js'
import { SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT, scroll_to_top } from '../../utils/scroll_utils.js'
import {
  can_view_tagged_families,
  has_classifiers_edit,
  has_utt
} from '../../utils/user_permissions.js'
import {
  get_patent_link_option_id_preference,
  update_patent_link_option_id_preference
} from '../../utils/patent_family_list_utils.js'

import { PATENT_LINK_AS_GOOGLE_OPTION_ID, PATENT_LINK_OPTION_DEFAULT_ID } from '../../model/patent_links.js'
import { fetch_executable_tags } from '../family_tagging/family_tag_utils'
import { is_utt_or_utt_landscape_report_type } from '../../utils/report_utils.js'
import FamilyTags from '../family_tagging/FamilyTags'

import { withUser } from '../UserContext.js'

import FamilyDetailsNavigation, { NavigationAnchor } from './FamilyDetailsNavigation.js'
import { FamilyTitle } from './FamilyTitle.js'
import { FamilyAbstract } from './FamilyAbstract.js'
import { FamilyStatus } from './FamilyStatus.js'
import { FamilyDates } from './FamilyDates.js'
import { FamilyTerritories } from './FamilyTerritories.js'
import { FamilyAssignees } from './FamilyAssignees.js'
import { FamilyCpcCodes } from './FamilyCpcCodes.js'
import { FamilyInventors } from './FamilyInventors.js'
import { FamilyPatentsSample } from './FamilyPatentsSample.js'
import { FamilyDetailsTile } from './FamilyDetailsTile.js'
import AddPatentToTrainingSetWrapper from './AddPatentToTrainingSetWrapper.js'
import { Heading, Label } from './FamilyDetailsLabel.js'
import CostsView from './CostsView.js'
import LegalEventsView from './LegalEventsView.js'
import FamilyDisputesView from './FamilyDisputesView.js'
import { FamilyPatentsDisplay } from './FamilyPatentsDisplay.js'
import ErrorBoundary from '../ErrorBoundary.js'
import { FamilyIdDisplay } from './FamilyIdDisplay.js'
import FamilyXMLViews from './FamilyXMLViews.js'
import Spinner from '../widgets/Spinner.js'
import { get_inpadocs_by_pat_fam_ids } from '../../utils/domain_utils.js'
import FamilyLegalEventsSummary from './FamilyLegalEventsSummary.js'
import FamilyClassWrapper from './FamilyClassWrapper.js'
import FamiliesListDisplay from './FamiliesListDisplay.js'
import { UserSettingsContext } from '../UserSettingsContext.js'
import { get_language_preference } from '../../utils/user_settings_utils.js'
import TechExplorerSelectionControls from './TechExplorerSelectionControls.js'
import TextLink from '../widgets/TextLink.js'
import { is_tech_discovery } from '../../utils/utils.js'

import s from './FamilyDetails.module.scss'

const FamilyDetails = (
  {
    user,
    family: input_family,
    selected_patent_number: selected_patent_number_prop,

    report_input,
    reset_selected_classifier_id_on_change_patfam,
    top_className,

    is_in_modal,

    // Eval classifier stuff (may be null)
    eval_training_set_id,
    eval_classifier_data,
    on_set_local_classifier_label,
    search_phrases,
    search_colours,
    highlight_prefix_only,
    no_highlighting,
    patent_link_option_id,
    on_change_patent_link_option_id,
    on_change_selected_patent_number,

    is_family_tagging_mode_on,
    family_tagging_search_phrase,
    on_toggle_family_tagging_mode,
    on_update_family_tagging_search_phrase,
    notify_tag_change,

    technology,
    allow_public_access,

    knn_family_ids,
    knn_blocklist,
    knn_bookmarked,
    on_add_to_knn_family_ids,
    on_add_to_knn_blocklist,
    on_add_to_knn_bookmarked,
}) => {
  const is_tech_discovery_context = is_tech_discovery()

  const { user_settings } = useContext(UserSettingsContext) || {}
  const preferred_language = get_language_preference(user_settings)

  const [selected_patent_link_mode, set_selected_patent_link_mode] = useState(allow_public_access ? PATENT_LINK_AS_GOOGLE_OPTION_ID : get_patent_link_option_id_preference() || PATENT_LINK_OPTION_DEFAULT_ID)

  const [domain_family, set_domain_family]   = useState(null)
  const [selected_patent_number, set_selected_patent_number] = useState(null)

  const [legal_events, set_legal_events]     = useState(null)
  const [is_fetching_family, set_is_fetching_family]             = useState(true)
  const [is_fetching_legal_events, set_is_fetching_legal_events] = useState(true)

  const [deprecated_by_family, set_deprecated_by_family]                 = useState(null)
  const [deprecated_legal_events, set_deprecated_legal_events]           = useState(null)
  const [is_fetching_deprecated_by, set_is_fetching_deprecated_by]       = useState(false)

  const [error_family_fetching, set_error_family_fetching] = useState(null)
  const [error_events_fetching, set_error_events_fetching] = useState(null)
  const [error_fetching_deprecated_by, set_error_fetching_deprecated_by] = useState(null)
  const [error_fetching_family_tags, set_error_fetching_family_tags] = useState(null)

  const [show_deprecated, set_show_deprecated] = useState(false)
  const [fetch_deprecated, set_fetch_deprecated] = useState(false)

  const [user_tags, set_user_tags] = useState([])
  const [fetch_family_tags, set_fetch_family_tags] = useState(false)

  const top_section_ref            = useRef()
  const images_section_ref         = useRef()
  const claims_section_ref         = useRef()
  const description_section_ref    = useRef()
  const abstract_section_ref       = useRef()
  const costs_section_ref          = useRef()
  const patent_members_section_ref = useRef()
  const in_citations_section_ref   = useRef()
  const out_citations_section_ref  = useRef()
  const legal_events_section_ref   = useRef()
  const disputes_section_ref       = useRef()

  const section_id_to_ref = {
    [NAV_TOP_SECTION_ID]: top_section_ref,
    [NAV_IMAGES_SECTION_ID]: images_section_ref,
    [NAV_ABSTRACT_SECTION_ID]: abstract_section_ref,
    [NAV_CLAIMS_SECTION_ID]: claims_section_ref,
    [NAV_DESCRIPTION_SECTION_ID]: description_section_ref,
    [NAV_COSTS_SECTION_ID]: costs_section_ref,
    [NAV_PAT_MEMBERS_SECTION_ID]: patent_members_section_ref,
    [NAV_OUT_CITATIONS_SECTION_ID]: out_citations_section_ref,
    [NAV_LEGAL_SECTION_ID]: legal_events_section_ref,
    [NAV_DISPUTES_SECTION_ID]: disputes_section_ref,
  }
  const patent_link_mode = patent_link_option_id || selected_patent_link_mode

  const base_family         = show_deprecated ? deprecated_by_family : input_family  // data for most fields (i.e. title, abstract, status, cpc, etc...)
  const extra_fields_family = show_deprecated ? deprecated_by_family : domain_family // data for cost / citations

  const { patentNumbers, title, status } = base_family || {}
  const is_missing = (!title && !status && !is_fetching_deprecated_by && !error_family_fetching && !error_fetching_deprecated_by)

  const { inCitations, outCitations, costPerYear } = extra_fields_family || {} // may be null

  const {patfams: in_citations_patfams} = inCitations || {}
  const {patfams: out_citations_patfams} = outCitations || {}

  const {user_id} = user || {}

  const highlighter_props = {
    no_highlighting,
    highlight_prefix_only,
    search_words: search_phrases,
    search_colours: search_colours
  }

  useEffect(() => {
    // When the family changes (or first loads)...

    if (domain_family && input_family && input_family.id === domain_family.id && !is_in_modal) {
      // Family has not changed, and not in model, so no need to update (seems like context menu changes trigger this)
      return
    }

    // Scroll to top
    if (is_in_modal) {
      // hack to deal with ReactStrap (bootstrap) modals. Ideally we should not be showing FamilyDetails in a modal, but for now...
      const el = document.querySelectorAll('.modal-body')[0]
      if (el) {
        el.scrollTop = 0
      }
    } else {
      scroll_to_top(top_section_ref, SCROLL_INTO_VIEW_BEHAVIOUR_INSTANT)
    }
    // Clear deprecated
    set_show_deprecated(false)
    set_deprecated_by_family(null)
    set_is_fetching_deprecated_by(false)
    set_error_fetching_deprecated_by(null)

    // Fetch domain family (for costs, outcitations), and deprecated family (if required).
    set_domain_family(null)
    set_is_fetching_family(true)
    set_is_fetching_legal_events(true)
    set_error_family_fetching(null)
    set_error_events_fetching(null)

    let did_cancel = false

    Promise.all([
      get_family_data(input_family.patFamId, allow_public_access)
        .then(response => {
          if(!did_cancel) {
            return response
          }
        })
        .catch(err => {
          if(!did_cancel) {
            set_error_family_fetching(err)
            return err
          }}),
      get_inpadocs_by_pat_fam_ids(input_family.patFamId)
        .then((response) => {
          if(!did_cancel) {
            return response
          }
        })
        .catch(err => {
          if(!did_cancel) {
            set_error_events_fetching(err)
            return err
          }})
    ])
      .then(([domain_family, legal_events]) => {
        if (!did_cancel) {
          set_domain_family(domain_family)
          set_legal_events(get_legal_events_sorted(legal_events[0]))
          const { representative_patent_number } = order_patent_numbers_and_select_best_representation(domain_family, preferred_language)
          set_selected_patent_number(representative_patent_number)

          const {patentNumbers=[]} = domain_family || {}
          if (selected_patent_number_prop && on_change_selected_patent_number && (patentNumbers.indexOf(selected_patent_number_prop) === -1)) {
            on_change_selected_patent_number(representative_patent_number)
          }

          set_is_fetching_family(false)
          set_is_fetching_legal_events(false)
        }
      })
      .catch(() => {
        if (!did_cancel) {
          set_is_fetching_family(false)
          set_is_fetching_legal_events(false)
        }
      })

    return () => {
       did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input_family, is_in_modal])

  useEffect(() => {
    if (!fetch_deprecated) return

    let did_cancel = false

    set_error_fetching_deprecated_by(null)
    set_is_fetching_deprecated_by(true)
    set_is_fetching_legal_events(true)

    const deprecated_family_id = domain_family.deprecatedBy
    Promise.all([
      get_family_data(deprecated_family_id, allow_public_access)
        .then(response => {
          if(!did_cancel) {
            return response
          }
        })
        .catch(err => {
          if(!did_cancel) {
            set_error_fetching_deprecated_by(err)
            return err
          }}),
      get_inpadocs_by_pat_fam_ids(deprecated_family_id)
        .then((response) => {
          if(!did_cancel) {
            return response
          }
        })
        .catch(err => {
          if(!did_cancel) {
            set_error_events_fetching(err)
            return err
          }})
      ])
      .then(([deprecated_by_family, legal_events]) => {
        if (!did_cancel) {
          set_deprecated_by_family({...deprecated_by_family, patFamId: deprecated_family_id})
          set_deprecated_legal_events(get_legal_events_sorted(legal_events[0]))

          const { representative_patent_number } = order_patent_numbers_and_select_best_representation(deprecated_by_family, preferred_language)
          set_selected_patent_number(representative_patent_number)

          set_is_fetching_deprecated_by(false)
          set_is_fetching_legal_events(false)
          set_show_deprecated(true)
          set_fetch_deprecated(false)
        }
      })
      .catch(() => {
        if (!did_cancel) {
          set_is_fetching_deprecated_by(false)
          set_fetch_deprecated(false)
          set_is_fetching_legal_events(false)
        }
      })

    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch_deprecated, domain_family])

  useEffect(() => {
    if (!user_id) return

    let did_cancel = false
    fetch_executable_tags()
      .catch(error => {
        if (!did_cancel) {
          set_error_fetching_family_tags(error)
        }
      })
      .then(custom_tags => {
        if (!did_cancel && custom_tags) {
          set_error_fetching_family_tags(null)
          set_user_tags(custom_tags)
        }
      })
    return () => {
      did_cancel = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user.user_id, fetch_family_tags])


  function on_change_patent_link_mode(id) {
    if (on_change_patent_link_option_id) {
      //use container handler to update preference

      on_change_patent_link_option_id(id)
    } else {
      //update everything here as it's a standalone page

      set_selected_patent_link_mode(id)
      update_patent_link_option_id_preference(id)
    }
  }

  function show_deprecated_by_family() {
    if (deprecated_by_family) {
      const { representative_patent_number } = order_patent_numbers_and_select_best_representation(deprecated_by_family, preferred_language)
      set_selected_patent_number(representative_patent_number)
      set_show_deprecated(true)
    } else {
      set_fetch_deprecated(true)
    }
  }

  function hide_deprecated_by_family() {
    set_show_deprecated(false)
  }

  function on_family_tag_change(families_to_update, new_selected_tags){
    set_fetch_family_tags(!fetch_family_tags)
    if (notify_tag_change) {
      notify_tag_change(families_to_update, new_selected_tags)
    }
  }

  useEffect(()=>{
    //this is because any change made in the tag mngt page around tags could affect the tag selector in the list view
    if (notify_tag_change) {
      notify_tag_change([], [])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user_tags])
  function handle_selected_patent_number_change(patent_number) {
    if (show_deprecated) {
      return set_selected_patent_number(patent_number)
    }

    return (selected_patent_number_prop && on_change_selected_patent_number) ?
      on_change_selected_patent_number(patent_number) :
      set_selected_patent_number(patent_number)
  }

  if (is_missing) {
    return (
      <div className={cn('alert', 'alert-danger')}>
        <h4 className={'alert-heading'}>Cipher family does not exist</h4>
        <FamilyIdDisplay
          family={{patFamId: show_deprecated ? domain_family.deprecatedBy : input_family.patFamId}}
          is_missing={is_missing}
        />
      </div>
    )
  }

  if (is_fetching_deprecated_by) {
    return (
      <Spinner/>
    )
  }

  if (error_fetching_deprecated_by) {
    return (
      <div>
        There was an error fetching patent.
      </div>
    )
  }

  const can_user_see_family_tags = !is_tech_discovery_context && can_view_tagged_families(user)

  const {report_type} = report_input || {}
  const is_utt_report = is_utt_or_utt_landscape_report_type(report_type)

  const show_class_suggestions_control = is_utt_report && has_utt(user)
  const show_add_to_training_set_control = !is_tech_discovery_context && has_classifiers_edit(user) && (eval_training_set_id || !show_class_suggestions_control)

  const selected_patent_number_for_display = selected_patent_number_prop || selected_patent_number

  const show_knn_actions = (on_add_to_knn_family_ids || on_add_to_knn_blocklist || on_add_to_knn_family_ids)
  
  return (
    <ErrorBoundary>
      <div ref={top_section_ref} className={s.block}>
        <div>
          <div className={cn('px-3 py-2 d-flex justify-content-between', s.top_wrapper, top_className)}>
            <div className='d-flex'>
              <FamilyIdDisplay
                family={show_deprecated ? deprecated_by_family : input_family}
                selected_patent_number={selected_patent_number_for_display}
                alternative_family={{ patFamId: show_deprecated ? input_family.patFamId : (domain_family || {}).deprecatedBy }}
                alternative_label={show_deprecated ? 'Replaces' : 'Replaced by'}
                on_show_alternative={show_deprecated ? hide_deprecated_by_family : show_deprecated_by_family}

                report_type={report_type}
                eval_training_set_id={eval_training_set_id}
                eval_classifier_data={eval_classifier_data}
              />
              <FamilyStatus family={extra_fields_family || base_family} is_in_modal={is_in_modal} className='ms-2'/>
            </div>
            <div className='d-none d-lg-flex my-auto'>
              <Label>Citations:</Label>
              <span className='d-flex ms-2'>
                <TextLink disable={allow_public_access} onClick={() => scroll_to_top(in_citations_section_ref)}>Forward</TextLink>
                <span className='ms-1'>{(in_citations_patfams || []).length}</span>
              </span>
              <span className='d-flex ms-2'>
                <TextLink disable={allow_public_access} onClick={() => scroll_to_top(out_citations_section_ref)}>Backward</TextLink>
                <span className='ms-1'>{(out_citations_patfams || []).length}</span>
              </span>
            </div>
          </div>

          <FamilyDetailsNavigation
            sections={[NAV_TOP_SECTION, NAV_IMAGES_SECTION, NAV_ABSTRACT_SECTION, NAV_DESCRIPTION_SECTION, NAV_CLAIMS_SECTION, NAV_COSTS_SECTION, NAV_LEGAL_SECTION, NAV_DISPUTES_SECTION]}
            section_id_to_ref={section_id_to_ref}
            allow_public_access={allow_public_access}
            className={cn('px-3 py-2 fs-unmask', s.navigation_wrapper)}
          />

          <div className={cn('d-md-flex', s.two_column_container)}>
            {/* Main area */}
            <div className={cn('px-3 py-2', s.details_wrapper)}>
              <FamilyTitle
                family={base_family}
                {...highlighter_props}

                className='mb-3'
              />

              <FamilyAbstract
                family={base_family}
                {...highlighter_props}
                className='mb-3'
              />

              <FamilyXMLViews
                family={base_family}
                selected_patent_number={show_deprecated ? selected_patent_number : selected_patent_number_for_display}
                images_block_ref={images_section_ref}
                claims_block_ref={claims_section_ref}
                description_block_ref={description_section_ref}
                abstract_block_ref={abstract_section_ref}
                allow_public_access={allow_public_access}
                on_change_selected_patent_number={handle_selected_patent_number_change}
                preferred_language={preferred_language}

                {...highlighter_props}

                className={cn('p-2', s.xml_wrapper)}
              />
            </div>

            {/* Sidebar */}
            <div className={s.tiles_wrapper}>

              {(show_class_suggestions_control || show_add_to_training_set_control || show_knn_actions) &&
                <div className={s.label_controls_top}>

                  {show_knn_actions &&
                    <TechExplorerSelectionControls
                      family_id={input_family.patFamId}

                      knn_family_ids={knn_family_ids}
                      knn_blocklist={knn_blocklist}
                      knn_bookmarked={knn_bookmarked}
                      on_add_to_knn_family_ids={on_add_to_knn_family_ids}
                      on_add_to_knn_blocklist={on_add_to_knn_blocklist}
                      on_add_to_knn_bookmarked={on_add_to_knn_bookmarked}
                    />
                  }

                  {show_add_to_training_set_control &&
                    <AddPatentToTrainingSetWrapper
                      patent={base_family}
                      report_type={report_type}
                      eval_training_set_id={eval_training_set_id}
                      on_set_local_classifier_label={on_set_local_classifier_label}
                      reset_selected_classifier_id_on_change_patfam={reset_selected_classifier_id_on_change_patfam} // if true, then selection is not sticky
                    />
                  }

                  {show_class_suggestions_control &&
                    <FamilyClassWrapper
                      family={base_family}
                      family_technology={technology}
                      report_input={report_input}
                    />
                  }

                </div>
              }

              {can_user_see_family_tags && !allow_public_access &&
                <FamilyDetailsTile>
                  <FamilyTags
                    heading={'Tags'}
                    family_ids={[input_family.patFamId]}
                    user_tags={user_tags}
                    error_fetching_custom_tags={error_fetching_family_tags}
                    search_input={family_tagging_search_phrase || ''}
                    set_search_input={on_update_family_tagging_search_phrase}
                    is_multiple_tagging={false}
                    is_advanced_mode_on={is_family_tagging_mode_on}
                    on_toggle_advanced_mode={on_toggle_family_tagging_mode}
                    notify_tag_change={on_family_tag_change}
                  />
                </FamilyDetailsTile>
              }

              <FamilyDetailsTile>
                <FamilyDates family={base_family}/>
              </FamilyDetailsTile>

              <FamilyDetailsTile>
                <FamilyTerritories family={base_family}/>
              </FamilyDetailsTile>

              <FamilyDetailsTile>
                <FamilyPatentsSample
                  family={base_family}
                  patent_link_mode_id={patent_link_mode}
                  set_patent_link_mode_id={on_change_patent_link_mode}
                  on_show_full_set={() => scroll_to_top(patent_members_section_ref)}
                  show_graph_link={allow_public_access ? false : true}
                  show_patent_link_mode_switch={allow_public_access ? false : true}
                />
              </FamilyDetailsTile>

              <FamilyDetailsTile>
                <FamilyLegalEventsSummary
                  family={base_family}
                  legal_events={show_deprecated ? deprecated_legal_events : legal_events}
                  is_fetching={is_fetching_legal_events}
                  on_show_all_events={() => scroll_to_top(legal_events_section_ref)}
                  error_fetching={error_events_fetching}
                  patent_link_mode_id={patent_link_mode}
                  preferred_language={preferred_language}
                />
              </FamilyDetailsTile>

              <FamilyDetailsTile>
                <FamilyCpcCodes
                  family={base_family}
                  highlighter_props={highlighter_props}
                />
              </FamilyDetailsTile>

              {(base_family.owners || base_family.assignees) &&
                <FamilyDetailsTile>
                  <FamilyAssignees
                    family={base_family}
                    is_in_modal={is_in_modal}
                    highlighter_props={highlighter_props}
                  />
                </FamilyDetailsTile>
              }

              <FamilyDetailsTile>
                <FamilyInventors family={base_family}/>
              </FamilyDetailsTile>

            </div>
            {/* End of Sidebar */}
          </div>

          <div className={cn('px-3 py-2', s.one_column_container)}>

            {!allow_public_access &&
              <div className={cn('mb-3 position-relative')}>
                <NavigationAnchor ref={costs_section_ref} />

                <Heading>Costs</Heading>
                {is_fetching_family &&
                  <Spinner/>
                }
                {error_family_fetching &&
                  <div>There was an error fetching costs.</div>
                }
                {!is_fetching_family && !error_family_fetching && costPerYear != null &&
                  <CostsView
                    family={base_family}
                    cost_per_year={costPerYear}
                  />
                }
                {!is_fetching_family && !error_family_fetching && costPerYear == null &&
                  <div>None</div>
                }
              </div>
            }

            <LegalEventsView
              legal_events_ref={legal_events_section_ref}
              family={base_family}
              className='mb-3 w-100'
              is_fetching={is_fetching_legal_events}
              legal_events={show_deprecated ? deprecated_legal_events : legal_events}
              error_fetching={error_events_fetching}
              patent_link_mode_id={patent_link_mode}
              is_in_modal={is_in_modal}
            />

            <FamilyDisputesView
              disputes_ref={disputes_section_ref}
              className='mb-3 w-100'
              patfam_id={base_family.patFamId}
              allow_public_access={allow_public_access}
            />

            {patentNumbers && (patentNumbers.length > SAMPLE_LIMIT) &&
              <div className='mb-3 position-relative'>
                <NavigationAnchor ref={patent_members_section_ref}/>
                <Heading>Patent members</Heading>
                <FamilyPatentsDisplay
                  patent_list={patentNumbers}
                  is_limit={false}
                  patent_link_mode_id={patent_link_mode}
                  set_patent_link_mode_id={on_change_patent_link_mode}
                  patent_link_option_id={patent_link_option_id}
                  on_change_patent_link_option_id={on_change_patent_link_option_id}
                  show_patent_link_mode_switch={allow_public_access ? false : true}
                />
              </div>
            }

            {!allow_public_access &&
              <div className='mb-3 position-relative'>
                <NavigationAnchor ref={in_citations_section_ref}/>
                <Heading>{IN_CITATIONS_TITLE}</Heading>
                <FamiliesListDisplay
                  families_list={in_citations_patfams}
                />
              </div>
            }

            {!allow_public_access &&
              <div className='mb-3 position-relative'>
                <NavigationAnchor ref={out_citations_section_ref}/>
                <Heading>{OUT_CITATIONS_TITLE}</Heading>
                <FamiliesListDisplay
                  families_list={out_citations_patfams}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default withUser(FamilyDetails)