import React, { useEffect, useState } from 'react'
import ReactTable from 'react-table'
import cn from 'classnames'
import qs from 'query-string'

import { ContainerFullWidthWithScroll } from '../ContainerFullWidth.js'
import { withRouter } from 'react-router-dom'
import {
  create_new_company_list,
  delete_company_list,
  fetch_company_lists,
} from '../../utils/company_list_utils.js'
import Spinner from '../widgets/Spinner.js'
import CompanyListSettings from './CompanyListSettings.js'
import { get_as_map } from '../../utils/utils.js'
import ErrorBody from '../ErrorBody.js'
import TextLink from '../widgets/TextLink.js'
import { PlusIcon, ChevronRightIcon } from '../widgets/IconSet.js'
import ErrorModal from '../ErrorModal.js'
import ListNameModal from './ListNameModal.js'
import { PrimaryButton } from '../widgets/Button.js'
import { track_report_builder_event, track_visit_event } from '../../utils/tracking_utils.js'
import { SAVED_ORGS_LISTS_ADMIN } from '../../constants/paths.js'
import SortingColumnHeaderCell from '../patent_family_list/SortingColumnHeaderCell.js'
import { FIELD_IS_SHARED, FIELD_LIST_NAME, LIST_NAME_FIELD_ID } from '../../model/saved_org_lists.js'
import { ASCENDING } from '../../model/sort_directions.js'
import { sort_table_data } from '../../utils/item_utils.js'

import cs from '../cipher_styles.module.scss'
import s from './SavedListsAdmin.module.scss'

const SavedListsAdmin = (
  {
    history,
    location,
  }) => {
  const query_params = qs.parse(location.search)
  const {company_list_id} = query_params || {}

  const [show_spinner, set_show_spinner] = useState(true)

  const [user_company_lists, set_user_company_lists] = useState(null)
  const [is_create_new, set_is_create_new] = useState(false)
  const [fetch_user_company_lists_error, set_fetch_user_company_lists_error] = useState(null)
  const [delete_list_error, set_delete_list_error] = useState(null)
  const [create_list_error, set_create_list_error] = useState(null)

  document.title = 'Classification: Saved lists'

  useEffect(() => {
    track_visit_event(`page="${SAVED_ORGS_LISTS_ADMIN}"`)
    fetch_company_lists()
      .catch((error) => {
        set_fetch_user_company_lists_error(error)
        set_show_spinner(false)
        throw error
      })
      .then(response => {
        set_user_company_lists(response)
        set_show_spinner(false)
      })
  }, [])

  function navigate_to_lists() {
    history.replace(location.pathname)
  }

  function on_list_select(company_list_id) {
    track_report_builder_event(`action="show_details" obj="org_list"`)
    const path = location.pathname
    history.replace({pathname: path, search: `?company_list_id=${company_list_id}`})
  }

  function get_selected_list() {
    if (!(user_company_lists && company_list_id)) return null

    const lists_by_company_id = get_as_map(user_company_lists, 'company_list_id')

    return lists_by_company_id[company_list_id]
  }

  function delete_list(list) {
    navigate_to_lists()

    const {company_list_id, is_shared} = list || {}

    track_report_builder_event(`action="delete" obj="org_list" owner="${is_shared ? 'shared' : 'private'}"`)

    set_show_spinner(true)

    delete_company_list(company_list_id)
      .catch(err => {
        set_delete_list_error(err)
        set_show_spinner(false)
        throw err
      })
      .then(() => {
        const filtered_lists = user_company_lists.filter(item => item.company_list_id !== company_list_id)
        set_user_company_lists(filtered_lists)
        set_show_spinner(false)
      })
  }

  function rename_list(list, new_name) {
    const {company_list_id, is_shared} = list || {}
    track_report_builder_event(`action="rename" obj="org_list" owner="${is_shared ? 'shared' : 'private'}"`)
    const updated_lists = user_company_lists.map(item => {
      return (item.company_list_id === company_list_id) ? {...item, list_name: new_name} : item
     })
    set_user_company_lists(updated_lists)
  }

  function on_copy_list(list) {
    const {company_list_id, is_shared} = list
    track_report_builder_event(`action="copy" obj="org_list" owner="${is_shared ? 'shared' : 'private'}"`)
    const updated_lists = [...user_company_lists, list]
    set_user_company_lists(updated_lists)
    on_list_select(company_list_id)
  }

  function change_sharing_list(list, updated_is_shared) {
    const {company_list_id} = list || {}
    track_report_builder_event(`action="change_sharing" obj="org_list" owner="${updated_is_shared ? 'shared' : 'private'}"`)
    const updated_lists = user_company_lists.map(item => {
      return (item.company_list_id === company_list_id) ? {...item, is_shared: updated_is_shared} : item
    })
    set_user_company_lists(updated_lists)
  }

  function create_new_list(list_name) {
    track_report_builder_event(`action="create" obj="org_list"`)
    set_show_spinner(true)
    set_is_create_new(false)
    create_new_company_list(list_name, [], false)
      .catch(err => {
      set_show_spinner(false)
      set_create_list_error(err)
      throw err
    })
      .then(response => {
        const {company_list_id} = response
        on_copy_list({list_name, company_list_id})
        set_show_spinner(false)
      })
  }

  function is_new_list_name_unique(list_name, lists_to_exclude=[]) {
    let existing_list = null
    const company_lists_ids_to_exclude = lists_to_exclude.map(item => item.company_list_id)
    user_company_lists.forEach(company_list => {
      const {company_list_id} = company_list

      const should_exclude = company_lists_ids_to_exclude.length > 0 ? company_lists_ids_to_exclude.indexOf(company_list_id) !== -1 : false

      if ((company_list.list_name === list_name) && !should_exclude) {
        existing_list = company_list
      }
    })

    return existing_list == null
  }

  const selected_list = get_selected_list()

  const is_list_view = !(company_list_id || selected_list)

  return (
    <div className='w-100'>

      <div className={cn('d-flex', s.breadcrumbs_bar)}>
        <ContainerFullWidthWithScroll className='d-flex'>
          {is_list_view && <h3>Saved lists</h3>}
          {!is_list_view &&
            <TextLink onClick={() => navigate_to_lists()} className='my-auto'>Saved lists</TextLink>
          }
          {selected_list &&
            <span className='my-auto'>
              <span className={cn('mx-1', s.breadcrumbs_separator)}><ChevronRightIcon /></span>
              {selected_list.list_name}
              {selected_list.is_shared &&
                <span className={cn('ms-1', cs.dark_grey_text)}>[shared]</span>
              }
            </span>
          }
        </ContainerFullWidthWithScroll>
      </div>

      <ContainerFullWidthWithScroll className='my-2'>
        {show_spinner &&
          <div><Spinner/></div>
        }

        {is_list_view &&
          <PrimaryButton onClick={() => set_is_create_new(true)} disabled={show_spinner}>
            <PlusIcon /><span className='ms-1'>Create new list</span>
          </PrimaryButton>
        }

        {is_list_view && user_company_lists && user_company_lists.length > 0 &&
          <SavedListsView
            company_lists={user_company_lists}
            on_list_select_handler={on_list_select}
          />
        }

        {is_list_view && user_company_lists && user_company_lists.length === 0 &&
          <div className='my-3 text-center'>There are no saved lists to display</div>
        }

        {selected_list &&
          <CompanyListSettings
            list={selected_list}
            on_delete_handler={delete_list}
            on_rename_handler={rename_list}
            on_sharing_change_handler={change_sharing_list}
            on_copy_handler={on_copy_list}
            is_new_list_name_unique={is_new_list_name_unique}
          />
        }

        {is_create_new &&
          <ListNameModal
            on_submit={create_new_list}
            on_cancel={() => set_is_create_new(false)}
            is_name_unique={is_new_list_name_unique}
            submit_label='Create list'
            title='Create a new list'
          />
        }

        {fetch_user_company_lists_error &&
          <ErrorBody
            error={fetch_user_company_lists_error}
            context='fetching organisation lists'
          />
        }

        {delete_list_error &&
          <ErrorModal
            error={delete_list_error}
            on_close={() => set_delete_list_error(null)}
            context='deleting list'
          />
        }

        {create_list_error &&
          <ErrorModal
            error={create_list_error}
            on_close={() => set_create_list_error(null)}
            context='creating new list'
          />
        }
      </ContainerFullWidthWithScroll>
    </div>
  )
}

const SavedListsView = (
  {
    company_lists,
    on_list_select_handler
  }) => {

  const [selected_sort_field_id, set_selected_sort_field_id] = useState(LIST_NAME_FIELD_ID)
  const [selected_sort_direction_id, set_selected_sort_direction_id] = useState(ASCENDING)

  const company_lists_sorted = sort_table_data(company_lists, selected_sort_field_id, selected_sort_direction_id)

  function get_display_name_cell(params) {
    const { original } = params
    const { list_name, company_list_id } = original
    return (
      <TextLink
        onClick={() => on_list_select_handler(company_list_id)}
      >
        {list_name}
      </TextLink>
    )
  }

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    set_selected_sort_field_id(sort_field_id)
    set_selected_sort_direction_id(sort_direction_id)
  }

  function render_column_header(field) {
    return (
      <SortingColumnHeaderCell
        field={field}
        selected_sort_field_id={selected_sort_field_id}
        selected_sort_direction_id={selected_sort_direction_id}
        on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}
      />
    )
  }

  const columns = [
    {
      ...FIELD_IS_SHARED,
      Header: render_column_header(FIELD_IS_SHARED),
      Cell: (params) => {
        const {row} = params
        const {is_shared} = row
        return is_shared ? 'Shared' : 'Private'
      },
      sortable: false,
      width: 150
    },
    {
      ...FIELD_LIST_NAME,
      accessor: FIELD_LIST_NAME.id,
      Header: render_column_header(FIELD_LIST_NAME),
      Cell: get_display_name_cell,
      sortable: false,
      minWidth: 400
    }
  ]

  return (
    <div>
      <ReactTable
        className='border-0 -striped w-25'
        manual={true}
        showPagination={false}
        filterable={false}
        sortable={false}

        columns={columns}
        data={company_lists_sorted}
        minRows={5}
      />
    </div>
  )
}

export default withRouter(SavedListsAdmin)