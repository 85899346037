import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import s from './SearchTextInput.module.scss'

const SearchTextInput = (
  {
    input_text,
    on_change_input_text,
    auto_focus,
    max_height_for_autoresize,
    containerClassName
  }) => {

  const movement_timer = useRef()
  const wrapper_ref = useRef()
  const input_ref = useRef()

  const [min_wrapper_height, set_min_wrapper_height] = useState(null)

  useEffect(() => {
    const update_input_height_with_delay = () => {
      clearInterval(movement_timer.current)
      movement_timer.current = setTimeout(update_input_height, 100)
    }

    window.addEventListener('resize', update_input_height_with_delay)
    return () => {
      document.removeEventListener('resize', update_input_height_with_delay)
    }
  })

  useEffect(() => {
    if (auto_focus) {
      // Workaround to ensure focus is set
      // This runs when the component first mounts
      input_ref.current.focus()
    }
  }, [auto_focus, input_ref])

  useEffect(() => {
    if (wrapper_ref && wrapper_ref.current && !min_wrapper_height) {
      set_min_wrapper_height(wrapper_ref.current.clientHeight)
    }
  }, [wrapper_ref, min_wrapper_height])

  function update_input_height() {
    if ((max_height_for_autoresize == null) || !input_ref || !wrapper_ref) return

    const input = input_ref.current || {}
    const wrapper = wrapper_ref.current || {}

    if (!input || !wrapper || !input.style || !wrapper.style) return
    input.style.height = 'auto'
    const scroll_height = input.scrollHeight || 0
    const pure_scroll_height = scroll_height + 4 // 4px for top and bottom border width (2 x 2px)

    input.style.height = '100%'

    const calculated_wrapper_height = pure_scroll_height < max_height_for_autoresize ? Math.max(min_wrapper_height || 0, pure_scroll_height) : max_height_for_autoresize
    wrapper.style.height = `${calculated_wrapper_height}px`
  }

  return (
    <div className={containerClassName} ref={wrapper_ref}>
      <textarea
        ref={input_ref}
        placeholder='Enter patent numbers or families, a technology, &#10;paragraphs of text or a combination. &#13; &#10;Use a new line &crarr; for each criteria type'
        value={input_text || ''}
        onChange={(event) => on_change_input_text(event.target.value)}
        autoFocus={auto_focus}
        rows={6}

        onKeyUp={() => update_input_height()}

        className={cn('form-control', s.text_area)}
        autoComplete='off'
        autoCorrect='off'
      />
    </div>
  )
}

export default SearchTextInput
